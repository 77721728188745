import { VatService } from './../_services/vat.service';
import { AuthService } from 'src/app/_services/auth.service';
import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IUser } from '../_interfaces/user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Uid } from '../_classes/uid';
import { ApiService } from '../_services/api.service';
import { FirebaseError } from '../_interfaces/firebase-error';
import { ApiErrorHandler } from '../_classes/api-error-handler';

@Component({
  selector: 'app-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss'],
})
export class CreateProfileComponent implements OnInit {
  taxIdForm: FormGroup;
  setCompanyDataEnabled: boolean = false;
  taxIdSaving: boolean = false;

  constructor(
    public authService: AuthService,
    private router: Router,
    public vatService: VatService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private apiService: ApiService
  ) {
    this.taxIdForm = this.fb.group({
      taxId: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(50), Uid.uidValidator()]],
      companyName: ['', [Validators.required]],
    });
    this.taxIdForm.disable();
  }

  ngOnInit(): void {
    this.authService.user$.pipe(take(1)).subscribe((user) => {
      if (!user?.preferences) {
        // if for some reason preferences are not yet created, create now
        user.preferences = {
          alternativeDataCloudStorage: true,
          sessionsCloudStorage: true,
          importSchemeCloudStorage: true,
          useMatchingPrediction: true,
          matchingThreshold: 0.9,
          encryptionEnabled: false,
          passwordHash: null,
        };
        user.firstTimeLoginCompleted = false;
        this.authService.updateUser(user);
        console.error('Preferences have not yet been set even though user ' + user.uid + ' was already authenticated');
      }
    });
  }

  onTaxIdInputChange(): void {
    this.taxIdForm.setValue({
      taxId: this.taxIdForm.value.taxId.toUpperCase(),
      companyName: this.taxIdForm.value.companyName,
    });
  }

  changeSetCompanyDataEnabled($event) {
    this.setCompanyDataEnabled = $event.checked;

    if ($event.checked) {
      this.taxIdForm.enable();
    } else {
      this.taxIdForm.disable();
    }
  }

  async createProfile(user: IUser): Promise<void> {
    this.taxIdSaving = true;
    user.firstTimeLoginCompleted = true;

    if (this.taxIdForm.valid) {
      user.taxId = this.taxIdForm.controls['taxId'].value;
      user.companyName = this.taxIdForm.controls['companyName'].value;

      const countryCode = this.taxIdForm.controls['taxId'].value.substr(0, 2);
      const uid = this.taxIdForm.controls['taxId'].value.substr(2);
      this.apiService
        .checkVatRequest(countryCode, uid)
        .pipe(take(1))
        .subscribe({
          next: async (res) => {
            if (res.valid) {
              user.taxIdValidated = new Date();
              try {
                await this.apiService.addCompanyData(user.stripeId, countryCode, uid, user.companyName);
                await this.authService.updateUser(user);
                this._snackBar.open('Your data has been saved', 'OK', {
                  duration: 2000,
                });
                this.router.navigate(['']);
                this.taxIdSaving = false;
              } catch (error) {
                console.error(error);
                this._snackBar.open('Failed to save your company data!', 'OK', {
                  duration: 5000,
                  panelClass: ['snackbar-warn'],
                });
                this.taxIdSaving = false;
              }
            } else {
              this._snackBar.open('The provided VAT number is invalid!', 'OK', {
                duration: 5000,
                panelClass: ['snackbar-warn'],
              });
              this.taxIdSaving = false;
            }
          },
          error: (error: FirebaseError) => {
            const errorMessage = ApiErrorHandler.getErrorMessage(error.details.code);
            console.error(ApiErrorHandler.getErrorLogMessage(error));
            this._snackBar.open(errorMessage, 'OK', {
              duration: 5000,
              panelClass: ['snackbar-warn'],
            });
            this.taxIdSaving = false;
          },
        });
    } else {
      try {
        await this.authService.updateUser(user);
        this._snackBar.open('Preferences saved', 'OK', {
          duration: 2000,
        });
        this.router.navigate(['']);
        this.taxIdSaving = false;
      } catch (error) {
        console.error(error);
        this._snackBar.open('Failed to save your preferences!', 'OK', {
          duration: 5000,
          panelClass: ['snackbar-warn'],
        });
        this.taxIdSaving = false;
      }
    }
  }
}
