import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { error } from 'protractor';
import { take } from 'rxjs/operators';
import { ApiErrorHandler } from 'src/app/_classes/api-error-handler';
import { Uid } from 'src/app/_classes/uid';
import { IApiResponse } from 'src/app/_interfaces/api-response';
import { FirebaseError } from 'src/app/_interfaces/firebase-error';
import { ISubscriptionDialogInput } from 'src/app/_interfaces/isubscription-dialog-input';
import { ApiService } from 'src/app/_services/api.service';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-subscription-dialog',
  templateUrl: './subscription-dialog.component.html',
  styleUrls: ['./subscription-dialog.component.scss'],
})
export class SubscriptionDialogComponent implements OnInit {
  subscriptionForm = this.fb.group({
    taxId: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(50), Uid.uidValidator()]],
    businessName: ['', [Validators.required, Validators.maxLength(50)]],
  });

  spinnerEnabled = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: ISubscriptionDialogInput,
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private apiService: ApiService,
    private _snackBar: MatSnackBar,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.subscriptionForm.setValue({
      taxId: this.inputData.taxId ? this.inputData.taxId.toUpperCase() : '',
      businessName: this.inputData.companyName ? this.inputData.companyName : '',
    });
  }

  onTaxIdInputChange(): void {
    this.subscriptionForm.setValue({
      taxId: this.subscriptionForm.value.taxId.toUpperCase(),
      businessName: this.subscriptionForm.value.businessName,
    });
  }

  confirmSubscription(): void {
    this.spinnerEnabled = true;

    const countryCode = this.subscriptionForm.value.taxId.substr(0, 2);
    const vatNumber = this.subscriptionForm.value.taxId.substr(2);

    this.apiService
      .checkVatRequest(countryCode, vatNumber)
      .pipe(take(1))
      .subscribe(
        (res: IApiResponse) => {
          if (res.valid) {
            this.authService.user$.pipe(take(1)).subscribe(async (user) => {
              try {
                await this.apiService.addCompanyData(
                  user.stripeId,
                  countryCode,
                  vatNumber,
                  this.subscriptionForm.value.businessName
                );

                // update user
                user.taxId = countryCode + vatNumber;
                user.taxIdValidated = new Date();
                user.companyName = this.subscriptionForm.value.businessName;
                await this.authService.updateUser(user);

                this.dialogRef.close(true);
              } catch (error) {
                this.spinnerEnabled = false;
                console.error(error);
                this._snackBar.open('Failed to update your company data!', 'OK', {
                  duration: 5000,
                  panelClass: ['snackbar-warn'],
                });
              }
            });
          } else {
            this.spinnerEnabled = false;
            this._snackBar.open('The provided VAT number is invalid', 'OK', {
              duration: 5000,
              panelClass: ['snackbar-warn'],
            });
          }
        },
        (error: FirebaseError) => {
          this.spinnerEnabled = false;

          const errorMessage = ApiErrorHandler.getErrorMessage(error.details.code);
          console.error(ApiErrorHandler.getErrorLogMessage(error));
          this._snackBar.open(errorMessage, 'OK', {
            duration: 5000,
            panelClass: ['snackbar-warn'],
          });
        }
      );
  }
}
