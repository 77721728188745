<h1>Review Selection</h1>
<div class="review__overflow">
  <div class="review__table-container">
    <div class="mat-elevation-z8">
      <table class="n-bordered review__table-data">
        <tr>
          <ng-container *ngFor="let column of import.importedHeaders; let i = index">
            <td
              *ngIf="getColumnDefinition(i) !== null; else notSelectedHeader"
              class="review__table-header-cell--selected review__table-data-content"
            >
              <b>{{ column }}</b> ({{ getColumnDefinition(i) }})
            </td>
            <ng-template #notSelectedHeader>
              <td class="review__table-data-content">
                <b>{{ column }}</b>
              </td>
            </ng-template>
          </ng-container>
        </tr>
        <tr *ngFor="let dataRow of import.importedData | slice: 0:5">
          <td class="review__table-data-content" *ngFor="let dataColumn of dataRow">
            {{ dataColumn }}
          </td>
        </tr>
      </table>
    </div>
    <div *ngIf="import.importedData.length >= 5" class="gradient"></div>
  </div>
</div>
