<ng-template matStepLabel>Alternative Data</ng-template>
<h1>Alternative Data Import (optional)</h1>
<app-file-import (fileImportFinishedEvent)="rawDataImported($event)"></app-file-import>

<br />
<div *ngIf="authService.user$ | async as user" class="import__selection-container">
  <mat-card class="import__selection mat-elevation-z4">
    <div>
      <h3>Select stored template</h3>
      <p>Choose from an already stored import template.</p>
    </div>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!alternativeDataSelectionEnabled"
      (click)="openAltTemplatesDialog()"
    >
      Select
    </button>
  </mat-card>
  <mat-card class="import__selection import__selection--center mat-elevation-z4">
    <div>
      <h3>Create new template</h3>
      <p>Create a new import template and add it to your cloud-storage.</p>
      <p *ngIf="!user.preferences.importSchemeCloudStorage" class="import__template-warning">
        Import templates cloud-storage is currently disabled (go to your Settings page to enable it)
      </p>
    </div>

    <div class="import__template-name-row">
      <form [formGroup]="alternativeDataImportTemplateForm">
        <mat-form-field class="dialog__template-name-field">
          <mat-label>Name of new template</mat-label>
          <input formControlName="name" name="name" type="text" matInput placeholder="Insert Template Name" />
        </mat-form-field>
      </form>
      <button
        class="import__template-name-row"
        mat-raised-button
        [disabled]="
          !alternativeDataSelectionEnabled ||
          !alternativeDataImportTemplateForm.valid ||
          !user.preferences.importSchemeCloudStorage
        "
        color="primary"
        (click)="openAltSelectionDialog(true)"
      >
        Select
      </button>
    </div>
  </mat-card>
  <mat-card class="import__selection mat-elevation-z4">
    <div>
      <h3>Proceed without template</h3>
      <p>Select the respective data-columns manually withouth storing your selection as a template.</p>
    </div>
    <button
      mat-raised-button
      [disabled]="!alternativeDataSelectionEnabled"
      color="primary"
      (click)="openAltSelectionDialog(false)"
    >
      Select
    </button>
  </mat-card>
</div>
