<mat-card *ngIf="vatService.checkFinished" class="finished-banner mat-elevation-z4">
  <div *ngIf="reportData.stateCounterChecked === vatService.importedData.length" class="finished-banner__left">
    <mat-icon class="finished-banner__success-icon" aria-hidden="false">check_circle</mat-icon>
    Finished check without errors!
  </div>
  <div *ngIf="reportData.stateCounterError > 0" class="finished-banner__left">
    <mat-icon class="finished-banner__error-icon" aria-hidden="false">error</mat-icon>
    Finished check with errors!
  </div>
  <div
    *ngIf="reportData.stateCounterError === 0 && reportData.stateCounterUnavailable > 0"
    class="finished-banner__left"
  >
    <mat-icon class="finished-banner__info-icon" aria-hidden="false">info</mat-icon>
    Finished check but at least one country server was unavailable!
  </div>

  <button mat-button (click)="openFinishedDialog()">Open Report</button>
</mat-card>
