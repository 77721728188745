<h1 mat-dialog-title>Export Data</h1>
<mat-dialog-content class="mat-typography">
  <div>
    <div class="file-export-dialog__main-checkbox">
      <mat-checkbox
        [(ngModel)]="exportAllColumnsCheckbox.checked"
        [color]="exportAllColumnsCheckbox.color"
        (change)="exportAllColumnsCheckboxChange($event.checked)"
      >
        {{ exportAllColumnsCheckbox.name }}
      </mat-checkbox>
    </div>

    <div class="file-export-dialog__main-checkbox">
      <mat-checkbox
        [(ngModel)]="exportMinimumColumnsCheckbox.checked"
        [color]="exportMinimumColumnsCheckbox.color"
        (change)="exportMinimumColumnsCheckboxChange($event.checked)"
      >
        {{ exportMinimumColumnsCheckbox.name }}
      </mat-checkbox>
    </div>

    <div class="file-export-dialog__main-checkbox">
      <mat-checkbox
        [(ngModel)]="overrideAcceptedResponseCheckbox.checked"
        [color]="overrideAcceptedResponseCheckbox.color"
      >
        {{ overrideAcceptedResponseCheckbox.name }}
      </mat-checkbox>
    </div>

    <div class="file-export-dialog__main-checkbox">
      <mat-checkbox
        [(ngModel)]="overrideResponseIfAltExistsCheckbox.checked"
        [color]="overrideResponseIfAltExistsCheckbox.color"
      >
        {{ overrideResponseIfAltExistsCheckbox.name }}
      </mat-checkbox>
    </div>

    <div class="file-export-dialog__main-checkbox">
      <mat-checkbox
        [(ngModel)]="overrideResponseIfUidValidCheckbox.checked"
        [color]="overrideResponseIfUidValidCheckbox.color"
      >
        {{ overrideResponseIfUidValidCheckbox.name }}
      </mat-checkbox>
    </div>

    <h3>Appendings</h3>
    <div class="file-export-dialog__main-checkbox">
      <mat-checkbox [(ngModel)]="appendValidColumnCheckbox.checked" [color]="appendValidColumnCheckbox.color">
        {{ appendValidColumnCheckbox.name }}
      </mat-checkbox>
    </div>

    <div class="file-export-dialog__main-checkbox">
      <mat-checkbox [(ngModel)]="appendDataCorrectCheckbox.checked" [color]="appendDataCorrectCheckbox.color">
        {{ appendDataCorrectCheckbox.name }}
      </mat-checkbox>
    </div>

    <div class="file-export-dialog__main-checkbox">
      <mat-checkbox [(ngModel)]="appendStateCheckbox.checked" [color]="appendStateCheckbox.color">
        {{ appendStateCheckbox.name }}
      </mat-checkbox>
    </div>

    <div class="file-export-dialog__main-checkbox">
      <mat-checkbox
        [(ngModel)]="appendAlternativeDataSheetCheckbox.checked"
        [color]="appendAlternativeDataSheetCheckbox.color"
      >
        {{ appendAlternativeDataSheetCheckbox.name }}
      </mat-checkbox>
    </div>

    <h3>Filter</h3>
    <div class="file-export-dialog__main-checkbox">
      <mat-checkbox
        [checked]="filterCheckboxAllChecked"
        [indeterminate]="filterCheckboxSomeChecked()"
        (change)="filterCheckboxSetAll($event.checked)"
        [color]="filterCheckbox.color"
      >
        {{ filterCheckbox.name }}
      </mat-checkbox>
    </div>

    <ul>
      <li *ngFor="let subCheckbox of filterCheckbox.subCheckboxes">
        <div class="file-export-dialog__main-checkbox">
          <mat-checkbox
            [(ngModel)]="subCheckbox.checked"
            [color]="subCheckbox.color"
            (ngModelChange)="filterCheckboxUpdateAllChecked()"
          >
            {{ subCheckbox.name }}
          </mat-checkbox>
        </div>
      </li>
    </ul>
    <br />
    <div class="file-export-dialog__main-checkbox">
      <mat-checkbox [(ngModel)]="deleteLocalDataCheckbox.checked" [color]="deleteLocalDataCheckbox.color">
        {{ deleteLocalDataCheckbox.name }}
      </mat-checkbox>
    </div>
    <div class="file-export-dialog__main-checkbox">
      <mat-checkbox [(ngModel)]="deleteSessionCheckbox.checked" [color]="deleteSessionCheckbox.color">
        {{ deleteSessionCheckbox.name }}
      </mat-checkbox>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="file-export-dialog__action-btn" mat-button mat-dialog-close>Cancel</button>
  <button
    class="file-export-dialog__action-btn"
    mat-raised-button
    color="primary"
    (click)="exportToExcel()"
    [disabled]="exportButtonDisabled"
  >
    Export to Excel
  </button></mat-dialog-actions
>
