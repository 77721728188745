<h1 mat-dialog-title>No internet connection!</h1>
<mat-dialog-content class="connection-dialog__content">
  <div class="connection-dialog__no-connection">
    <svg
      class="connection-dialog__no-connection-img"
      id="Capa_1"
      enable-background="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="m421.53 219.264c-20.979-65.806-82.598-113.472-155.359-113.472-72.328 0-133.645 47.102-154.979 112.3-28.768-1.139-57.908 9.267-79.871 31.23-41.761 41.761-41.761 109.47 0 151.231 40.038 40.038 103.923 41.683 145.929 4.949 25.575 16.674 56.113 26.372 88.921 26.372 27.99 0 54.329-7.057 77.344-19.483 17.405 12.272 38.633 19.483 61.548 19.483 59.06 0 106.937-47.877 106.937-106.937 0-53.458-39.229-97.753-90.47-105.673z"
            fill="#eaf9fa"
          />
          <path
            d="m92.326 242.149c0-14.142 11.465-25.607 25.607-25.607s25.607 11.465 25.607 25.607-11.465 25.607-25.607 25.607-25.607-11.464-25.607-25.607"
            fill="#d4f2f6"
          />
          <path
            d="m512 324.937c0 55.99-43.02 101.916-97.805 106.541 31.709-18.554 53.003-52.962 53.003-92.356 0-50.347-34.8-92.571-81.659-103.93-5.495-1.332-9.906-5.358-11.934-10.636-23.499-61.158-82.798-104.574-152.24-104.574-8.963 0-17.76.721-26.331 2.122 21.489-10.446 45.627-16.308 71.134-16.308 72.762 0 134.387 47.656 155.362 113.464 51.241 7.924 90.47 52.221 90.47 105.677z"
            fill="#d4f2f6"
          />
          <g>
            <circle cx="344.396" cy="402.755" fill="#a3e440" r="81.174" />
            <path
              d="m425.569 402.753c0 44.833-36.345 81.178-81.168 81.178-5.45 0-10.776-.536-15.927-1.566 37.2-7.407 65.231-40.239 65.231-79.612s-28.031-72.195-65.231-79.602c5.151-1.03 10.477-1.566 15.927-1.566 44.823 0 81.168 36.334 81.168 81.168z"
              fill="#99d53b"
            />
            <g>
              <path
                d="m360.527 434.645c-1.774 0-3.556-.607-5.011-1.849-3.074-2.623-7.47-4.136-12.058-4.149-.021 0-.042 0-.063 0-4.565 0-8.943 1.484-12.017 4.076-3.263 2.752-8.137 2.336-10.887-.926-2.751-3.262-2.336-8.137.926-10.887 5.903-4.978 13.704-7.716 21.976-7.716h.113c8.313.025 16.142 2.812 22.042 7.848 3.246 2.77 3.632 7.647.862 10.893-1.53 1.79-3.7 2.71-5.883 2.71z"
                fill="#82b732"
              />
              <g>
                <g>
                  <path
                    d="m305.823 402.389c-4.268 0-7.768-3.459-7.768-7.726s3.418-7.726 7.685-7.726h.082c4.268 0 7.726 3.459 7.726 7.726.001 4.267-3.457 7.726-7.725 7.726z"
                    fill="#82b732"
                  />
                </g>
              </g>
            </g>
            <g fill="#bcea73">
              <path
                d="m343.435 294.221c-.009 0-.016 0-.024 0-4.268-.013-7.716-3.482-7.703-7.749l.069-23.008c.013-4.259 3.47-7.703 7.725-7.703h.024c4.268.013 7.716 3.482 7.703 7.75l-.069 23.008c-.013 4.258-3.47 7.702-7.725 7.702z"
              />
              <path
                d="m309.129 304.027c-2.627 0-5.187-1.34-6.636-3.758l-11.954-19.958c-2.192-3.66-1.002-8.405 2.659-10.598 3.657-2.192 8.404-1.004 10.599 2.658l11.954 19.958c2.192 3.66 1.002 8.405-2.659 10.598-1.243.746-2.612 1.1-3.963 1.1z"
              />
              <path
                d="m377.679 304.342c-1.366 0-2.749-.362-4.002-1.122-3.648-2.214-4.81-6.966-2.596-10.614l12.073-19.892c2.214-3.648 6.965-4.808 10.614-2.596 3.648 2.214 4.81 6.966 2.596 10.614l-12.073 19.892c-1.454 2.395-4.001 3.718-6.612 3.718z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="m70.192 153.953c-3.018-3.017-7.909-3.017-10.928 0l-5.883 5.883-5.883-5.883c-3.018-3.017-7.909-3.017-10.928 0-3.017 3.017-3.017 7.909 0 10.927l5.883 5.883-5.883 5.883c-3.017 3.017-3.017 7.909 0 10.927 1.509 1.509 3.487 2.263 5.464 2.263s3.955-.755 5.464-2.263l5.883-5.883 5.883 5.883c1.509 1.509 3.487 2.263 5.464 2.263s3.955-.755 5.464-2.263c3.017-3.017 3.017-7.909 0-10.927l-5.883-5.883 5.883-5.883c3.017-3.018 3.017-7.91 0-10.927z"
                fill="#a8e7ef"
              />
              <path
                d="m235.961 323.419c-3.017-3.018-7.908-3.018-10.928 0l-5.883 5.883-5.883-5.883c-3.018-3.017-7.909-3.017-10.928 0-3.017 3.017-3.017 7.909 0 10.927l5.883 5.883-5.883 5.883c-3.017 3.017-3.017 7.909 0 10.927 1.509 1.509 3.486 2.263 5.464 2.263 1.977 0 3.955-.755 5.464-2.263l5.883-5.883 5.883 5.883c1.509 1.509 3.487 2.263 5.464 2.263s3.955-.755 5.464-2.263c3.017-3.017 3.017-7.909 0-10.927l-5.883-5.883 5.883-5.883c3.017-3.018 3.017-7.91 0-10.927z"
                fill="#64b9fc"
              />
              <path
                d="m482.21 180.342 4.116-4.116c3.017-3.017 3.017-7.909 0-10.927-3.018-3.017-7.909-3.017-10.928 0l-4.115 4.115-4.115-4.115c-3.018-3.017-7.909-3.017-10.928 0-3.017 3.017-3.017 7.909 0 10.927l4.116 4.116-4.116 4.116c-3.017 3.017-3.017 7.909 0 10.927 1.509 1.509 3.487 2.263 5.464 2.263s3.955-.755 5.464-2.263l4.115-4.115 4.115 4.115c1.509 1.509 3.487 2.263 5.464 2.263s3.955-.755 5.464-2.263c3.017-3.017 3.017-7.909 0-10.927z"
                fill="#bcea73"
              />
              <path
                d="m407.673 51.415c-3.018-3.017-7.909-3.017-10.928 0l-4.115 4.115-4.115-4.115c-3.017-3.017-7.908-3.018-10.928 0-3.017 3.017-3.017 7.909 0 10.927l4.116 4.116-4.116 4.116c-3.017 3.018-3.017 7.909 0 10.927 1.509 1.509 3.487 2.263 5.464 2.263s3.955-.755 5.464-2.263l4.115-4.115 4.115 4.115c1.509 1.509 3.486 2.263 5.464 2.263 1.977 0 3.955-.755 5.464-2.263 3.017-3.017 3.017-7.909 0-10.927l-4.116-4.116 4.116-4.116c3.017-3.018 3.017-7.91 0-10.927z"
                fill="#a8e7ef"
              />
            </g>
            <g>
              <path
                d="m295.554 50.609c0-12.449 10.092-22.541 22.541-22.541s22.541 10.092 22.541 22.541-10.093 22.541-22.542 22.541-22.54-10.092-22.54-22.541"
                fill="#bcea73"
              />
              <path
                d="m306.662 70.034c8.848-2.039 15.441-9.961 15.441-19.427 0-9.456-6.582-17.388-15.43-19.427 3.348-1.978 7.252-3.111 11.423-3.111 12.443 0 22.538 10.095 22.538 22.538 0 12.453-10.095 22.548-22.538 22.548-4.172 0-8.087-1.133-11.434-3.121"
                fill="#99d53b"
              />
            </g>
          </g>
          <g>
            <path
              d="m92.326 216.542c0-14.142 11.465-25.607 25.607-25.607s25.607 11.465 25.607 25.607-11.465 25.607-25.607 25.607-25.607-11.465-25.607-25.607"
              fill="#64b9fc"
            />
            <path
              d="m104.945 238.609c10.052-2.317 17.541-11.316 17.541-22.07 0-10.743-7.478-19.753-17.53-22.07 3.803-2.247 8.238-3.534 12.977-3.534 14.136 0 25.604 11.468 25.604 25.604 0 14.148-11.468 25.616-25.604 25.616-4.738 0-9.185-1.288-12.988-3.546"
              fill="#31a7fb"
            />
          </g>
          <g>
            <circle cx="354.517" cy="132.99" fill="#a8e7ef" r="27.371" />
            <path
              d="m381.887 132.987c0 15.122-12.258 27.38-27.368 27.38-5.066 0-9.819-1.376-13.884-3.79 10.744-2.477 18.75-12.095 18.75-23.59 0-11.483-7.993-21.114-18.737-23.59 4.065-2.402 8.806-3.777 13.871-3.777 15.11-.001 27.368 12.257 27.368 27.367z"
              fill="#70d9e6"
            />
          </g>
        </g>
      </g>
      <path
        d="m383.041 402.389c-4.268 0-7.768-3.459-7.768-7.726s3.418-7.726 7.685-7.726h.083c4.268 0 7.726 3.459 7.726 7.726.001 4.267-3.458 7.726-7.726 7.726z"
        fill="#82b732"
      />
    </svg>
    <p class="connection-dialog__paragraph">
      Could not connect to the internet, please check your connection. <br />The application will reconnect
      automatically, refreshing is not necessary.
    </p>
  </div>
</mat-dialog-content>
