import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private afAuth: AngularFireAuth) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // redirect user to home if user is already logged in (used for login and register components)
    return this.authService.user$.pipe(
      map((user) => !user),
      tap((isNotLoggedIn) => {
        if (!isNotLoggedIn) {
          this.router.navigate(['']);
        }
      })
    );
  }
}
