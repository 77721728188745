import { PasswordLoginDialogComponent } from './../_components/password-login-dialog/password-login-dialog.component';
import { PromptInput } from './../_interfaces/prompt-input';
import { PromptDialogComponent } from './../_components/prompt-dialog/prompt-dialog.component';
import { VatService } from './../_services/vat.service';
import { FirestoreService } from './../_services/firestore.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { IUser } from '../_interfaces/user';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Uid } from '../_classes/uid';
import { ApiService } from '../_services/api.service';
import { ApiErrorHandler } from '../_classes/api-error-handler';
import { FirebaseError } from '../_interfaces/firebase-error';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  resetButtonEnabled = true;
  currentStripeRole = '';
  checksInPeriod = 0;
  customerPortalLink: string;
  deletionLoading = false;
  taxIdForm: FormGroup;
  taxIdSaving: boolean = false;

  constructor(
    public authService: AuthService,
    private firestoreService: FirestoreService,
    public vatService: VatService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private apiService: ApiService
  ) {
    this.taxIdForm = this.fb.group({
      taxId: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(50), Uid.uidValidator()]],
      companyName: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.authService.user$.pipe(take(1)).subscribe((user) => {
      this.taxIdForm.setValue({
        taxId: user.taxId ? user.taxId : '',
        companyName: user.companyName ? user.companyName : '',
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onTaxIdInputChange(): void {
    this.taxIdForm.setValue({
      taxId: this.taxIdForm.value.taxId.toUpperCase(),
      companyName: this.taxIdForm.value.companyName,
    });
  }

  async saveCompanyData(user: IUser) {
    this.taxIdSaving = true;

    // clear whitespaces
    const clearedTaxId = this.taxIdForm.controls['taxId'].value.replace(/\s/g, '');
    this.taxIdForm.controls['taxId'].setValue(clearedTaxId);

    // update user data
    user.taxId = this.taxIdForm.controls['taxId'].value;
    user.companyName = this.taxIdForm.controls['companyName'].value;

    try {
      await this.authService.updateUser(user);
    } catch (error) {
      console.error(error);
      this._snackBar.open('Failed to update your company data!', 'OK', {
        duration: 5000,
        panelClass: ['snackbar-warn'],
      });
      return;
    }

    const countryCode = this.taxIdForm.controls['taxId'].value.substr(0, 2);
    const uid = this.taxIdForm.controls['taxId'].value.substr(2);

    this.apiService
      .checkVatRequest(countryCode, uid)
      .pipe(take(1))
      .subscribe({
        next: async (res) => {
          if (res.valid) {
            user.taxIdValidated = new Date();
            try {
              await this.apiService.addCompanyData(user.stripeId, countryCode, uid, user.companyName);
              await this.authService.updateUser(user);
              this.taxIdSaving = false;
              this._snackBar.open('Your company data has been updated', 'OK', {
                duration: 2000,
              });
            } catch (error) {
              console.error(error);
              this.taxIdSaving = false;
              this._snackBar.open('Failed to update your company data!', 'OK', {
                duration: 5000,
                panelClass: ['snackbar-warn'],
              });
            }
          } else {
            user.taxIdValidated = null;
            try {
              await this.authService.updateUser(user);
              this.taxIdSaving = false;
              this._snackBar.open('Your company data has been updated but the provided VAT number is invalid!', 'OK', {
                duration: 5000,
                panelClass: ['snackbar-warn'],
              });
            } catch (error) {
              console.error(error);
              this._snackBar.open('Failed to update your company data!', 'OK', {
                duration: 5000,
                panelClass: ['snackbar-warn'],
              });
            }
          }
        },
        error: (error: FirebaseError) => {
          this.taxIdSaving = false;
          const errorMessage = ApiErrorHandler.getErrorMessage(error.details.code);
          console.error(ApiErrorHandler.getErrorLogMessage(error));
          this._snackBar.open(errorMessage, 'OK', {
            duration: 5000,
            panelClass: ['snackbar-warn'],
          });
        },
      });
  }

  resetPassword(email: string): void {
    this.resetButtonEnabled = false;
    this.authService.sendPasswordResetEmail(email).then(
      () => {
        this._snackBar.open('Password reset email has been sent to ' + email, 'OK', {
          duration: 2000,
        });
      },
      (error) => {
        console.error(error);
        this._snackBar.open('Failed to send password reset email', 'OK', {
          duration: 5000,
          panelClass: ['snackbar-warn'],
        });
      }
    );
  }

  deleteAccount(user: IUser): void {
    const data: PromptInput = {
      title: 'Confirm deletion',
      text: 'Are you sure you want to delete your account? This action cannot be undone and all data will be lost. (This operation requires additional authentication)',
    };
    const dialogRef = this.dialog.open(PromptDialogComponent, {
      maxWidth: '400px',
      data,
    });

    dialogRef.afterClosed().subscribe(async (confirmed: boolean) => {
      if (confirmed) {
        this.authService.suspendPageCloseWarning = true;
        const providerIds = await firebase
          .auth()
          .currentUser.providerData.map((providerData) => providerData.providerId);
        if (providerIds.includes('google.com')) {
          this.authService
            .googleSignin()
            .then(() => {
              this.authService.user$.pipe(take(1)).subscribe(async (authenticatedUser) => {
                if (authenticatedUser.uid === user.uid) {
                  this.deletionLoading = true;
                  try {
                    await this.firestoreService.deleteUser(user.uid);
                    this._snackBar.open('Your account was successully deleted', 'OK', {
                      duration: 5000,
                    });
                    window.location.reload();
                  } catch (error) {
                    console.error(error);
                    this.deletionLoading = false;
                    this._snackBar.open('User deletion failed', 'OK', {
                      duration: 5000,
                      panelClass: ['snackbar-warn'],
                    });
                  }
                } else {
                  this._snackBar.open('Wrong user was used for re-authentication', 'OK', {
                    duration: 5000,
                    panelClass: ['snackbar-warn'],
                  });
                }
              });
            })
            .catch((error) => {
              if (error.code !== 'auth/popup-closed-by-user') {
                console.error(error);
                this._snackBar.open(error.message, 'OK', {
                  duration: 5000,
                  panelClass: ['snackbar-warn'],
                });
              }
            });
        } else if (providerIds.includes('password')) {
          const passwordLoginDialog = this.dialog.open(PasswordLoginDialogComponent, {});
          passwordLoginDialog.afterClosed().subscribe(async (authenticated: boolean) => {
            if (authenticated) {
              this.deletionLoading = true;

              try {
                await this.firestoreService.deleteUser(user.uid);
                this._snackBar.open('Your account was successully deleted', 'OK', {
                  duration: 5000,
                });
                window.location.reload();
              } catch (error) {
                console.error(error);
                this.deletionLoading = false;
                this._snackBar.open('User deletion failed', 'OK', {
                  duration: 5000,
                  panelClass: ['snackbar-warn'],
                });
              }
            }
          });
        }
      }
    });
  }
}
