<mat-toolbar class="nav-bar">
  <div class="nav-bar__toolbar-row">
    <div>
      <div class="nav-bar__danube-container">
        <a class="nav-bar__url nav-bar__main-name" routerLink="">
          <svg
            class="nav-bar__danube-logo"
            version="1.1"
            baseProfile="tiny"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 500 500"
            xml:space="preserve"
            xlink:href="data:image/png;base64"
          >
            <path fill="white" stroke="#000000" stroke-width="10" stroke-miterlimit="10" d="M57,283" />
            <g>
              <path
                fill="white"
                d="M250.4,0.8C112.7,0.8,1,112.4,1,250.2c0,137.7,111.7,249.4,249.4,249.4c137.7,0,249.4-111.7,249.4-249.4
    C499.8,112.4,388.1,0.8,250.4,0.8z M383.8,326.3c-62,0-101.4-14.1-117.6-46.3c-17.1-34.1-2.3-75.4,13.2-104.1
    c-22.4,3-38.4,9.2-47.8,18.3c-11.2,10.9-13.6,26.7-16.3,45c-3.1,20.8-6.6,44.4-25.3,62.4c-19.8,19.1-51.6,26.9-100.2,24.6l1.8-39.7		c35.9,1.6,59.7-2.9,70.8-13.6c8.9-8.6,11.1-22.9,13.5-39.6c6.3-42,14.8-99.4,141.4-99.4h41L333,166c-12.6,16-45.4,68.2-31.2,96.2	c9.2,18.3,41.5,25.6,91.2,24.2l1.1,39.8C390.5,326.2,387.1,326.3,383.8,326.3z"
              />
            </g>
          </svg>
          <span class="nav-bar__header-brand">danube<span class="nav-bar__header-brand-light">labs </span> </span>
        </a>
      </div>
    </div>

    <div class="toolbar-row--right">
      <ng-container *ngIf="authService.user$ | async as user; else login">
        <ng-container *ngIf="user.firstTimeLoginCompleted">
          <button class="nav-bar__session" mat-button disabled>
            <mat-icon
              *ngIf="user.preferences.encryptionEnabled; else encryptionDisabled"
              [ngClass]="encryptionService.key ? 'nav-bar__usage-icon--active' : 'nav-bar__usage-icon'"
              aria-hidden="false"
              matTooltip="Client side encryption is enabled"
              >enhanced_encryption</mat-icon
            >
            <ng-template #encryptionDisabled>
              <mat-icon matTooltip="Client side encryption is disabled" class="nav-bar__usage-icon" aria-hidden="false"
                >no_encryption</mat-icon
              ></ng-template
            >
          </button>

          <button [matMenuTriggerFor]="menuSession" class="nav-bar__session nav-bar__border" mat-button>
            <mat-icon
              *ngIf="user.preferences.sessionsCloudStorage; else sessionStorageDisabled"
              [ngClass]="(vatService.currentSession$ | async) ? 'nav-bar__usage-icon--active' : 'nav-bar__usage-icon'"
              aria-hidden="false"
              >cloud_done</mat-icon
            >
            <ng-template #sessionStorageDisabled>
              <mat-icon
                [ngClass]="(vatService.currentSession$ | async) ? 'nav-bar__usage-icon--active' : 'nav-bar__usage-icon'"
                aria-hidden="false"
                >cloud_off</mat-icon
              ></ng-template
            >
          </button>
          <mat-menu class="nav-bar__usage-menu" #menuSession="matMenu" xPosition="before">
            <ng-container
              *ngIf="
                !user.preferences.sessionsCloudStorage && !(vatService.currentSession$ | async);
                else showCurrentSession
              "
            >
              Session Cloud Storage is currently disabled (to enable go to
              <a class="nav-bar__settings-link" routerLink="/settings">Settings</a>)
            </ng-container>
            <ng-template #showCurrentSession>
              <div *ngIf="vatService.currentSession$ | async; else noSession">
                <div>Current cloud session: {{ (vatService.currentSession$ | async)?.name }}</div>
              </div>
              <ng-template #noSession>You are currently not using any cloud-session.</ng-template></ng-template
            >

            <button (click)="restoreBackupSession()" class="nav-bar__restore-btn" mat-raised-button>
              <mat-icon aria-hidden="false">restore</mat-icon> Restore backup session
            </button>
          </mat-menu>

          <button
            (click)="checkBalance(user)"
            [matMenuTriggerFor]="menuUsage"
            mat-button
            class="nav-bar__usage nav-bar__border"
          >
            <mat-icon
              [ngClass]="
                stripeRole[stripeRole.FREE] === stripeRole[authService.role | async]
                  ? 'nav-bar__usage-icon'
                  : 'nav-bar__usage-icon--active'
              "
              aria-hidden="false"
              >data_usage</mat-icon
            >
            <span>
              {{ usageCounter }}
            </span>
          </button>

          <button (click)="openDataboxDialog()" mat-button class="nav-bar__usage nav-bar__border">
            <mat-icon
              [matBadge]="unreadDataboxEntries$ | async"
              matBadgeColor="primary"
              class="nav-bar__usage-icon"
              aria-hidden="false"
              >verified</mat-icon
            >
          </button>

          <mat-menu class="nav-bar__usage-menu" #menuUsage="matMenu" xPosition="before">
            <div class="nav-bar__plan-display" *ngIf="{ role: authService.role | async } as context">
              <ng-container *ngIf="stripeRole[context.role] === stripeRole[stripeRole.FREE]; else proPlan"
                >You're on the free plan (limited to {{ freeChecks }} checks per month).</ng-container
              >
              <ng-template #proPlan>
                <p *ngIf="stripeRole[context.role] === stripeRole[stripeRole.BASIC_PLAN]">You're on the basic plan.</p>
                <p *ngIf="stripeRole[context.role] === stripeRole[stripeRole.PRO_PLAN]">You're on the pro plan.</p>

                <div *ngIf="invoice$ | async as invoice; else invoiceLoading">
                  Next invoice value:
                  <ng-container *ngIf="invoice.currency === 'eur'; else notEur">€</ng-container>
                  <ng-template #notEur>invoice.currency</ng-template>
                  {{ invoice.amount_due / 100 | number: '1.2' }} (updated every 24 hours)
                </div>
                <ng-template #invoiceLoading
                  ><div class="nav-bar__loading-spinner-container">
                    Next invoice value:
                    <div class="nav-bar__loading-spinner">
                      <mat-progress-spinner diameter="17" mode="indeterminate"></mat-progress-spinner>
                    </div></div
                ></ng-template>
              </ng-template>
            </div>
          </mat-menu>
        </ng-container>

        <button mat-button [matMenuTriggerFor]="menu" class="nav-bar__border nav-bar__menu-button">
          <img referrerpolicy="no-referrer" class="nav-bar__avatar" [src]="user.photoURL" /> {{ user.displayName }}
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
          <button *ngIf="user.firstTimeLoginCompleted" routerLink="/settings" mat-menu-item>
            <mat-icon aria-hidden="false">settings</mat-icon>Settings
          </button>
          <button *ngIf="user.firstTimeLoginCompleted" routerLink="/subscription" mat-menu-item>
            <mat-icon aria-hidden="false">bar_chart</mat-icon>Subscription
          </button>
          <button mat-menu-item (click)="signOut()"><mat-icon aria-hidden="false">logout</mat-icon>Log out</button>
        </mat-menu>
      </ng-container>

      <ng-template #login>
        <button routerLink="/login" mat-raised-button color="primary">Sign in</button>
        <button class="nav-bar__button" routerLink="/register" mat-raised-button color="primary">Register</button>
      </ng-template>
    </div>
  </div>
</mat-toolbar>

<mat-progress-bar
  *ngIf="vatService.checkStarted && !vatService.checkFinished; else replacer"
  mode="determinate"
  [value]="vatService.checkedRowsProgress"
></mat-progress-bar>
<ng-template #replacer>
  <div class="nav-bar__progress-bar-replacer"></div>
</ng-template>
