import { BlockedLocationComponent } from './blocked-location/blocked-location.component';
import { LocationGuard } from './_guards/location.guard';
import { EncryptionGuard } from './_guards/encryption.guard';
import { NotFoundComponent } from './not-found/not-found.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { RedirectGuard } from './_guards/redirect.guard';
import { CreateProfileComponent } from './create-profile/create-profile.component';
import { VatCheckGuard } from './_guards/vat-check.guard';
import { PendingChangesGuard } from './_guards/pending-changes.guard';
import { ImportComponent } from './import/import.component';
import { RegisterComponent } from './register/register.component';
import { SettingsComponent } from './settings/settings.component';
import { LoginComponent } from './login/login.component';
import { VatCheckComponent } from './vat-check/vat-check.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: VatCheckComponent,
    canActivate: [AuthGuard, EncryptionGuard, LocationGuard],
  },
  { path: 'login', component: LoginComponent, canActivate: [RedirectGuard, LocationGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [RedirectGuard, LocationGuard] },
  { path: 'password-reset', component: PasswordResetComponent, canActivate: [RedirectGuard, LocationGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard, LocationGuard] },
  {
    path: 'import',
    component: ImportComponent,
    canActivate: [AuthGuard, VatCheckGuard, EncryptionGuard, LocationGuard],
    canDeactivate: [PendingChangesGuard],
  },
  { path: 'create-profile', component: CreateProfileComponent, canActivate: [AuthGuard, LocationGuard] },
  { path: 'subscription', component: SubscriptionComponent, canActivate: [AuthGuard, LocationGuard] },
  { path: '404', component: NotFoundComponent, canActivate: [LocationGuard] },
  { path: 'service-unavailable', component: BlockedLocationComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
