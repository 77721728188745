<div [hidden]="!(authService.user$ | async)" class="container vat-check">
  <app-adblock-enabled-banner></app-adblock-enabled-banner>
  <app-verified-banner></app-verified-banner>
  <app-finished-banner (reportFinishedEvent)="reportFinished($event)"></app-finished-banner>

  <div class="vat-check__action-row">
    <div>
      <button
        [disabled]="vatService.checkStarted && !vatService.checkFinished"
        routerLink="/import"
        mat-raised-button
        color="primary"
        type="submit"
      >
        <mat-icon aria-hidden="false">auto_fix_high</mat-icon> Data import
      </button>
    </div>
    <div>
      <button
        [disabled]="vatService.checkStarted && !vatService.checkFinished"
        mat-raised-button
        color="primary"
        (click)="openDataManagerDialog()"
      >
        <mat-icon aria-hidden="false">table_chart</mat-icon>
        Stored alternative data
      </button>
    </div>
  </div>

  <br />
  <div class="vat-check__table-parent">
    <div class="vat-check__table" *ngIf="vatService.importedData.length > 0; else emptyState">
      <app-table-filter
        [dataSource]="vatService.dataSource"
        [currentFilters]="vatService.currentFilters"
      ></app-table-filter>

      <br />
      <div class="mat-elevation-z8">
        <table mat-table matSort [dataSource]="vatService.dataSource">
          <ng-container [matColumnDef]="propertyName.UID.toString()">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>VAT No.</th>
            <td mat-cell *matCellDef="let element">{{ element.UID }}</td>
          </ng-container>

          <ng-container [matColumnDef]="propertyName.Name.toString()">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td *matCellDef="let element">
              <ng-container *ngIf="element.acceptedResponse; else noAcceptedResponse">
                {{ element.acceptedResponse.name }}
              </ng-container>
              <ng-template #noAcceptedResponse>
                {{ element.Name }}
              </ng-template>
            </td>
          </ng-container>

          <ng-container [matColumnDef]="propertyName.Addr1.toString()">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Addr1</th>
            <td *matCellDef="let element">
              <ng-container *ngIf="element.acceptedResponse; else noAcceptedResponse">
                {{ element.acceptedResponse.address.Addr1 }}
              </ng-container>
              <ng-template #noAcceptedResponse>
                {{ element.address.Addr1 }}
              </ng-template>
            </td>
          </ng-container>

          <ng-container [matColumnDef]="propertyName.Addr2.toString()">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Addr2</th>
            <td *matCellDef="let element">
              <ng-container *ngIf="element.acceptedResponse; else noAcceptedResponse">
                {{ element.acceptedResponse.address.Addr2 }}
              </ng-container>
              <ng-template #noAcceptedResponse>
                {{ element.address.Addr2 }}
              </ng-template>
            </td>
          </ng-container>

          <ng-container [matColumnDef]="propertyName.Addr3.toString()">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Addr3</th>
            <td *matCellDef="let element">
              <ng-container *ngIf="element.acceptedResponse; else noAcceptedResponse">
                {{ element.acceptedResponse.address.Addr3 }}
              </ng-container>
              <ng-template #noAcceptedResponse>
                {{ element.address.Addr3 }}
              </ng-template>
            </td>
          </ng-container>

          <ng-container [matColumnDef]="propertyName.Addr4.toString()">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Addr4</th>
            <td *matCellDef="let element">
              <ng-container *ngIf="element.acceptedResponse; else noAcceptedResponse">
                {{ element.acceptedResponse.address.Addr4 }}
              </ng-container>
              <ng-template #noAcceptedResponse>
                {{ element.address.Addr4 }}
              </ng-template>
            </td>
          </ng-container>

          <ng-container [matColumnDef]="propertyName.Addr5.toString()">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Addr5</th>
            <td *matCellDef="let element">
              <ng-container *ngIf="element.acceptedResponse; else noAcceptedResponse">
                {{ element.acceptedResponse.address.Addr5 }}
              </ng-container>
              <ng-template #noAcceptedResponse>
                {{ element.address.Addr5 }}
              </ng-template>
            </td>
          </ng-container>

          <ng-container [matColumnDef]="propertyName.Addr6.toString()">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Addr6</th>
            <td *matCellDef="let element">
              <ng-container *ngIf="element.acceptedResponse; else noAcceptedResponse">
                {{ element.acceptedResponse.address.Addr6 }}
              </ng-container>
              <ng-template #noAcceptedResponse>
                {{ element.address.Addr6 }}
              </ng-template>
            </td>
          </ng-container>

          <ng-container [matColumnDef]="headerCorrectData">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>validData</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.correctAddress === true && element.correctName === true">
                <mat-icon matTooltip="Address and name are valid" class="vat-check__success-icon" aria-hidden="false"
                  >check</mat-icon
                ></ng-container
              >
              <ng-container *ngIf="element.correctAddress === false && element.correctName === false">
                <mat-icon
                  matTooltip="Address and name are both invalid"
                  class="vat-check__error-icon"
                  aria-hidden="false"
                  >error</mat-icon
                ></ng-container
              >
              <ng-container
                *ngIf="
                  (element.correctAddress === true && element.correctName === false) ||
                  (element.correctAddress === false && element.correctName === true)
                "
              >
                <mat-icon matTooltip="Data is only partially valid" class="vat-check__warning-icon" aria-hidden="false"
                  >warning</mat-icon
                >
              </ng-container>
            </td>
          </ng-container>

          <ng-container [matColumnDef]="headerDataMatch">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>dataMatchAvg</th>
            <td mat-cell *matCellDef="let element">
              <div
                class="vat-check__match-element"
                [matTooltip]="
                  'Average between name (' +
                  (element.importNameMatchingPercent * 100 | number : '1.2-2') +
                  '%) and address (' +
                  (element.importAddressMatchingPercent * 100 | number : '1.2-2') +
                  ')%'
                "
                *ngIf="element.importAddressMatchingPercent >= 0 || element.importNameMatchingPercent >= 0"
              >
                {{
                  ((element.importAddressMatchingPercent + element.importNameMatchingPercent) / 2) * 100
                    | number : '1.2-2'
                }}%
              </div>
            </td>
          </ng-container>

          <ng-container [matColumnDef]="headerValid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>validVATNo.</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.apiResponse?.valid">
                <mat-icon matTooltip="VAT No. is valid" class="vat-check__success-icon" aria-hidden="false"
                  >check</mat-icon
                ></ng-container
              >
              <ng-container *ngIf="element.apiResponse?.valid === false">
                <mat-icon matTooltip="VAT No. is invalid" class="vat-check__error-icon" aria-hidden="false"
                  >error</mat-icon
                ></ng-container
              >
            </td>
          </ng-container>

          <ng-container [matColumnDef]="headerState">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
            <td mat-cell *matCellDef="let element">
              {{ state[element.state] }}
            </td>
          </ng-container>

          <ng-container [matColumnDef]="headerAction">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Details</th>
            <td mat-cell *matCellDef="let element">
              <button
                *ngIf="element.apiResponse?.valid != null; else detailsDisabled"
                (click)="openRowDialog(element)"
                mat-button
              >
                <mat-icon aria-hidden="false">manage_search</mat-icon>
              </button>
              <ng-template #detailsDisabled>
                <button disabled mat-button>
                  <mat-icon aria-hidden="false">manage_search</mat-icon>
                </button>
              </ng-template>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>

      <br />
      <div class="vat-check__action-row">
        <div>
          <button
            *ngIf="!vatService.checkFinished; else checkAllWhenFinished"
            [disabled]="vatService.checkStarted && !vatService.checkFinished"
            (click)="vatService.startCheck(0, false)"
            mat-raised-button
            color="primary"
          >
            Start check
          </button>
          <ng-template #checkAllWhenFinished>
            <button
              [disabled]="(vatService.checkStarted && !vatService.checkFinished) || checkUncheckedBtnDisabled"
              (click)="vatService.startCheck(0, false)"
              mat-raised-button
              color="primary"
            >
              Check unfinished
            </button>
            <button
              [disabled]="vatService.checkStarted && !vatService.checkFinished"
              (click)="checkAll()"
              mat-raised-button
              color="accent"
              class="vat-check__action-btn"
            >
              Check again
            </button>
          </ng-template>
          <button
            *ngIf="!vatService.checkPaused"
            [disabled]="!vatService.checkStarted || vatService.checkFinished"
            class="vat-check__action-btn"
            (click)="vatService.togglePause()"
            mat-raised-button
            color="primary"
          >
            <mat-icon aria-hidden="false">pause</mat-icon>
            Pause
          </button>
          <button
            *ngIf="vatService.checkPaused"
            [disabled]="!vatService.checkStarted || vatService.checkFinished"
            class="vat-check__action-btn"
            (click)="vatService.togglePause()"
            mat-raised-button
            color="primary"
          >
            <mat-icon aria-hidden="false">play_arrow</mat-icon>
            Resume
          </button>

          <button class="vat-check__action-btn" (click)="clearTable()" mat-raised-button color="primary">
            Clear table
          </button>
        </div>
        <div>
          <button
            class="vat-check__action-btn"
            [disabled]="!(vatService.checkPaused || vatService.checkFinished) || !vatService.batchFinished"
            (click)="openFileExportDialog()"
            mat-raised-button
            color="primary"
          >
            <mat-icon aria-hidden="false">download</mat-icon>Export
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-template #emptyState>
    <div class="vat-check__no-data">
      <form [formGroup]="singleVatCheckForm">
        <div class="vat-check__single-input-container">
          <mat-form-field class="vat-check__single-input" appearance="fill">
            <mat-label>Single VAT Number Check</mat-label>
            <input
              (input)="onSingleCheckInputChange()"
              formControlName="taxId"
              matInput
              placeholder="e.g. ATU12345678"
            />
          </mat-form-field>
          <button
            [disabled]="!singleVatCheckForm.valid || spinnerEnabled"
            (click)="singleCheck()"
            class="button-action"
            mat-raised-button
            color="primary"
          >
            <div class="vat-check__confirm-btn-container">
              <div>Validate</div>
              <div *ngIf="spinnerEnabled" class="vat-check__spinner">
                <mat-progress-spinner diameter="17" mode="indeterminate"></mat-progress-spinner>
              </div>
            </div>
          </button>
        </div>
      </form>

      <div *ngIf="singleCheckResponse; else showSvg" class="vat-check__response-container">
        <app-single-check-response
          (responseClearedEvent)="resetSingleCheckResponse()"
          [singleCheckResponse]="singleCheckResponse"
        ></app-single-check-response>
      </div>

      <ng-template #showSvg>
        <svg
          class="vat-check__no-data-img"
          id="Capa_1"
          enable-background="new 0 0 512 512"
          height="512"
          viewBox="0 0 512 512"
          width="512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="m499.923 374.628v60.029c0 7.135-5.775 12.909-12.909 12.909h-462.028c-7.134 0-12.909-5.775-12.909-12.909v-60.029c0-1.37.213-2.73.65-4.029l65.367-198.559c1.735-5.298 6.678-8.87 12.26-8.87h331.293c5.582 0 10.524 3.572 12.26 8.87l65.367 198.558c.436 1.3.649 2.66.649 4.03z"
                    fill="#9e9797"
                  />
                  <path
                    d="m499.923 374.628v60.029c0 7.135-5.775 12.909-12.909 12.909h-77.617c-.264-91.51 1.147-242.543 1.563-284.396h10.687c5.582 0 10.524 3.572 12.26 8.87l65.367 198.558c.436 1.3.649 2.66.649 4.03z"
                    fill="#b9acac"
                  />
                  <path
                    d="m12.074 374.628v60.029c0 7.135 5.775 12.909 12.909 12.909h77.617c.264-91.51-1.147-242.543-1.563-284.396h-10.686c-5.582 0-10.524 3.572-12.26 8.87l-65.367 198.559c-.436 1.299-.65 2.659-.65 4.029z"
                    fill="#b9acac"
                  />
                  <path
                    d="m450.878 370.594-39.805-120.907c-1.743-5.294-6.686-8.871-12.26-8.871h-285.627c-5.573 0-10.517 3.577-12.26 8.871l-39.804 120.907c-.429 1.303-.647 2.665-.647 4.036v60.03c0 7.128 5.779 12.907 12.907 12.907h365.237c7.128 0 12.907-5.779 12.907-12.907v-60.03c-.001-1.371-.219-2.734-.648-4.036z"
                    fill="#756f6f"
                  />
                  <ellipse cx="256" cy="321.285" fill="#5b5555" rx="94.356" ry="26.528" />
                  <path
                    d="m451.525 374.633v60.03c0 7.125-5.782 12.907-12.907 12.907h-44.903c7.125 0 12.907-5.782 12.907-12.907v-60.03c0-1.368-.219-2.736-.645-4.04l-39.805-120.911c-1.742-5.292-6.686-8.867-12.261-8.867h44.903c5.576 0 10.519 3.575 12.261 8.867l39.805 120.911c.426 1.304.645 2.672.645 4.04z"
                    fill="#5b5555"
                  />
                  <path
                    d="m499.926 387.088v112.005c0 7.128-5.779 12.907-12.907 12.907h-462.038c-7.128 0-12.907-5.779-12.907-12.907v-112.005c0-7.128 5.779-12.907 12.907-12.907h95.297c4.932 0 9.433 2.811 11.598 7.243l18.726 38.342c2.164 4.432 6.665 7.243 11.598 7.243h187.6c4.932 0 9.433-2.811 11.598-7.243l18.726-38.342c2.164-4.432 6.665-7.243 11.598-7.243h95.297c7.128 0 12.907 5.779 12.907 12.907z"
                    fill="#31a7fb"
                  />
                  <path
                    d="m499.926 387.088v112.005c0 7.125-5.782 12.907-12.907 12.907h-49.147c7.125 0 12.907-5.782 12.907-12.907v-112.005c0-7.124-5.782-12.907-12.907-12.907h49.147c7.124 0 12.907 5.783 12.907 12.907z"
                    fill="#1c96f9"
                  />
                  <g fill="#f8f3f1">
                    <path
                      d="m78.761 484.49h-27.854c-4.204 0-7.611-3.408-7.611-7.611s3.408-7.611 7.611-7.611h27.854c4.204 0 7.611 3.408 7.611 7.611s-3.407 7.611-7.611 7.611z"
                    />
                    <path
                      d="m78.761 460.713h-27.854c-4.204 0-7.611-3.408-7.611-7.612s3.408-7.611 7.611-7.611h27.854c4.204 0 7.611 3.408 7.611 7.611.001 4.204-3.407 7.612-7.611 7.612z"
                    />
                  </g>
                </g>
                <g>
                  <circle cx="256" cy="236.198" fill="#a3e440" r="88.317" />
                  <path
                    d="m344.316 236.196c0 48.779-39.543 88.322-88.311 88.322-5.929 0-11.724-.583-17.328-1.704 40.474-8.059 70.972-43.78 70.972-86.618s-30.498-78.548-70.972-86.607c5.604-1.121 11.399-1.704 17.328-1.704 48.768 0 88.311 39.532 88.311 88.311z"
                    fill="#99d53b"
                  />
                  <g>
                    <path
                      d="m273.552 270.1c-1.748 0-3.504-.599-4.938-1.822-3.484-2.974-8.452-4.688-13.632-4.704-.023 0-.047 0-.071 0-5.153 0-10.101 1.683-13.587 4.622-3.213 2.711-8.016 2.302-10.725-.912-2.71-3.214-2.302-8.015.912-10.725 6.307-5.32 14.674-8.227 23.518-8.208 8.859.027 17.193 2.992 23.469 8.348 3.197 2.729 3.577 7.534.848 10.731-1.507 1.764-3.644 2.67-5.794 2.67z"
                      fill="#82b732"
                    />
                    <g>
                      <g>
                        <path
                          d="m214.034 235.006c-.014 0-.028 0-.043 0-4.204-.023-7.638-3.449-7.615-7.653s3.403-7.544 7.608-7.57h.091c4.204.023 7.593 3.449 7.57 7.653-.023 4.189-3.427 7.57-7.611 7.57z"
                          fill="#82b732"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <path
                    d="m105.915 80.697c0-14.991 12.152-27.143 27.143-27.143s27.142 12.152 27.142 27.143-12.152 27.143-27.143 27.143-27.142-12.152-27.142-27.143"
                    fill="#bcea73"
                  />
                  <path
                    d="m119.291 104.087c10.655-2.456 18.593-11.994 18.593-23.393 0-11.387-7.926-20.938-18.581-23.394 4.031-2.382 8.732-3.746 13.756-3.746 14.984 0 27.139 12.156 27.139 27.14 0 14.996-12.156 27.152-27.139 27.152-5.024 0-9.737-1.365-13.768-3.759"
                    fill="#99d53b"
                  />
                </g>
                <g fill="#64b9fc">
                  <path
                    d="m221.122 2.23c-2.972-2.973-7.791-2.973-10.764 0l-6.153 6.153-6.153-6.153c-2.972-2.972-7.792-2.972-10.764 0-2.973 2.972-2.973 7.792 0 10.764l6.153 6.153-6.153 6.153c-2.973 2.972-2.973 7.792 0 10.764 1.486 1.487 3.434 2.229 5.382 2.229 1.947 0 3.896-.743 5.382-2.229l6.153-6.153 6.153 6.153c1.486 1.486 3.434 2.229 5.382 2.229s3.896-.743 5.382-2.229c2.973-2.972 2.973-7.792 0-10.764l-6.153-6.153 6.153-6.153c2.973-2.973 2.973-7.792 0-10.764z"
                  />
                  <path
                    d="m387.121 93.838 6.153-6.153c2.973-2.972 2.973-7.792 0-10.764-2.972-2.972-7.792-2.972-10.764 0l-6.153 6.153-6.153-6.153c-2.972-2.972-7.792-2.972-10.764 0-2.973 2.972-2.973 7.792 0 10.764l6.153 6.153-6.153 6.153c-2.973 2.972-2.973 7.792 0 10.764 1.486 1.486 3.434 2.229 5.382 2.229s3.896-.743 5.382-2.229l6.153-6.153 6.153 6.153c1.486 1.486 3.434 2.229 5.382 2.229s3.896-.743 5.382-2.229c2.973-2.972 2.973-7.792 0-10.764z"
                  />
                </g>
              </g>
              <g fill="#bcea73">
                <path
                  d="m255.605 109.604c-.008 0-.015 0-.023 0-4.204-.012-7.601-3.431-7.589-7.634l.067-22.666c.012-4.196 3.418-7.589 7.611-7.589h.023c4.204.012 7.601 3.431 7.589 7.634l-.067 22.666c-.012 4.196-3.418 7.589-7.611 7.589z"
                />
                <path
                  d="m221.81 119.265c-2.588 0-5.111-1.32-6.537-3.701l-11.777-19.662c-2.16-3.607-.988-8.281 2.618-10.441 3.607-2.161 8.28-.988 10.441 2.618l11.777 19.662c2.16 3.607.988 8.281-2.618 10.441-1.225.733-2.573 1.083-3.904 1.083z"
                />
                <path
                  d="m289.34 119.575c-1.345 0-2.708-.357-3.942-1.106-3.593-2.181-4.738-6.863-2.557-10.456l11.894-19.597c2.181-3.594 6.862-4.738 10.456-2.557 3.593 2.181 4.738 6.863 2.557 10.456l-11.894 19.597c-1.432 2.359-3.942 3.663-6.514 3.663z"
                />
              </g>
            </g>
            <g>
              <circle cx="325.478" cy="38.815" fill="#a8e7ef" r="21.56" />
              <path
                d="m347.036 38.813c0 11.912-9.655 21.567-21.557 21.567-3.99 0-7.734-1.084-10.936-2.985 8.463-1.951 14.769-9.527 14.769-18.582 0-9.045-6.296-16.631-14.759-18.582 3.202-1.892 6.936-2.975 10.926-2.975 11.902-.001 21.557 9.655 21.557 21.557z"
                fill="#70d9e6"
              />
            </g>
          </g>
          <path
            d="m298.048 235.006c-.014 0-.028 0-.043 0-4.204-.023-7.638-3.45-7.615-7.654.023-4.189 3.382-7.57 7.565-7.57h.043.09c4.204.023 7.593 3.449 7.57 7.653-.023 4.19-3.427 7.571-7.61 7.571z"
            fill="#82b732"
          />
        </svg>

        <h2>No data imported</h2>
        <p>Import a new dataset, validate a single VAT number or restore a recent backup.</p>
      </ng-template>
    </div>
  </ng-template>
</div>
