import { AuthService } from 'src/app/_services/auth.service';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-verified-banner',
  templateUrl: './verified-banner.component.html',
  styleUrls: ['./verified-banner.component.scss'],
})
export class VerifiedBannerComponent implements OnInit {
  confirmationEmailResent = false;

  constructor(public authService: AuthService, private _snackBar: MatSnackBar) {}

  ngOnInit(): void {}

  async sendEmailVerification(): Promise<void> {
    this.confirmationEmailResent = true;
    try {
      await this.authService.sendEmailVerification();
      this._snackBar.open('Verification email sent!', 'OK', {
        duration: 2000,
      });
    } catch (error) {
      console.error(error);
      this._snackBar.open('Failed to send verification email', 'OK', {
        duration: 5000,
        panelClass: ['snackbar-warn'],
      });
    }
  }
}
