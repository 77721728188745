import { Component, Input, OnInit } from '@angular/core';
import { Currency } from 'src/app/_classes/currency';

@Component({
  selector: 'app-upcoming-invoice',
  templateUrl: './upcoming-invoice.component.html',
  styleUrls: ['./upcoming-invoice.component.scss'],
})
export class UpcomingInvoiceComponent implements OnInit {
  @Input() private invoice: any;
  items: Array<{ name: string; amt: number; tax: number; ges: number; cur: string }>;
  sums: { woTax: number | string; tax: number | string; ges: number | string };
  currency: string;

  constructor() {
    this.items = [];
    this.sums = { woTax: 0, tax: 0, ges: 0 };
  }

  ngOnInit(): void {
    let currencies = [];
    for (let el of this.invoice?.lines?.data ?? []) {
      if (el.amount_excluding_tax < 0) continue;
      const amt = el.amount_excluding_tax / 100;
      const tax = el.tax_amounts ? el.tax_amounts[0]?.amount / 100 ?? 0 : 0;

      if (typeof this.sums.woTax == 'number') this.sums.woTax += amt;
      if (typeof this.sums.tax == 'number') this.sums.tax += tax;
      if (typeof this.sums.ges == 'number') this.sums.ges += amt + tax;
      currencies.push(el.currency);
      this.items.push({
        name: el.description,
        amt: amt,
        tax: tax,
        ges: amt + tax,
        cur: Currency.getSymbolFor(el.currency),
      });
    }
    currencies = [...new Set(currencies)];
    if (currencies.length > 1 || currencies.length == 0) {
      this.sums = { woTax: '#NV', tax: '#NV', ges: '#NV' };
    } else {
      this.sums = {
        woTax: this.invoice.subtotal_excluding_tax / 100,
        tax: this.invoice.tax / 100,
        ges: this.invoice.total / 100,
      };
      this.currency = Currency.getSymbolFor(currencies[0]);
    }
  }
}
