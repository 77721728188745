<!-- {{ singleCheckResponse | json }} -->
<mat-card class="single-check-response__card">
  <div class="single-check-response__message-row">
    <div *ngIf="singleCheckResponse.valid; else invalidMessage" class="single-check-response__message">
      <mat-icon class="single-check-response__success-icon" aria-hidden="false">check_circle</mat-icon>
      VAT number is valid!
    </div>
    <ng-template #invalidMessage>
      <div class="single-check-response__message">
        <mat-icon class="single-check-response__error-icon" aria-hidden="false">error</mat-icon>
        VAT number is invalid!
      </div></ng-template
    >

    <button (click)="clearSingleCheck()" class="single-check-response__clear-btn" mat-raised-button color="secondary">
      Clear
    </button>
  </div>

  <div class="single-check-response__row">
    <div class="single-check-response__col--left">
      <div>Valid VAT Number:</div>
      <div>Country Code:</div>
      <div>VAT Number:</div>
      <div>Company Name:</div>
      <div>Request Date:</div>
      <div>Address (adrz1):</div>
      <div>Address (adrz2):</div>
      <div>Address (adrz3):</div>
      <div>Address (adrz4):</div>
      <div>Address (adrz5):</div>
      <div>Address (adrz6):</div>
    </div>

    <div class="single-check-response__col--right">
      <div class="single-check-response__valid" *ngIf="singleCheckResponse.valid; else invalid">
        {{ singleCheckResponse.valid }}
      </div>
      <ng-template #invalid
        ><div class="single-check-response__invalid">{{ singleCheckResponse.valid }}</div>
      </ng-template>
      <div>{{ singleCheckResponse.countryCode }}</div>
      <div>{{ singleCheckResponse.vatNumber }}</div>
      <div>{{ singleCheckResponse.name }}</div>
      <div>{{ singleCheckResponse.requestDate }}</div>
      <div>{{ singleCheckResponse.address.Addr1 }}</div>
      <div>{{ singleCheckResponse.address.Addr2 }}</div>
      <div>{{ singleCheckResponse.address.Addr3 }}</div>
      <div>{{ singleCheckResponse.address.Addr4 }}</div>
      <div>{{ singleCheckResponse.address.Addr5 }}</div>
      <div>{{ singleCheckResponse.address.Addr6 }}</div>
    </div>
  </div>
</mat-card>
