<div class="container">
  <div>
    <h1>Login</h1>
    <div>
      <form [formGroup]="loginForm" (ngSubmit)="loginEmail()">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input
            autocomplete="email"
            formControlName="email"
            name="email"
            type="email"
            matInput
            placeholder="john.doe@gmail.com"
          /> </mat-form-field
        ><br />
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input autocomplete="current-password" formControlName="password" name="password" type="password" matInput />
        </mat-form-field>
        <br />
        <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid">Authenticate</button>
      </form>
    </div>
  </div>
</div>
