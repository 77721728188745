<h1 mat-dialog-title>Stored Templates</h1>
<mat-dialog-content class="mat-typography">
  <table class="dialog__table-template">
    <tr class="dialog__table-template-tr">
      <th class="dialog__table-template-th">Name</th>
      <th class="dialog__table-template-th">Date Created</th>
      <th class="dialog__table-template-th dialog__table-template-th-action">Action</th>
    </tr>
    <tr class="dialog__table-template-tr" *ngFor="let template of templates$ | async">
      <td>
        {{ template.name }}
      </td>
      <td>
        {{ template.timeStamp['seconds'] * 1000 | date: 'dd-MM-yyyy HH:mm' }}
      </td>
      <td>
        <button mat-raised-button color="primary" type="submit" disabled="false" (click)="selectTemplate(template)">
          Select
        </button>

        <button class="dialog__template-delete-button" mat-button (click)="deleteTemplate(template)">
          <mat-icon aria-hidden="false">delete_outline</mat-icon>
        </button>
      </td>
    </tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
