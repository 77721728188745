import { StripeRole } from './../_enums/stripe-role.enum';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Injectable } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IUser } from '../_interfaces/user';
import firebase from 'firebase/compat/app';

const DEFAULT_AVATAR =
  'https://firebasestorage.googleapis.com/v0/b/vat-check-f32e6.appspot.com/o/default%2Favatar.png?alt=media&token=75bfe8f3-49f7-4cc9-8d54-a8a4579ef05a';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  role: Promise<StripeRole>;
  user$: Observable<IUser>;
  authState$: Observable<firebase.User>;
  suspendPageCloseWarning = false;
  userLocatedInEU: boolean; // if null, location guard was unable to check the geolocation-db --> user has adblock enabled
  private subscriptions: Subscription[] = [];

  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore, private aff: AngularFireFunctions) {
    this.authState$ = this.afAuth.authState;

    this.subscriptions.push(
      this.authState$.subscribe(async (user) => {
        if (user) {
          this.role = this.getStripeRole();
        }
      })
    );

    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.db.doc<IUser>(`users/${user.uid}`).valueChanges();
        } else {
          // Logged out
          return of(null);
        }
      })
    );
  }

  async getStripeRole(): Promise<StripeRole> {
    try {
      await firebase.auth().currentUser.getIdToken(true);
      const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
      if (decodedToken.claims.stripeRole === 'basic_plan') {
        return StripeRole.BASIC_PLAN;
      } else if (decodedToken.claims.stripeRole === 'pro_plan') {
        return StripeRole.PRO_PLAN;
      } else {
        return StripeRole.FREE;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  getStripePortalLink(): Observable<any> {
    const callable = this.aff.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    return callable({ returnUrl: window.location.origin });
  }

  async emailRegister(email: string, password: string): Promise<void> {
    try {
      const credential = await this.afAuth.createUserWithEmailAndPassword(email, password);
      // create new user
      const user: IUser = {
        uid: credential.user.uid,
        email: credential.user.email,
        displayName: credential.user.displayName,
        photoURL: DEFAULT_AVATAR,
        preferences: {
          alternativeDataCloudStorage: true,
          sessionsCloudStorage: true,
          importSchemeCloudStorage: true,
          useMatchingPrediction: true,
          matchingThreshold: 0.9,
          encryptionEnabled: false,
          passwordHash: null,
        },
        firstTimeLoginCompleted: false,
      };
      await this.updateUser(user);
      return this.sendEmailVerification();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async sendEmailVerification(): Promise<void> {
    try {
      return (await this.afAuth.currentUser).sendEmailVerification();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async sendPasswordResetEmail(passwordResetEmail: string): Promise<void> {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail);
  }

  async emailSignin(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  googleSignin(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();

      try {
        const authRes = await this.afAuth.signInWithPopup(provider);
        this.user$.pipe(take(1)).subscribe(async (user) => {
          if (user && user.preferences) {
            user.displayName = authRes.user.displayName;
            user.uid = authRes.user.uid;
            user.email = authRes.user.email;
            user.photoURL = authRes.user.photoURL;
            await this.updateUser(user);
            resolve();
          } else {
            // create new user
            const newUser: IUser = {
              displayName: authRes.user.displayName,
              uid: authRes.user.uid,
              email: authRes.user.email,
              photoURL: authRes.user.photoURL,
              preferences: {
                alternativeDataCloudStorage: true,
                sessionsCloudStorage: true,
                importSchemeCloudStorage: true,
                useMatchingPrediction: true,
                matchingThreshold: 0.9,
                encryptionEnabled: false,
                passwordHash: null,
              },
              firstTimeLoginCompleted: false,
            };
            await this.updateUser(newUser);
            resolve();
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  updateUser(user: IUser): Promise<void> {
    // used updateUser() here instead of firestore.service to avoid circular dependency

    user.displayName = user.displayName ? user.displayName : user.email;
    if (!user.uid) {
      user.uid = this.db.createId();
    }

    return this.db.collection('users').doc(user.uid).set(user, { merge: true });
  }

  async signOut(): Promise<void> {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    return this.afAuth.signOut();
  }
}
