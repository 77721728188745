export const environment = {
  production: false,
  useFirebaseVatCheck: true,
  firebase: {
    apiKey: 'AIzaSyBZ9JTBKv8tJ_-8amT7aj468ObUT7yKEKU',
    authDomain: 'vat-check-f32e6.firebaseapp.com',
    databaseURL: 'https://vat-check-f32e6.firebaseio.com',
    projectId: 'vat-check-f32e6',
    storageBucket: 'vat-check-f32e6.appspot.com',
    messagingSenderId: '933387736334',
    appId: '1:933387736334:web:f8ffca8548d57cac81335f',
    measurementId: 'G-K0J2VD1PF4',
  },
  // test keys not working in FE as long as BE uses live keys
  // stripe: {
  //   basicPriceId: 'price_1JEGhYCboKS58xMPGoojlx9Q',
  //   proPriceId: 'price_1JEGiOCboKS58xMPKq5Hgbaw',
  //   volumeBasicPriceId: 'price_1JEGlICboKS58xMPOJTOGscJ',
  //   volumeProPriceId: 'price_1JEGm9CboKS58xMPiIv1kExb',
  //   stripePrivateKey: 'pk_test_aAXHZRWSCh2KEvFJDZP5Fep700STWyUzj5',
  //   austrianTaxRateId: 'txr_1Ix8m0CboKS58xMP84WIE13M',
  // },
  stripe: {
    basicPriceId: 'price_1MI5AACboKS58xMPIvlKoDO9',
    proPriceId: 'price_1MI59zCboKS58xMPps64RDad',
    volumeBasicPriceId: 'price_1MI5A6CboKS58xMPxoVyZ1FO',
    volumeProPriceId: 'price_1MI59tCboKS58xMPskoM1s1Y',
    stripePrivateKey: 'pk_live_04yYyxd67LSjqCkjFSxV05Ek00Uca5EWdm',
    austrianTaxRateId: 'txr_1MJfhwCboKS58xMP2giFnLsh',
  },
};
