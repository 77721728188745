import { ApiResponse } from './../../_classes/api-response';
import { Address } from './../../_classes/address';
import { IFileExportOutput } from './../../_interfaces/ifile-export-output';
import { AuthService } from './../../_services/auth.service';
import { FirestoreService } from 'src/app/_services/firestore.service';
import { VatService } from './../../_services/vat.service';
import { ICompanyTableRow } from './../../_interfaces/company-table-row';
import { Component, Inject, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { PropertyName } from 'src/app/_enums/property-name.enum';
import { State } from 'src/app/_enums/state.enum';
import { take } from 'rxjs/operators';

const HEADER_VALID_UID = 'validVATNo';
const HEADER_DATA_CORRECT = 'correctData';
const HEADER_STATE = 'state';
const APPEND_HEADERS: string[] = [HEADER_VALID_UID, HEADER_DATA_CORRECT, HEADER_STATE];

export interface Checkbox {
  name: string;
  checked: boolean;
  color: ThemePalette;
  subCheckboxes?: Checkbox[];
  filterState?: State;
}

export interface ExportBuildData {
  uidData: any[];
  alternativeData: any[];
}

@Component({
  selector: 'app-file-export-dialog',
  templateUrl: './file-export-dialog.component.html',
  styleUrls: ['./file-export-dialog.component.scss'],
})
export class FileExportDialogComponent implements OnInit {
  exportButtonDisabled = false;
  filterCheckboxAllChecked = true;
  filterCheckbox: Checkbox = {
    name: 'Export all rows',
    checked: true,
    color: 'primary',
    subCheckboxes: [
      {
        name: 'Export rows with state CHECKED',
        checked: true,
        color: 'primary',
        filterState: State.CHECKED,
      },
      {
        name: 'Export rows with state UNCHECKED',
        checked: true,
        color: 'primary',
        filterState: State.UNCHECKED,
      },
      {
        name: 'Export rows with state ERROR',
        checked: true,
        color: 'primary',
        filterState: State.ERROR,
      },
      {
        name: 'Export rows with state UNAVAILABLE',
        checked: true,
        color: 'primary',
        filterState: State.UNAVAILABLE,
      },
    ],
  };
  overrideAcceptedResponseCheckbox: Checkbox = {
    name: 'Override with accepted response data',
    checked: true,
    color: 'primary',
  };
  overrideResponseIfAltExistsCheckbox: Checkbox = {
    name: 'Override with response data if matching alternative data exists',
    checked: false,
    color: 'primary',
  };
  overrideResponseIfUidValidCheckbox: Checkbox = {
    name: 'Override with response data if VAT number is valid',
    checked: false,
    color: 'primary',
  };
  exportAllColumnsCheckbox: Checkbox = {
    name: 'Export all columns in imported format (using original names)',
    checked: true,
    color: 'primary',
  };
  exportMinimumColumnsCheckbox: Checkbox = {
    name: 'Export only displayed columns (using standardized names)',
    checked: false,
    color: 'primary',
  };
  appendValidColumnCheckbox: Checkbox = {
    name: 'Append isValid column (true/false/null)',
    checked: true,
    color: 'primary',
  };
  appendDataCorrectCheckbox: Checkbox = {
    name: 'Append dataCorrect column (true/false/null)',
    checked: true,
    color: 'primary',
  };
  appendStateCheckbox: Checkbox = {
    name: 'Append state column (CHECKED/UNCHECKED/ERROR/UNAVAILABLE)',
    checked: true,
    color: 'primary',
  };
  appendAlternativeDataSheetCheckbox: Checkbox = {
    name: 'Append sheet with alternative data',
    checked: false,
    color: 'primary',
  };
  deleteSessionCheckbox: Checkbox = {
    name: 'Delete current session backup from cloud after export',
    checked: false,
    color: 'accent',
  };
  deleteLocalDataCheckbox: Checkbox = {
    name: 'Delete data and clear table after export',
    checked: false,
    color: 'accent',
  };

  constructor(public dialogRef: MatDialogRef<any>, private vatService: VatService) {}

  ngOnInit(): void {}

  private closeDialog(): void {
    this.dialogRef.close();
  }

  exportToExcel(): void {
    const exportData = this.buildExport();
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const mainWS = XLSX.utils.aoa_to_sheet(exportData.uidData, { sheetStubs: true });
    const alternativeDataWS = XLSX.utils.aoa_to_sheet(exportData.alternativeData, {
      sheetStubs: true,
    });
    XLSX.utils.book_append_sheet(wb, mainWS, 'Results');
    if (this.appendAlternativeDataSheetCheckbox.checked) {
      XLSX.utils.book_append_sheet(wb, alternativeDataWS, 'AlternativeData');
    }
    XLSX.writeFile(wb, 'export.xlsx');

    const output: IFileExportOutput = {
      clearTable: this.deleteLocalDataCheckbox.checked,
      deleteCurrentSession: this.deleteSessionCheckbox.checked,
    };
    this.dialogRef.close(output);
  }

  private buildExport(): ExportBuildData {
    const uidDataAOA: any[] = [];
    const alternativeDataAOA: any[] = [];

    if (this.appendAlternativeDataSheetCheckbox.checked) {
      // set alternative data headers (optional sheet)
      alternativeDataAOA[0] = [
        PropertyName[PropertyName.UID],
        PropertyName[PropertyName.Name],
        PropertyName[PropertyName.Addr1],
        PropertyName[PropertyName.Addr2],
        PropertyName[PropertyName.Addr3],
        PropertyName[PropertyName.Addr4],
        PropertyName[PropertyName.Addr5],
        PropertyName[PropertyName.Addr6],
      ];
    }

    // set results headers
    if (this.exportAllColumnsCheckbox.checked) {
      uidDataAOA[0] = JSON.parse(JSON.stringify(this.vatService.importedHeaders));
    } else {
      uidDataAOA[0] = [
        PropertyName[PropertyName.UID],
        PropertyName[PropertyName.Name],
        PropertyName[PropertyName.Addr1],
        PropertyName[PropertyName.Addr2],
        PropertyName[PropertyName.Addr3],
        PropertyName[PropertyName.Addr4],
        PropertyName[PropertyName.Addr5],
        PropertyName[PropertyName.Addr6],
      ];
    }
    if (this.appendValidColumnCheckbox.checked) {
      uidDataAOA[0].push(HEADER_VALID_UID);
    }
    if (this.appendDataCorrectCheckbox.checked) {
      uidDataAOA[0].push(HEADER_DATA_CORRECT);
    }
    if (this.appendStateCheckbox.checked) {
      uidDataAOA[0].push(HEADER_STATE);
    }

    for (let i = 0; i < this.vatService.importedData.length; i++) {
      const companyTableRow: ICompanyTableRow = this.vatService.importedData[i];
      if (this.rowExportEligible(companyTableRow)) {
        const exportArrayRow: any[] = [];
        // iterate over imported (original) headers
        for (let j = 0; j < uidDataAOA[0].length; j++) {
          const header: string = uidDataAOA[0][j];

          if (this.exportAllColumnsCheckbox.checked) {
            const indexOfHeaderIndex = this.vatService.importedHeadersColumnIndices.indexOf(j);
            if (indexOfHeaderIndex !== -1) {
              // current header is a selected column (propertyName Enum)
              if (indexOfHeaderIndex > 1 && indexOfHeaderIndex < 8) {
                // is address
                exportArrayRow[j] =
                  this.overrideWithResponseData(companyTableRow, PropertyName[indexOfHeaderIndex], true) !== null
                    ? this.overrideWithResponseData(companyTableRow, PropertyName[indexOfHeaderIndex], true)
                    : companyTableRow.address[PropertyName[indexOfHeaderIndex]];
              } else if (indexOfHeaderIndex === 1) {
                // is name
                exportArrayRow[j] =
                  this.overrideWithResponseData(companyTableRow, PropertyName[indexOfHeaderIndex], false) !== null
                    ? this.overrideWithResponseData(companyTableRow, PropertyName[indexOfHeaderIndex], false)
                    : companyTableRow[PropertyName[indexOfHeaderIndex]];
              } else if (indexOfHeaderIndex === 0) {
                exportArrayRow[j] = companyTableRow[PropertyName[indexOfHeaderIndex]];
              } // UID
            } else {
              // else: column was not selected and therfore can be found in additionalData
              if (!APPEND_HEADERS.includes(header)) {
                let duplicateOccurancesBeforeElement = 0;
                for (let k = 0; k < j; k++) {
                  if (
                    header === this.vatService.importedHeaders[k] &&
                    this.vatService.importedHeadersColumnIndices.indexOf(k) == -1
                  ) {
                    duplicateOccurancesBeforeElement += 1;
                  }
                }
                exportArrayRow[j] = companyTableRow.additionalData[header][duplicateOccurancesBeforeElement];
              }
            }
          } else {
            // export displayed data using standardized names
            if (!APPEND_HEADERS.includes(header)) {
              if (j > 1 && j < 8) {
                // address
                exportArrayRow[j] =
                  this.overrideWithResponseData(companyTableRow, uidDataAOA[0][j], true) !== null
                    ? this.overrideWithResponseData(companyTableRow, uidDataAOA[0][j], true)
                    : companyTableRow.address[uidDataAOA[0][j]];
              } else if (j === 1) {
                // name
                exportArrayRow[j] =
                  this.overrideWithResponseData(companyTableRow, uidDataAOA[0][j], false) !== null
                    ? this.overrideWithResponseData(companyTableRow, uidDataAOA[0][j], false)
                    : companyTableRow[uidDataAOA[0][j]];
              } else if (j === 0) {
                exportArrayRow[j] = companyTableRow[uidDataAOA[0][j]];
              } // UID
            }
          }

          // append optional columns
          switch (uidDataAOA[0][j]) {
            case HEADER_VALID_UID:
              exportArrayRow[j] = companyTableRow.apiResponse.valid;
              break;
            case HEADER_DATA_CORRECT:
              exportArrayRow[j] = companyTableRow.correctAddress && companyTableRow.correctName;
              break;
            case HEADER_STATE:
              exportArrayRow[j] = State[companyTableRow.state];
              break;
          }
        }
        // add alternatives to separate sheet
        if (this.appendAlternativeDataSheetCheckbox.checked) {
          companyTableRow.alternativeData?.forEach((element) => {
            alternativeDataAOA.push([
              companyTableRow.UID,
              element.alternativeName,
              element.alternativeAddress.Addr1,
              element.alternativeAddress.Addr2,
              element.alternativeAddress.Addr3,
              element.alternativeAddress.Addr4,
              element.alternativeAddress.Addr5,
              element.alternativeAddress.Addr6,
            ]);
          });
        }
        uidDataAOA.push(exportArrayRow);
      }
    }
    return {
      alternativeData: alternativeDataAOA,
      uidData: uidDataAOA,
    };
  }

  private overrideWithResponseData(companyTableRow: ICompanyTableRow, field: string, isAddress: boolean): any {
    if (this.overrideAcceptedResponseCheckbox.checked && companyTableRow.acceptedResponse) {
      if (isAddress) {
        return companyTableRow.acceptedResponse.address[field];
      } else if (field === PropertyName[PropertyName.Name]) {
        return companyTableRow.acceptedResponse.name;
      }
    }
    if (this.overrideResponseIfAltExistsCheckbox.checked && companyTableRow.apiResponse.valid) {
      for (let i = 0; i < companyTableRow.alternativeData.length; i++) {
        const altData = companyTableRow.alternativeData[i];
        if (
          Address.isEqual(altData.alternativeAddress, companyTableRow.address) &&
          altData.alternativeName === companyTableRow.Name &&
          ApiResponse.isEqual(altData.apiResponse, companyTableRow.apiResponse)
        ) {
          if (isAddress) {
            return companyTableRow.apiResponse.address[field];
          } else if (field === PropertyName[PropertyName.Name]) {
            return companyTableRow.apiResponse.name;
          }
        }
      }
    }
    if (this.overrideResponseIfUidValidCheckbox.checked && companyTableRow.apiResponse.valid) {
      if (isAddress) {
        return companyTableRow.apiResponse.address[field];
      } else if (field === PropertyName[PropertyName.Name]) {
        return companyTableRow.apiResponse.name;
      }
    }
    return null;
  }

  private rowExportEligible(companyTableRow: ICompanyTableRow): boolean {
    for (let i = 0; i < this.filterCheckbox.subCheckboxes.length; i++) {
      const filterCheckbox = this.filterCheckbox.subCheckboxes[i];
      if (companyTableRow.state === filterCheckbox.filterState && filterCheckbox.checked) {
        return true;
      }
    }
    return false;
  }

  private verifyExportButtonDisabled(): void {
    if (this.filterCheckbox.subCheckboxes.filter((item) => item.checked).length === 0) {
      this.exportButtonDisabled = true;
    } else if (!this.exportAllColumnsCheckbox.checked && !this.exportMinimumColumnsCheckbox.checked) {
      this.exportButtonDisabled = true;
    } else {
      this.exportButtonDisabled = false;
    }
  }

  filterCheckboxUpdateAllChecked(): void {
    this.filterCheckboxAllChecked =
      this.filterCheckbox.subCheckboxes != null && this.filterCheckbox.subCheckboxes.every((t) => t.checked);
    this.verifyExportButtonDisabled();
  }

  filterCheckboxSomeChecked(): boolean {
    if (this.filterCheckbox.subCheckboxes == null) {
      return false;
    }
    return this.filterCheckbox.subCheckboxes.filter((t) => t.checked).length > 0 && !this.filterCheckboxAllChecked;
  }

  filterCheckboxSetAll(checked: boolean) {
    this.filterCheckboxAllChecked = checked;
    if (this.filterCheckbox.subCheckboxes == null) {
      return;
    }
    this.filterCheckbox.subCheckboxes.forEach((t) => (t.checked = checked));
    this.verifyExportButtonDisabled();
  }

  exportAllColumnsCheckboxChange($event: boolean) {
    if ($event) {
      this.exportMinimumColumnsCheckbox.checked = false;
    }
    this.verifyExportButtonDisabled();
  }

  exportMinimumColumnsCheckboxChange($event: boolean) {
    if ($event) {
      this.exportAllColumnsCheckbox.checked = false;
    }
    this.verifyExportButtonDisabled();
  }
}
