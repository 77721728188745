<h1 mat-dialog-title>Stored Sessions</h1>
<mat-dialog-content class="mat-typography">
  <mat-form-field>
    <mat-label>Filter Name</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="e.g. Session_01" #input />
  </mat-form-field>
  <div class="mat-elevation-z4">
    <table matSort mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="timeStamp">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Date (imported)</th>
        <td mat-cell *matCellDef="let element">
          {{ element.timeStamp['seconds'] * 1000 | date: 'dd-MM-yyyy HH:mm' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-sort-header class="sessions-dialog__th-action" mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element">
          <button
            (click)="restoreSession(element)"
            class="button-action"
            mat-raised-button
            color="primary"
            type="submit"
          >
            Restore
          </button>
          <button class="sessions-dialog__delete-button" (click)="deleteSession(element)" mat-button>
            <mat-icon aria-hidden="false">delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="sessions-dialog__action-btn" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
