import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  registerForm = this.fb.group({
    email: [
      '',
      [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.maxLength(50)],
    ],
    password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
    repeatedPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
  });

  googleRegisterLoading = false;
  emailRegisterLoading = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  registerEmail() {
    if (this.registerForm.value.password !== this.registerForm.value.repeatedPassword) {
      this.resetRegisterForm();
      this._snackBar.open('The provided passwords do not match', 'OK', {
        duration: 5000,
        panelClass: ['snackbar-warn'],
      });
    } else {
      this.emailRegisterLoading = true;
      this.authService
        .emailRegister(this.registerForm.value.email, this.registerForm.value.password)
        .then(() => {
          this.router.navigateByUrl('');
        })
        .catch((error) => {
          this.emailRegisterLoading = false;
          console.error(error);
          this._snackBar.open('Failed to register new account', 'OK', {
            duration: 5000,
            panelClass: ['snackbar-warn'],
          });
        });
    }
  }

  loginGoogle() {
    this.googleRegisterLoading = true;
    this.authService
      .googleSignin()
      .then(() => {
        this.router.navigateByUrl('');
      })
      .catch((error) => {
        this.googleRegisterLoading = false;
        if (error.code !== 'auth/popup-closed-by-user') {
          console.error(error);
          this._snackBar.open(error.message, 'OK', {
            duration: 5000,
            panelClass: ['snackbar-warn'],
          });
        }
      });
  }

  resetRegisterForm(): void {
    this.registerForm.reset();
    this.registerForm.markAsUntouched();
    this.registerForm.controls.email.setErrors(null);
    this.registerForm.controls.password.setErrors(null);
  }
}
