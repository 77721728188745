import { IFinishedDialogInput } from './../../_interfaces/ifinished-dialog-input';
import { FinishedDialogComponent } from './finished-dialog/finished-dialog.component';
import { VatService } from './../../_services/vat.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { State } from 'src/app/_enums/state.enum';

@Component({
  selector: 'app-finished-banner',
  templateUrl: './finished-banner.component.html',
  styleUrls: ['./finished-banner.component.scss'],
})
export class FinishedBannerComponent implements OnInit {
  @Output() reportFinishedEvent = new EventEmitter<IFinishedDialogInput>();

  private subscriptions: Subscription[] = [];
  reportData: IFinishedDialogInput;

  constructor(public vatService: VatService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.updateCounters();
    this.reportFinishedEvent.emit(this.reportData);

    this.subscriptions.push(
      this.vatService.checkFinished$.subscribe((finished: boolean) => {
        if (finished) {
          this.updateCounters();
          this.reportFinishedEvent.emit(this.reportData);
          this.openFinishedDialog();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private updateCounters(): void {
    this.reportData = {
      stateCounterChecked: 0,
      stateCounterUnchecked: 0,
      stateCounterUnavailable: 0,
      stateCounterError: 0,
      correctUidCounter: 0,
      correctNameCounter: 0,
      correctAddressCounter: 0,
      unavailableCountries: [],
    };

    this.vatService.importedData.forEach((element) => {
      if (element.correctAddress) {
        this.reportData.correctAddressCounter += 1;
      }
      if (element.correctName) {
        this.reportData.correctNameCounter += 1;
      }
      if (element.apiResponse?.valid) {
        this.reportData.correctUidCounter += 1;
      }

      switch (element.state) {
        case State.CHECKED:
          this.reportData.stateCounterChecked += 1;
          break;
        case State.UNCHECKED:
          this.reportData.stateCounterUnchecked += 1;
          break;
        case State.UNAVAILABLE:
          const countryCode = element.UID.substring(0, 2);
          if (!this.reportData.unavailableCountries.includes(countryCode)) {
            this.reportData.unavailableCountries.push(countryCode);
          }

          this.reportData.stateCounterUnavailable += 1;
          break;
        case State.ERROR:
          this.reportData.stateCounterError += 1;
          break;
      }
    });
  }

  openFinishedDialog(): void {
    this.dialog.open(FinishedDialogComponent, {
      width: '900px',
      maxHeight: '95vh',
      data: this.reportData,
      autoFocus: false,
    });
  }
}
