import { CaptureConsole } from '@sentry/integrations';
import { AlternativeDataDialogComponent } from './vat-check/alternative-data-dialog/alternative-data-dialog.component';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { VatCheckComponent } from './vat-check/vat-check.component';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LoginComponent } from './login/login.component';
import { AuthService } from './_services/auth.service';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RowDialogComponent } from './vat-check/row-dialog/row-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettingsComponent } from './settings/settings.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { TableFilterComponent } from './vat-check/table-filter/table-filter.component';
import { MatStepperModule } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { RegisterComponent } from './register/register.component';
import { FileExportDialogComponent } from './vat-check/file-export-dialog/file-export-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FinishedDialogComponent } from './vat-check/finished-banner/finished-dialog/finished-dialog.component';
import { AngularFireFunctionsModule, REGION, USE_EMULATOR } from '@angular/fire/compat/functions';
import { CallbackPipe } from './_pipes/callback.pipe';
import { SessionsDialogComponent } from './vat-check/sessions-dialog/sessions-dialog.component';
import { ImportComponent } from './import/import.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { TemplateDialogComponent } from './import/template-dialog/template-dialog.component';
import { SelectionDialogComponent } from './import/selection-dialog/selection-dialog.component';
import { ReviewComponent } from './import/review/review.component';
import { MatSelectModule } from '@angular/material/select';
import { UidImportComponent } from './import/uid-import/uid-import.component';
import { AlternativeDataImportComponent } from './import/alternative-data-import/alternative-data-import.component';
import { ValidationComponent } from './import/validation/validation.component';
import { FileImportComponent } from './import/file-import/file-import.component';
import { PendingChangesGuard } from './_guards/pending-changes.guard';
import { PromptDialogComponent } from './_components/prompt-dialog/prompt-dialog.component';
import { CreateProfileComponent } from './create-profile/create-profile.component';
import { VerifiedBannerComponent } from './_components/verified-banner/verified-banner.component';
import { MatMenuModule } from '@angular/material/menu';
import { FinishedBannerComponent } from './vat-check/finished-banner/finished-banner.component';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { SubscriptionComponent } from './subscription/subscription.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { EncryptionDialogComponent } from './_components/encryption-dialog/encryption-dialog.component';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { NewKeyDialogComponent } from './settings/new-key-dialog/new-key-dialog.component';
import { PasswordLoginDialogComponent } from './_components/password-login-dialog/password-login-dialog.component';
import { BlockedLocationComponent } from './blocked-location/blocked-location.component';
import { AdblockEnabledBannerComponent } from './_components/adblock-enabled-banner/adblock-enabled-banner.component';
import { SubscriptionDialogComponent } from './subscription/subscription-dialog/subscription-dialog.component';
import { ConnectionDialogComponent } from './connection-dialog/connection-dialog.component';
import { SingleCheckResponseComponent } from './vat-check/single-check-response/single-check-response.component';
import { UpcomingInvoiceComponent } from './subscription/upcoming-invoice/upcoming-invoice.component';
import { PersonalSettingsComponent } from './_components/personal-settings/personal-settings.component';
import { BrowserTracing } from '@sentry/tracing';
import { DataboxDialogComponent } from './databox-dialog/databox-dialog.component';
import { OrderByPipe } from './_pipes/order-by.pipe';
import { MatBadgeModule } from '@angular/material/badge';
import { CountChecksPipe } from './_pipes/count-checks.pipe';

Sentry.init({
  dsn: 'https://5341fad07ca34b24a0234670c773d475@o540146.ingest.sentry.io/5658672',
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation

    new BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
  enabled: environment.production,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

@NgModule({
  declarations: [
    AppComponent,
    VatCheckComponent,
    LoginComponent,
    RowDialogComponent,
    SettingsComponent,
    TableFilterComponent,
    RegisterComponent,
    FileExportDialogComponent,
    FinishedDialogComponent,
    CallbackPipe,
    SessionsDialogComponent,
    ImportComponent,
    NavBarComponent,
    TemplateDialogComponent,
    SelectionDialogComponent,
    ReviewComponent,
    UidImportComponent,
    AlternativeDataImportComponent,
    ValidationComponent,
    FileImportComponent,
    PromptDialogComponent,
    CreateProfileComponent,
    VerifiedBannerComponent,
    AlternativeDataDialogComponent,
    FinishedBannerComponent,
    SubscriptionComponent,
    PasswordResetComponent,
    NotFoundComponent,
    EncryptionDialogComponent,
    NewKeyDialogComponent,
    PasswordLoginDialogComponent,
    BlockedLocationComponent,
    AdblockEnabledBannerComponent,
    SubscriptionDialogComponent,
    ConnectionDialogComponent,
    SingleCheckResponseComponent,
    UpcomingInvoiceComponent,
    PersonalSettingsComponent,
    DataboxDialogComponent,
    OrderByPipe,
    CountChecksPipe,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    HttpClientModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatToolbarModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSnackBarModule,
    FormsModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatInputModule,
    MatProgressBarModule,
    MatChipsModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatCheckboxModule,
    AngularFireFunctionsModule,
    MatSelectModule,
    MatMenuModule,
    MatSliderModule,
    MatTooltipModule,
    AngularFireAnalyticsModule,
    MatBadgeModule,
  ],
  providers: [
    PendingChangesGuard,
    AuthService,
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    { provide: REGION, useValue: 'europe-west3' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    // { provide: USE_EMULATOR, useValue: ['localhost', 5001] }, //uses local firebase emulator instead of production cloud functions
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
