import { VatService } from './../../_services/vat.service';
import { FirestoreService } from './../../_services/firestore.service';
import { AuthService } from './../../_services/auth.service';
import { EncryptionService } from './../../_services/encryption.service';
import { Component, Inject, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-encryption-dialog',
  templateUrl: './encryption-dialog.component.html',
  styleUrls: ['./encryption-dialog.component.scss'],
})
export class EncryptionDialogComponent implements OnInit {
  encryptionForm = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
  });

  spinnerEnabled = false;

  constructor(
    private encryptionService: EncryptionService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<any>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public headerText: string,
    private fb: FormBuilder,
    private vatService: VatService
  ) {}

  ngOnInit(): void {}

  disableEncryption(): void {
    this.authService.user$.pipe(take(1)).subscribe((user) => {
      if (user) {
        user.preferences.encryptionEnabled = false;
        this.authService.updateUser(user).then(() =>
          this._snackBar.open('Client side encryption was disabled', 'OK', {
            duration: 2000,
          })
        );
        this.dialogRef.close();
      }
    });
  }

  confirmPassword(): void {
    this.spinnerEnabled = true;
    this.authService.user$.pipe(take(1)).subscribe((user) => {
      if (user.preferences.passwordHash) {
        // password already set, verify
        this.encryptionService
          .verifyKey(this.encryptionForm.controls.password.value)
          .then((verified) => {
            if (verified) {
              this.vatService.resetImportedData();
              this._snackBar.open('Data decrypted', 'OK', {
                duration: 2000,
              });
              this.dialogRef.close();
            } else {
              this.spinnerEnabled = false;
              this.encryptionForm.reset();
              this._snackBar.open('Invalid password', 'OK', {
                duration: 5000,
                panelClass: ['snackbar-warn'],
              });
            }
          })
          .catch((err) => {
            this.spinnerEnabled = false;
            this.encryptionForm.reset();
          });
      } else {
        // set new password
        this.encryptionService
          .setNewKey(this.encryptionForm.controls.password.value)
          .then(() => {
            this.vatService.resetImportedData();
            this._snackBar.open('New encryption password set', 'OK', {
              duration: 2000,
            });
            this.dialogRef.close();
          })
          .catch((err) => {});
      }
    });
  }
}
