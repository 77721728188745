import { EncryptionService } from './../_services/encryption.service';
import { StripeRole } from './../_enums/stripe-role.enum';
import { VatService } from './../_services/vat.service';
import { ApiService } from './../_services/api.service';
import { UsageService } from './../_services/usage.service';
import { AuthService } from './../_services/auth.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable, pipe } from 'rxjs';
import { IUser } from '../_interfaces/user';
import { FREE_CHECKS } from './../../global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PromptDialogComponent } from '../_components/prompt-dialog/prompt-dialog.component';
import { PromptInput } from '../_interfaces/prompt-input';
import { MatDialog } from '@angular/material/dialog';
import { DataboxDialogComponent } from '../databox-dialog/databox-dialog.component';
import { FirestoreService } from '../_services/firestore.service';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  freeChecks: number = FREE_CHECKS;
  unreadDataboxEntries$: Observable<number> | undefined;
  invoice$: Observable<any>;
  usageCounter = 0;
  stripeRole: typeof StripeRole = StripeRole;
  @Output() restoreCloudSessionClicked = new EventEmitter<boolean>();

  constructor(
    public authService: AuthService,
    public usageService: UsageService,
    private apiService: ApiService,
    public vatService: VatService,
    public encryptionService: EncryptionService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private firestoreService: FirestoreService
  ) {}

  ngOnInit(): void {
    this.usageService.usageDoc$.subscribe(
      (res) => {
        this.usageCounter = res.usageCounter;
      },
      (error) => {
        console.error(error);
        this._snackBar.open('Failed to retrieve usage counter', 'OK', {
          duration: 5000,
          panelClass: ['snackbar-warn'],
        });
      }
    );

    this.authService.user$.subscribe((user) => {
      if (!user) {
        return;
      }

      this.unreadDataboxEntries$ = this.firestoreService.queryDataboxEntries(user.uid).pipe(
        map((entries) => {
          const count = entries.filter((entry) => !entry.read).length;
          return count > 0 ? count : undefined;
        })
      );
    });
  }

  checkBalance(user: IUser) {
    if (user.stripeId) {
      this.invoice$ = this.apiService.getInvoiceInfo(user.stripeId);
    }
  }

  async signOut(): Promise<void> {
    try {
      if (this.vatService.checkStarted && !this.vatService.checkFinished) {
        // vat check in progress
        const data: PromptInput = {
          title: 'Confirm deletion',
          text: 'Are you sure you want to sign out? A validation-process is currently in progress, data may be lost.',
        };
        const dialogRef = this.dialog.open(PromptDialogComponent, {
          maxWidth: '400px',
          data,
        });

        dialogRef.afterClosed().subscribe(async (confirmed: boolean) => {
          if (confirmed) {
            await this.authService.signOut();
            this.vatService.resetImportedData();
            window.location.reload();
          }
        });
      } else {
        await this.authService.signOut();
        this.vatService.resetImportedData();
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      this._snackBar.open('Failed to sign out', 'OK', {
        duration: 5000,
        panelClass: ['snackbar-warn'],
      });
    }
  }

  restoreBackupSession(): void {
    this.restoreCloudSessionClicked.emit(true);
  }

  openDataboxDialog() {
    this.dialog.open(DataboxDialogComponent, {
      width: '80%',
      maxHeight: '95vh',
      autoFocus: false,
    });
  }
}
