import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-password-login-dialog',
  templateUrl: './password-login-dialog.component.html',
  styleUrls: ['./password-login-dialog.component.scss'],
})
export class PasswordLoginDialogComponent implements OnInit {
  loginForm = this.fb.group({
    email: [
      '',
      [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.maxLength(50)],
    ],
    password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
  });
  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<any>
  ) {}

  ngOnInit(): void {}

  loginEmail() {
    this.authService
      .emailSignin(this.loginForm.value.email, this.loginForm.value.password)
      .then(() => {
        this.dialogRef.close(true);
      })
      .catch((error) => {
        this._snackBar.open(error.message, 'OK', {
          duration: 5000,
          panelClass: ['snackbar-warn'],
        });
        if (error.code !== 'auth/wrong-password') { console.error(error); }
        this.resetLoginForm();
      });
  }
  resetLoginForm(): void {
    this.loginForm.reset();
    this.loginForm.markAsUntouched();
    this.loginForm.controls.email.setErrors(null);
    this.loginForm.controls.password.setErrors(null);
  }
}
