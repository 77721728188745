<div class="file-import__row">
  <div>
    <input
      #fileInput
      hidden="true"
      type="file"
      onclick="this.value=null"
      (change)="onFileChange($event)"
      accept=".xlsx, .xls, .csv"
    />
    <a
      class="file-import__template-btn"
      mat-raised-button
      target="_blank"
      href="https://firebasestorage.googleapis.com/v0/b/vat-check-f32e6.appspot.com/o/default%2Ftemplate.xlsx?alt=media&token=407eca87-aaeb-4c53-bd93-c542f902582f"
      ><mat-icon aria-hidden="false">download</mat-icon> Download sample template</a
    >
    <button mat-raised-button color="primary" (click)="fileInput.click()">
      <mat-icon aria-hidden="false">attachment</mat-icon>
      Choose spreadsheet file
    </button>
    {{ fileName }}
  </div>
  <mat-form-field appearance="fill">
    <mat-label>Data Sheet</mat-label>
    <mat-select
      [disabled]="!selectionEnabled"
      [(ngModel)]="selectedSheetName"
      (selectionChange)="changeSheetSelection($event)"
    >
      <ng-container *ngIf="workBook">
        <mat-option *ngFor="let sheet of workBook.SheetNames" [value]="sheet">
          {{ sheet }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>
