import { CompanyTableRow } from './company-table-row';
import { ICompanyTableRow } from './../_interfaces/company-table-row';
import { IAlternativeDataDocument } from 'src/app/_interfaces/alternative-data-document';
import { IAlternativeData } from './../_interfaces/alternative-data';
export class AlternativeDataDocument implements IAlternativeDataDocument {
  docId: string;
  userUid: string;
  companyUid: string;
  alternativeData: IAlternativeData[];

  public static parseAlternativeData(
    columnIndices: number[],
    headerRow: string[],
    dataRows: any[],
    userUid: string
  ): IAlternativeDataDocument[] {
    const alternativeDataDocuments: IAlternativeDataDocument[] = [];
    const storedCompanyUids: string[] = [];
    const companyTableRows: ICompanyTableRow[] = CompanyTableRow.parseUidData(columnIndices, headerRow, dataRows);

    companyTableRows.forEach((element) => {
      if (!storedCompanyUids.includes(element.UID)) {
        // create new doc
        const doc: IAlternativeDataDocument = {
          docId: null,
          userUid,
          companyUid: element.UID,
          alternativeData: [{ alternativeName: element.Name, alternativeAddress: element.address, apiResponse: null }],
        };
        storedCompanyUids.push(element.UID);
        alternativeDataDocuments.push(doc);
      } else {
        // add to existing doc
        alternativeDataDocuments.forEach((doc) => {
          if (element.UID === doc.companyUid) {
            doc.alternativeData.push({
              alternativeName: element.Name,
              alternativeAddress: element.address,
              apiResponse: null,
            });
          }
        });
      }
    });

    return alternativeDataDocuments;
  }
}
