import { AuthService } from './_services/auth.service';
import { UsageService } from './_services/usage.service';
import { Component, HostListener } from '@angular/core';
import { VatService } from './_services/vat.service';
import { mergeMap, map } from 'rxjs/operators';
import { fromEvent, merge, Observable, Observer, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionDialogComponent } from './connection-dialog/connection-dialog.component';
import { VatCheckComponent } from './vat-check/vat-check.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private vatService: VatService,
    private usageService: UsageService,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  title = 'vat-fe';
  private usingSession = false;
  private sessionCloudStorageActive = false;
  private subscriptions: Subscription[] = [];
  private vatCheckComponent: VatCheckComponent | undefined;

  @HostListener('window:beforeunload')
  preventClosing(): boolean {
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    // the prompt will not appear on internal route changes (not necessary as the data is stored in global service)

    if (this.authService.suspendPageCloseWarning) {
      return true;
    } else if (
      !this.vatService.checkStarted &&
      !this.vatService.checkFinished &&
      this.vatService.importedData.length > 0
    ) {
      // check not started yet
      if (this.usingSession && this.sessionCloudStorageActive) {
        return true;
      } else {
        return false;
      }
    } else if (this.vatService.checkStarted && !this.vatService.checkFinished) {
      // check running/paused
      return false;
    } else if (this.vatService.checkFinished) {
      // check finished
      if (this.usingSession && this.sessionCloudStorageActive) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  ngOnInit(): void {
    let dialogRef: any;
    this.applicationIsOnline().subscribe((isOnline) => {
      if (!isOnline) {
        dialogRef = this.dialog.open(ConnectionDialogComponent, {
          disableClose: true,
        });
      } else if (dialogRef) {
        dialogRef.close();
      }
    });

    this.usageService.initialUsageLoad();

    this.subscriptions.push(
      this.authService.user$.subscribe((user) => {
        if (user) {
          this.sessionCloudStorageActive = user?.preferences?.sessionsCloudStorage ? true : false;
        } else {
          this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        }
      }),

      this.vatService.newSessionInitialized
        .pipe(
          mergeMap((initialized) => {
            if (initialized) {
              return this.vatService.currentSession$;
            }
          })
        )
        .subscribe(
          (session) => {
            this.usingSession = session ? true : false;
          },
          (error) => {
            console.error(error);
            this.usingSession = false;
            this._snackBar.open('Failed to load current session from cloud storage', 'OK', {
              duration: 5000,
              panelClass: ['snackbar-warn'],
            });
          }
        )
    );
  }

  applicationIsOnline(): Observable<boolean> {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  restoreCloudSessionClicked() {
    this.vatCheckComponent?.openSessionsDialog();
  }

  onOutletLoaded(component: VatCheckComponent | any) {
    if (component instanceof VatCheckComponent) this.vatCheckComponent = component;
  }
}
