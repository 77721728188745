import { Uid } from './../../_classes/uid';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PropertyName } from 'src/app/_enums/property-name.enum';
import { IRawImportData } from '../../_interfaces/raw-import-data';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-selection-dialog',
  templateUrl: './selection-dialog.component.html',
  styleUrls: ['./selection-dialog.component.scss'],
})
export class SelectionDialogComponent implements OnInit {
  @ViewChild(MatStepper) stepper: MatStepper;
  columnIndices: number[] = [null, null, null, null, null, null, null, null];
  propertyName: typeof PropertyName = PropertyName;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: IRawImportData,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<any>
  ) {}

  ngOnInit(): void {}

  selectCell(propertyName: PropertyName, index: number): void {
    let validUids = true;
    if (propertyName === PropertyName.UID) {
      // check if inputdata contains invalid UIDs
      const invalidUids = this.dialogInputData.importedData.filter((row) => !Uid.validUidFormat(row[index]));
      if (invalidUids.length > 0) {
        validUids = false;
      }
    }

    // check if index is already set somewhere EXCEPT on propertyName
    const foundIndex = this.columnIndices.findIndex((element) => element === index);
    if (foundIndex !== -1) {
      // already in use
      if (this.columnIndices[propertyName] === index) {
        // in use only by itself
        if (validUids) {
          this.stepper.selected.completed = true;
          this.stepper.next();
        } else {
          this._snackBar.open('Sheet contains invalid VAT numbers', 'OK', {
            duration: 5000,
            panelClass: ['snackbar-warn'],
          });
        }
      } else {
        this._snackBar.open('Column already selected', 'OK', {
          duration: 5000,
          panelClass: ['snackbar-warn'],
        });
      }
    } else {
      if (validUids) {
        this.columnIndices[propertyName] = index;
        this.stepper.selected.completed = true;
        this.stepper.next();
      } else {
        this._snackBar.open('Sheet contains invalid VAT numbers', 'OK', {
          duration: 5000,
          panelClass: ['snackbar-warn'],
        });
      }
    }
  }

  nextButtonDisabled(propertyName: PropertyName): boolean {
    if (this.columnIndices[propertyName] !== null) {
      return false;
    } else {
      return true;
    }
  }

  resetStepper(): void {
    this.stepper.reset();
    this.columnIndices = [null, null, null, null, null, null, null, null];
  }
}
