import { ErrorState } from '../_enums/error.enum';
import { FirebaseError } from '../_interfaces/firebase-error';

export class ApiErrorHandler {
  public static getErrorMessage(errorCode: ErrorState): string {
    let errorMessage;
    switch (errorCode) {
      case ErrorState.UnauthenticatedError:
        errorMessage = 'Error: Authentication failed';
        break;
      case ErrorState.ProgrammingError:
        errorMessage = 'Error: Internal server error';
        break;
      case ErrorState.ResourceRequestError:
        errorMessage = 'Error: Requesting external resource failed';
        break;
      case ErrorState.UnexpectedFormatError:
        errorMessage = 'Error: Unable to process server response data';
        break;
      case ErrorState.FrameworkError:
        errorMessage = 'Error: Server framework communication failed';
        break;
      case ErrorState.UnavailableError:
        errorMessage = 'Error: The country server is currently unavailable';
        break;
      case ErrorState.VatNumberUnknownError:
        errorMessage = 'Error: The provided VAT number was not found';
        break;
      case ErrorState.InternalStateConsistencyError:
        errorMessage = 'Error: Internal state consistency error';
        break;
      case ErrorState.FirestoreError:
        errorMessage = 'Error: Firestore operation failed';
        break;
      case ErrorState.FinOnError:
        errorMessage = 'Error: External Finon API error';
        break;
      default:
        errorMessage = 'Error: Server not reachable';
        break;
    }
    return errorMessage;
  }

  public static getErrorLogMessage(error: FirebaseError) {
    return 'Error code: ' + error.details?.code + ' - msg: ' + error.message;
  }
}
