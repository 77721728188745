<h3 mat-dialog-title>Set a new encryption-key</h3>
<div mat-dialog-content>
  <p class="new-key-dialog__warning">
    Please be aware that all encrypted data currently stored in the cloud as well as the current local session will be
    lost!
  </p>
  <form [formGroup]="encryptionForm">
    <mat-form-field class="new-key-dialog__input">
      <mat-label>New password</mat-label>
      <input
        [readonly]="spinnerEnabled"
        autocomplete="new-password"
        cdkFocusInitial
        formControlName="password"
        matInput
        type="password"
      />
    </mat-form-field>
    <div class="new-key-dialog__actions">
      <button
        type="submit"
        mat-raised-button
        color="primary"
        [disabled]="!encryptionForm.valid || spinnerEnabled"
        (click)="setNewKey()"
      >
        <div class="new-key-dialog__confirm-btn-container">
          <div>Confirm</div>
          <div *ngIf="spinnerEnabled" class="new-key-dialog__spinner">
            <mat-progress-spinner diameter="17" mode="indeterminate"></mat-progress-spinner>
          </div>
        </div>
      </button>
      <button mat-button [mat-dialog-close]="false" [disabled]="spinnerEnabled">Cancel</button>
    </div>
  </form>
</div>
