import { Router } from '@angular/router';
import { AuthService } from './../_services/auth.service';
import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm = this.fb.group({
    email: [
      '',
      [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.maxLength(50)],
    ],
    password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
  });

  googleLoginLoading = false;
  emailLoginLoading = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  loginEmail() {
    this.emailLoginLoading = true;
    this.authService
      .emailSignin(this.loginForm.value.email, this.loginForm.value.password)
      .then(() => {
        this.router.navigateByUrl('');
      })
      .catch((error) => {
        this._snackBar.open(error.message, 'OK', {
          duration: 5000,
          panelClass: ['snackbar-warn'],
        });
        if (error.code !== 'auth/wrong-password') {
          console.error(error);
        }
        this.resetLoginForm();
        this.emailLoginLoading = false;
      });
  }

  loginGoogle() {
    this.googleLoginLoading = true;
    this.authService
      .googleSignin()
      .then(() => {
        this.router.navigateByUrl('');
      })
      .catch((error) => {
        this.googleLoginLoading = false;
        if (error.code !== 'auth/popup-closed-by-user') {
          console.error(error);
          this._snackBar.open(error.message, 'OK', {
            duration: 5000,
            panelClass: ['snackbar-warn'],
          });
        }
      });
  }

  resetLoginForm(): void {
    this.loginForm.reset();
    this.loginForm.markAsUntouched();
    this.loginForm.controls.email.setErrors(null);
    this.loginForm.controls.password.setErrors(null);
  }
}
