import { AuthService } from './../_services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  resetForm = this.fb.group({
    email: [
      '',
      [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.maxLength(50)],
    ],
  });
  resetButtonEnabled = true;

  constructor(private fb: FormBuilder, private authService: AuthService, private _snackBar: MatSnackBar) {}

  ngOnInit(): void {}

  sendPasswordReset(): void {
    this.resetButtonEnabled = false;
    const email: string = this.resetForm.controls.email.value;
    this.authService
      .sendPasswordResetEmail(email)
      .then(() => {
        this._snackBar.open('Password reset email has been sent to: ' + email, 'OK', {
          duration: 2000,
        });
      })
      .catch((error) => {
        console.error(error);
        this._snackBar.open('Failed to send password reset email', 'OK', {
          duration: 5000,
          panelClass: ['snackbar-warn'],
        });
      });
  }
}
