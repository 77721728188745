import { FREE_CHECKS, MAX_CLOUD_STORAGE_FILE_LENGTH } from './../../../global';
import { StripeRole } from './../../_enums/stripe-role.enum';
import { AuthService } from 'src/app/_services/auth.service';
import { IRawImportData } from './../../_interfaces/raw-import-data';
import { IFileImportOutput } from './../../_interfaces/file-import-output';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as XLSX from 'xlsx';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-file-import',
  templateUrl: './file-import.component.html',
  styleUrls: ['./file-import.component.scss'],
})
export class FileImportComponent implements OnInit {
  @Output() fileImportFinishedEvent = new EventEmitter<IFileImportOutput>();

  private fileTarget: DataTransfer;
  workBook: XLSX.WorkBook;
  selectedSheetName: any;
  fileName: string;
  selectionEnabled = false;

  constructor(private _snackBar: MatSnackBar, private authService: AuthService) {}

  ngOnInit(): void {}

  changeSheetSelection(evt: any): void {
    this.selectedSheetName = evt.value;
    this.parseData(evt.value, this.fileName, this.workBook);
  }

  onFileChange(evt: any): void {
    this.fileTarget = evt.target as DataTransfer;
    if (this.fileTarget.files.length !== 1) {
      this._snackBar.open('Cannot use multiple files.', 'OK', {
        duration: 5000,
        panelClass: ['snackbar-warn'],
      });
      return;
    }

    this.fileName = this.fileTarget.files[0].name;
    const fileExtension: string = this.fileName.split('.').pop().toLowerCase();

    if (fileExtension === 'xlsx' || fileExtension === 'xls' || fileExtension === 'csv') {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        this.workBook = XLSX.read(bstr, { type: 'binary' });

        // if only 1 sheet available, select it
        if (this.workBook.SheetNames.length >= 1) {
          this.selectedSheetName = this.workBook.SheetNames[0];
          this.parseData(this.selectedSheetName, this.fileName, this.workBook);
        } else {
          this.selectedSheetName = null;
          this.fileImportFinishedEvent.emit(null);
        }

        this.selectionEnabled = true;
      };
      reader.readAsBinaryString(this.fileTarget.files[0]);
    } else {
      this.resetVariables();
      this.fileImportFinishedEvent.emit(null);
      this._snackBar.open('Only .xlsx and .xls files are supported!', 'OK', {
        duration: 5000,
        panelClass: ['snackbar-warn'],
      });
    }
  }

  private parseData(sheetName: string, fileName: string, workBook: XLSX.WorkBook) {
    const ws: XLSX.WorkSheet = workBook.Sheets[sheetName];
    const importedData: any = XLSX.utils.sheet_to_json(ws, { header: 1, defval: '', blankrows: false });
    const importedHeaders: string[] = importedData[0];
    importedData.shift();

    this.authService.user$.pipe(take(1)).subscribe(async (user) => {
      const role: StripeRole = await this.authService.role;
      if (role === StripeRole.FREE && importedData?.length > FREE_CHECKS) {
        this.fileImportFinishedEvent.emit(null);
        this._snackBar.open(
          'Imported data is exceeding available contingent of ' +
            FREE_CHECKS +
            ' checks. Please upgrade your plan to continue!',
          'OK',
          {
            duration: 7000,
            panelClass: ['snackbar-warn'],
          }
        );
      } else if (importedData?.length > 0) {
        if (
          (user.preferences.sessionsCloudStorage && importedData?.length < MAX_CLOUD_STORAGE_FILE_LENGTH) ||
          !user.preferences.sessionsCloudStorage
        ) {
          const rawData: IRawImportData = {
            importedData,
            importedHeaders,
          };
          const output: IFileImportOutput = {
            data: rawData,
            fileName,
          };
          this.fileImportFinishedEvent.emit(output);
        } else {
          this.fileImportFinishedEvent.emit(null);
          this._snackBar.open(
            'Session cloud storage is only available for files with less than ' +
              MAX_CLOUD_STORAGE_FILE_LENGTH +
              ' rows. Please disable session cloud storage to continue!',
            'OK',
            {
              duration: 7000,
              panelClass: ['snackbar-warn'],
            }
          );
        }
      } else {
        this.fileImportFinishedEvent.emit(null);
        this._snackBar.open('Invalid sheet data', 'OK', {
          duration: 5000,
          panelClass: ['snackbar-warn'],
        });
      }
    });
  }

  private resetVariables(): void {
    this.workBook = null;
    this.selectedSheetName = null;
    this.fileName = null;
    this.selectionEnabled = false;
  }
}
