// keep Enum names in sync with ICompanyTableRow and IAddress, order and value of enums MUST NOT be changed
export enum PropertyName {
  UID,
  Name,
  Addr1,
  Addr2,
  Addr3,
  Addr4,
  Addr5,
  Addr6,
}
