import { MatTableDataSource } from '@angular/material/table';
import { VatService } from './../../_services/vat.service';
import { ICompanyTableRow } from './../../_interfaces/company-table-row';
import { AuthService } from './../../_services/auth.service';
import { Observable, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FirestoreService } from 'src/app/_services/firestore.service';
import { ISession } from 'src/app/_interfaces/session';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';

@Component({
  selector: 'app-sessions-dialog',
  templateUrl: './sessions-dialog.component.html',
  styleUrls: ['./sessions-dialog.component.scss'],
})
export class SessionsDialogComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<ISession>();
  displayedColumns: string[] = ['name', 'timeStamp', 'action'];

  private subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<any>,
    private firestoreService: FirestoreService,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    private vatService: VatService
  ) {}

  ngOnInit(): void {
    this.authService.user$.pipe(take(1)).subscribe((user) => {
      if (user) {
        this.subscriptions.push(
          this.firestoreService.querySessions(user.uid, user.preferences.encryptionEnabled).subscribe(
            (sessions) => {
              this.dataSource = new MatTableDataSource<ISession>(sessions);
              this.dataSource.paginator = this.paginator;

              this.dataSource.sort = this.sort;
              const sortState: Sort = { active: 'timeStamp', direction: 'desc' };
              this.sort.active = sortState.active;
              this.sort.direction = sortState.direction;
              this.sort.sortChange.emit(sortState);
            },
            (error) => {
              console.error(error);
              this._snackBar.open('Failed to receive sessions from cloud storage', 'OK', {
                duration: 5000,
                panelClass: ['snackbar-warn'],
              });
            }
          )
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  deleteSession(session: ISession): void {
    this.authService.user$.pipe(take(1)).subscribe((user) => {
      if (user) {
        this.firestoreService
          .deleteSession(user.uid, session.docId, user.preferences.encryptionEnabled)
          .then(() =>
            this._snackBar.open('Session deleted', 'OK', {
              duration: 2000,
            })
          )
          .catch((error) => {
            console.error(error);
            this._snackBar.open('Failed to delete session from cloud storage', 'OK', {
              duration: 5000,
              panelClass: ['snackbar-warn'],
            });
          });
      }
    });
  }

  restoreSession(session: ISession): void {
    this.authService.user$.pipe(take(1)).subscribe((user) => {
      if (user) {
        this.firestoreService
          .querySessionRows(session.userUid, session.docId, user.preferences.encryptionEnabled)
          .pipe(take(1))
          .subscribe(
            (sessionRows) => {
              if (sessionRows?.length > 0) {
                const session$ = this.firestoreService.getSession(
                  session.userUid,
                  session.docId,
                  user.preferences.encryptionEnabled
                );
                this.vatService.setImportData(
                  sessionRows.map((item) => item.companyTableRow),
                  session.headerRow,
                  session.columnIndices,
                  session$
                );
                this.dialogRef.close(true);
              }
            },
            (error) => {
              console.error(error);
              this._snackBar.open('Failed to receive session rows from cloud storage', 'OK', {
                duration: 5000,
                panelClass: ['snackbar-warn'],
              });
            }
          );
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
