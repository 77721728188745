<h1 mat-dialog-title>Stored Alternative Data</h1>
<mat-dialog-content>
  <p>Removing stored entries will not have an effect on already checked VAT numbers of your current session!</p>
  <div class="mat-elevation-z4 alt-data-dialog__table-container">
    <table matSort mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="uid">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>VAT No.</th>
        <td mat-cell *matCellDef="let element">
          {{ element.apiResponse?.countryCode + element.apiResponse?.vatNumber }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.alternativeName }}</td>
      </ng-container>

      <ng-container matColumnDef="addr1">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Addr1</th>
        <td mat-cell *matCellDef="let element">{{ element.alternativeAddress.Addr1 }}</td>
      </ng-container>

      <ng-container matColumnDef="addr2">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Addr2</th>
        <td mat-cell *matCellDef="let element">{{ element.alternativeAddress.Addr2 }}</td>
      </ng-container>

      <ng-container matColumnDef="addr3">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Addr3</th>
        <td mat-cell *matCellDef="let element">{{ element.alternativeAddress.Addr3 }}</td>
      </ng-container>

      <ng-container matColumnDef="addr4">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Addr4</th>
        <td mat-cell *matCellDef="let element">{{ element.alternativeAddress.Addr4 }}</td>
      </ng-container>

      <ng-container matColumnDef="addr5">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Addr5</th>
        <td mat-cell *matCellDef="let element">{{ element.alternativeAddress.Addr5 }}</td>
      </ng-container>

      <ng-container matColumnDef="addr6">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Addr6</th>
        <td mat-cell *matCellDef="let element">{{ element.alternativeAddress.Addr6 }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element">
          <button mat-button (click)="deleteAlternativeDataRow(element)">
            <mat-icon aria-hidden="false">delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="actions">
  <button (click)="deleteAll()" mat-raised-button color="warn">Delete all entries</button>
  <button mat-button mat-dialog-close cdkFocusInitial>Close</button>
</mat-dialog-actions>
