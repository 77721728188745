import { map, mergeMap, take } from 'rxjs/operators';
import { FirestoreService } from 'src/app/_services/firestore.service';
import { AuthService } from './auth.service';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IMonthlyUsage } from '../_interfaces/imonthly-usage';

interface QuerySubscriptionReturn {
  usageDocs: IMonthlyUsage[];
  subscriptionIsActive: boolean;
  currentPeriodStart?: any;
  currentPeriodEnd?: any;
}

@Injectable({
  providedIn: 'root',
})
export class UsageService {
  private usageDocSrc: ReplaySubject<IMonthlyUsage> = new ReplaySubject<IMonthlyUsage>(1);
  usageDoc$: Observable<IMonthlyUsage> = this.usageDocSrc.asObservable();
  private subscriptions: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private firestoreService: FirestoreService,
    private _snackBar: MatSnackBar
  ) {}

  initialUsageLoad(): void {
    // gets called in app component (on app load)
    this.authService.authState$.subscribe((user) => {
      if (user) {
        const yearFormatter = new Intl.DateTimeFormat([], { timeZone: 'Europe/Vienna', year: 'numeric' });
        const monthFormatter = new Intl.DateTimeFormat([], { timeZone: 'Europe/Vienna', month: 'numeric' });
        const year: string = yearFormatter.format(new Date());
        const month: string = monthFormatter.format(new Date());

        const firstDayOfMonth = new Date(Number(year), Number(month) - 1, 1);
        const lastDayOfMonth = new Date(Number(year), Number(month), 0);

        this.firestoreService
          .getCurrentSubscriptions(user.uid)
          .pipe(
            take(1),
            mergeMap((subscriptions) => {
              const activeSubscriptions = subscriptions.filter((subscription) => subscription.ended_at === null);
              if (activeSubscriptions.length === 0) {
                // user has no active subscription
                return this.firestoreService.queryUsageDoc(firstDayOfMonth, lastDayOfMonth, user.uid).pipe(
                  map((res) => {
                    const response: QuerySubscriptionReturn = {
                      usageDocs: res,
                      subscriptionIsActive: false,
                    };
                    return response;
                  })
                );
              } else {
                // user currently is on a paid plan
                if (activeSubscriptions.length > 1) {
                  console.error('initialUsageLoad(): more than one active subscription for user uid: ' + user.uid);
                }
                const currentPeriodStart = activeSubscriptions[0].current_period_start.toDate();
                const currentPeriodEnd = activeSubscriptions[0].current_period_end.toDate();

                return this.firestoreService.queryUsageDoc(currentPeriodStart, currentPeriodEnd, user.uid).pipe(
                  map((res) => {
                    const response: QuerySubscriptionReturn = {
                      usageDocs: res,
                      subscriptionIsActive: true,
                      currentPeriodStart,
                      currentPeriodEnd,
                    };
                    return response;
                  })
                );
              }
            })
          )
          .subscribe(
            async (res) => {
              if (res.usageDocs?.length > 0) {
                // usage doc already exists for this period
                this.setUsageDoc(user.uid, res.usageDocs[0].docId);
                if (res.usageDocs?.length > 1) {
                  console.error('More than 1 usage docs found for userId ' + user.uid);
                  this.mergeDuplicateUsageDocs(res.usageDocs, user.uid);
                }
              } else {
                // create new usage doc
                let usageDoc: IMonthlyUsage;
                if (res.subscriptionIsActive) {
                  usageDoc = {
                    docId: null,
                    isPaidPlan: true,
                    currentPeriodStart: res.currentPeriodStart,
                    currentPeriodEnd: res.currentPeriodEnd,
                    usageCounter: 0,
                    userUid: user.uid,
                  };
                } else {
                  usageDoc = {
                    docId: null,
                    isPaidPlan: false,
                    currentPeriodStart: firstDayOfMonth,
                    currentPeriodEnd: lastDayOfMonth,
                    usageCounter: 0,
                    userUid: user.uid,
                  };
                }

                try {
                  usageDoc.docId = await this.firestoreService.createUsageDoc(usageDoc);
                  this.setUsageDoc(user.uid, usageDoc.docId);
                } catch (error) {
                  console.error(error);
                  this._snackBar.open('Failed to store usage document to cloud storage', 'OK', {
                    duration: 5000,
                    panelClass: ['snackbar-warn'],
                  });
                }
              }
            },
            (error) => {
              this.authService.user$.pipe(take(1)).subscribe((user) => {
                if (user) {
                  console.error(error);
                  this._snackBar.open('Failed to query usage counter from cloud storage', 'OK', {
                    duration: 5000,
                    panelClass: ['snackbar-warn'],
                  });
                }
              });
            }
          );
      } else {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
      }
    });
  }

  incrementUsage(addUsage: number) {
    this.usageDoc$.pipe(take(1)).subscribe((usageDoc) => {
      this.firestoreService.incrementUsageCounter(usageDoc, addUsage);
    });
  }

  private setUsageDoc(userUid: string, usageDocId: string): void {
    this.authService.authState$.pipe(take(1)).subscribe((user) => {
      if (!user) {
        return;
      }

      this.subscriptions.push(
        this.firestoreService.getUsageDoc(userUid, usageDocId).subscribe(
          (doc) => {
            this.usageDocSrc.next(doc);
          },
          (error) => {
            console.error(error);
            this._snackBar.open('Failed to get usage counter from cloud storage', 'OK', {
              duration: 5000,
              panelClass: ['snackbar-warn'],
            });
          }
        )
      );
    });
  }

  private async mergeDuplicateUsageDocs(usageDocs: IMonthlyUsage[], userUid): Promise<void> {
    let mergedUsageCounter = 0;
    for (let i = 0; i < usageDocs.length; i++) {
      mergedUsageCounter += usageDocs[i].usageCounter;

      // delete all usage docs except first one
      if (i > 0) {
        try {
          await this.firestoreService.deleteUsageDoc(userUid, usageDocs[i].docId);
        } catch (error) {
          console.error(error);
          this._snackBar.open('Failed to delete usage documents from cloud storage', 'OK', {
            duration: 5000,
            panelClass: ['snackbar-warn'],
          });
        }
      }
    }

    const mergedUsageDoc: IMonthlyUsage = {
      docId: usageDocs[0].docId,
      currentPeriodStart: usageDocs[0].currentPeriodStart,
      currentPeriodEnd: usageDocs[0].currentPeriodEnd,
      isPaidPlan: usageDocs[0].isPaidPlan,
      userUid: usageDocs[0].userUid,
      usageCounter: mergedUsageCounter,
    };

    try {
      await this.firestoreService.updateUsageDoc(mergedUsageDoc);
    } catch (error) {
      console.error(error);
    }
  }
}
