<h1 mat-dialog-title>Check Finished!</h1>
<mat-dialog-content class="mat-typography">
  <div>
    <h3>Status</h3>
    <div class="finished-dialog__report-row">
      <mat-icon
        *ngIf="inputData.stateCounterError === 0; else error"
        class="finished-dialog__success-icon"
        aria-hidden="false"
        >check_circle</mat-icon
      >
      {{ inputData.stateCounterError }}/{{ vatService.importedData.length }} rows have been checked with errors.
    </div>

    <div class="finished-dialog__report-row">
      <mat-icon
        *ngIf="inputData.stateCounterUnavailable === 0; else error"
        class="finished-dialog__success-icon"
        aria-hidden="false"
        >check_circle</mat-icon
      >
      {{ inputData.stateCounterUnavailable }}/{{ vatService.importedData.length }} rows have been checked with the
      country-server being unavailable.
    </div>

    <ng-container *ngIf="inputData.stateCounterUnavailable > 0"
      ><br />
      The country server is currently unavailable for the following countries:
      <ul>
        <li class="finished-dialog__country" *ngFor="let country of inputData.unavailableCountries">
          {{ country }}
        </li>
      </ul>
    </ng-container>

    <br />
    <h3>Validation</h3>
    <div class="finished-dialog__report-row">
      <mat-icon
        *ngIf="inputData.correctUidCounter === vatService.importedData.length; else error"
        class="finished-dialog__success-icon"
        aria-hidden="false"
        >check_circle</mat-icon
      >
      {{ vatService.importedData.length - inputData.correctUidCounter }} /{{ vatService.importedData.length }} rows have
      been checked with an invalid VAT number.
    </div>
    <div class="finished-dialog__report-row">
      <mat-icon
        *ngIf="inputData.correctAddressCounter === vatService.importedData.length; else error"
        class="finished-dialog__success-icon"
        aria-hidden="false"
        >check_circle</mat-icon
      >
      {{ vatService.importedData.length - inputData.correctAddressCounter }} /{{ vatService.importedData.length }} rows
      have been checked with an invalid address.
    </div>
    <div class="finished-dialog__report-row">
      <mat-icon
        *ngIf="inputData.correctNameCounter === vatService.importedData.length; else error"
        class="finished-dialog__success-icon"
        aria-hidden="false"
        >check_circle</mat-icon
      >
      {{ vatService.importedData.length - inputData.correctNameCounter }} /{{ vatService.importedData.length }} rows
      have been checked with an invalid name.
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="finished-dialog__action-btn" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>

<ng-template #error>
  <mat-icon class="finished-dialog__error-icon" aria-hidden="false">error</mat-icon>
</ng-template>
