import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-adblock-enabled-banner',
  templateUrl: './adblock-enabled-banner.component.html',
  styleUrls: ['./adblock-enabled-banner.component.scss'],
})
export class AdblockEnabledBannerComponent implements OnInit {
  constructor(public authService: AuthService) {}

  ngOnInit(): void {}

  refreshPage(): void {
    window.location.reload();
  }
}
