import { ErrorState } from './../_enums/error.enum';
import { IUserPreferences } from './../_interfaces/iuser-preferences';
import { CONCURRENT_REQUESTS, FREE_CHECKS } from './../../global';
import { StripeRole } from './../_enums/stripe-role.enum';
import { ISession } from 'src/app/_interfaces/session';
import { UsageService } from './usage.service';
import { ApiService } from './api.service';
import { IUser } from './../_interfaces/user';
import { CompanyTableRow } from './../_classes/company-table-row';
import { ICompanyTableRow } from './../_interfaces/company-table-row';
import { ISessionRow } from 'src/app/_interfaces/session-row';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';
import { IApiResponse } from './../_interfaces/api-response';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { State } from '../_enums/state.enum';
import { catchError, first, switchMap, take } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { IAlternativeDataDocument } from '../_interfaces/alternative-data-document';
import { PromptInput } from '../_interfaces/prompt-input';
import { PromptDialogComponent } from '../_components/prompt-dialog/prompt-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FirebaseError } from '../_interfaces/firebase-error';
import { ApiErrorHandler } from '../_classes/api-error-handler';
import { IMonthlyUsage } from '../_interfaces/imonthly-usage';

@Injectable({
  providedIn: 'root',
})
export class VatService {
  // todo: move varaibles below into session object? --> stateless and automatic table updates
  private _importedHeaders: string[] = [];
  private _importedHeadersColumnIndices: number[] = [];
  private _importedData: ICompanyTableRow[] = [];

  dataSource: any = new MatTableDataSource<ICompanyTableRow>(this._importedData);

  newSessionInitialized = new Subject<boolean>();
  private _currentSession$: Observable<ISession>;

  private _batchFinished = false; // indicates if a batch is currently running (used to prevent the user to resume before batch finished)
  private resumeAtIndex = 0;
  private lastIncludeUnchecked = false; // used to store last state of includeUnchecked when resuming from pause
  currentFilters: any[] = []; // indices are Filter enum values
  checkFinished$ = new Subject<boolean>(); // observable that emits on checkFinished change
  private _checkFinished = false;
  private _checkedRowsProgress = 0;
  private _checkStarted = false;
  private _checkPaused = false;

  private tmpPreferences: IUserPreferences; // each check uses a "local" copy of the current user preferences so settings cannot be changed via a different session
  private serverUnreachableCounter = 0;

  public get importedHeaders(): string[] {
    return this._importedHeaders;
  }
  public get importedHeadersColumnIndices(): number[] {
    return this._importedHeadersColumnIndices;
  }
  public get importedData(): ICompanyTableRow[] {
    return this._importedData;
  }
  public get checkFinished(): boolean {
    return this._checkFinished;
  }
  private setCheckFinished(value: boolean) {
    this._checkPaused = false;
    this._checkFinished = value;
    this.checkFinished$.next(value);
  }
  public get checkedRowsProgress(): number {
    return this._checkedRowsProgress;
  }
  public get checkStarted(): boolean {
    return this._checkStarted;
  }
  public get checkPaused(): boolean {
    return this._checkPaused;
  }
  public get currentSession$(): Observable<ISession> {
    return this._currentSession$;
  }
  public get batchFinished(): boolean {
    return this._batchFinished;
  }

  constructor(
    private _snackBar: MatSnackBar,
    private firestoreService: FirestoreService,
    private authService: AuthService,
    private apiService: ApiService,
    private usageService: UsageService,
    private dialog: MatDialog
  ) {}

  setImportData(
    companyTableRows: ICompanyTableRow[],
    importedHeaders: string[],
    columnIndices: number[],
    session$?: Observable<ISession>
  ) {
    this.resetImportedData();
    this._importedData = companyTableRows;
    this._importedHeaders = importedHeaders;
    this._importedHeadersColumnIndices = columnIndices;
    if (session$) {
      this._currentSession$ = session$;
      this.newSessionInitialized.next(true);
    }

    const checkedRows = companyTableRows.filter((item) => item.state === State.CHECKED);
    if (checkedRows.length === companyTableRows.length) {
      this.setCheckFinished(true);
    }
  }

  async startCheck(startingIndex: number, includeUnchecked: boolean): Promise<void> {
    try {
      const usage: IMonthlyUsage = await this.usageService.usageDoc$.pipe(first()).toPromise();
      const role: StripeRole = await this.authService.role;
      this.tmpPreferences = (await this.authService.user$.pipe(first()).toPromise()).preferences;

      if (role === StripeRole.FREE && usage.usageCounter + this.importedData.length > FREE_CHECKS) {
        this._snackBar.open(
          'Imported data is exceeding your available check-contingent. Please upgrade your plan to continue!',
          'OK',
          {
            duration: 5000,
            panelClass: ['snackbar-warn'],
          }
        );
        return;
      } else {
        this.checkData(startingIndex, includeUnchecked);
      }
    } catch (error) {
      console.error(error);
      this._snackBar.open('Failed to start check', 'OK', {
        duration: 5000,
        panelClass: ['snackbar-warn'],
      });
    }
  }

  private checkData(startingIndex: number, includeUnchecked: boolean): void {
    if (this._checkPaused) {
      return;
    } else if (this.importedData.length < 1) {
      return;
    }

    this.authService.user$.pipe(take(1)).subscribe((user) => {
      if (user) {
        if (startingIndex === 0) {
          this.resetControls();
          if (includeUnchecked) {
            // reset rows if check again all is executed
            this.importedData.forEach((row) => {
              row.state = State.UNCHECKED;
              row.correctAddress = null;
              row.correctName = null;
              row.apiResponse = null;
              row.importNameMatchingPercent = null;
              row.importAddressMatchingPercent = null;
              row.nameMatchOverrideActive = null;
              row.addressMatchOverrideActive = null;
              if (this.tmpPreferences.sessionsCloudStorage) {
                this.updateSessionRowToCloud(user, row);
              }
            });
          }
          this._checkStarted = true;
        }
        this._batchFinished = false;
        this.lastIncludeUnchecked = includeUnchecked;

        const maxIndex: number =
          startingIndex + CONCURRENT_REQUESTS > this.importedData.length
            ? this.importedData.length
            : startingIndex + CONCURRENT_REQUESTS;
        let synchronizedLoopCounter: number = startingIndex; // will only increment if relevant async processes have finished
        let billableChecks = 0;

        const proceedCheck = async () => {
          if (!this._batchFinished) {
            // update counters and determine if check is finished
            this.dataSource.filter = this.currentFilters;
            this._checkedRowsProgress += (1 / this.importedData.length) * 100;
            synchronizedLoopCounter += 1;
            this.resumeAtIndex = synchronizedLoopCounter;
            if (synchronizedLoopCounter === maxIndex && maxIndex !== this.importedData.length) {
              this._batchFinished = true;
              if (billableChecks > 0 && this.importedData.length > 0) {
                this.usageService.incrementUsage(billableChecks);
              }
              if (!this._checkPaused) {
                this.checkData(maxIndex, includeUnchecked);
              }
            } else if (this.importedData.length === synchronizedLoopCounter) {
              this._batchFinished = true;
              if (billableChecks > 0 && this.importedData.length > 0) {
                this.usageService.incrementUsage(billableChecks);
              }
              this.setCheckFinished(true);
            }
          }
        };

        for (let i = startingIndex; i < maxIndex; i++) {
          const companyTableRow: ICompanyTableRow = this.importedData[i];
          const countryCode = companyTableRow.UID.substr(0, 2);
          const uid = companyTableRow.UID.substr(2);

          if (companyTableRow.state !== State.CHECKED || includeUnchecked) {
            this.apiService
              .checkVatRequest(countryCode, uid, user.taxIdValidated ? user.taxId : null)
              .pipe(
                switchMap((apiResponse: IApiResponse) => {
                  // set api response
                  companyTableRow.apiResponse = apiResponse;
                  billableChecks++;

                  if (this.tmpPreferences.alternativeDataCloudStorage) {
                    return this.firestoreService.queryAlternativeData(user.uid, this.tmpPreferences.encryptionEnabled);
                  } else {
                    // alternativeDataCloudStorage disabled
                    this.updateCompanyTableRowAfterVatCheck(companyTableRow, user);
                    if (this.tmpPreferences.sessionsCloudStorage) {
                      this.updateSessionRowToCloud(user, companyTableRow);
                    }
                    proceedCheck();
                    return EMPTY;
                  }
                }),
                catchError((err: FirebaseError) => {
                  this.handleError(err, companyTableRow);
                  if (this.tmpPreferences.sessionsCloudStorage) {
                    this.updateSessionRowToCloud(user, companyTableRow);
                  }

                  proceedCheck();
                  return EMPTY;
                }),
                take(1) // prevent alternativedatadocument observable from getting triggered on rowdialog change (recursion issues, update manually instead)
              )
              .subscribe(
                (docs: IAlternativeDataDocument[]) => {
                  const filteredDocs = docs.filter((item) => item.companyUid === companyTableRow.UID);

                  // alternativeDataCloudStorage enabled
                  this.updateCompanyTableRowAfterVatCheck(companyTableRow, user, filteredDocs);
                  if (this.tmpPreferences.sessionsCloudStorage) {
                    this.updateSessionRowToCloud(user, companyTableRow);
                  }
                  proceedCheck();
                },
                (error) => {
                  // alternativeDataCloudStorage request failed
                  console.error(error);
                  this.updateCompanyTableRowAfterVatCheck(companyTableRow, user);
                  if (this.tmpPreferences.sessionsCloudStorage) {
                    this.updateSessionRowToCloud(user, companyTableRow);
                  }
                  proceedCheck();
                  this._snackBar.open('Failed to receive alternative data documents from cloud storage', 'OK', {
                    duration: 5000,
                    panelClass: ['snackbar-warn'],
                  });
                }
              );
          } else {
            // else: row has already been checked
            proceedCheck();
          }
        }
      }
    });
  }

  private handleError(error: FirebaseError, companyTableRow: ICompanyTableRow): void {
    switch (error.details?.code) {
      case ErrorState.UnavailableError:
        companyTableRow.state = State.UNAVAILABLE;
        break;
      default:
        companyTableRow.state = State.ERROR;
        break;
    }

    // only display error snackbar on error
    if (companyTableRow.state === State.ERROR) {
      const errorMessage = ApiErrorHandler.getErrorMessage(error.details.code);

      // pause check if too many server unreachable errors happened
      if (!error.message) {
        this.serverUnreachableCounter++;
        if (this.serverUnreachableCounter === CONCURRENT_REQUESTS) {
          const data: PromptInput = {
            title: 'Server unreachable',
            text: 'It seems that our backend server currently does not respond, therefore the check has been paused. If this problem persists, please contact our support.',
          };
          this.dialog.open(PromptDialogComponent, {
            maxWidth: '400px',
            data,
          });

          this.togglePause();
        }
      }

      console.error(ApiErrorHandler.getErrorLogMessage(error));
      this._snackBar.open(errorMessage, 'OK', {
        duration: 5000,
        panelClass: ['snackbar-warn'],
      });
    }
  }

  private updateCompanyTableRowAfterVatCheck(
    companyTableRow: ICompanyTableRow,
    user: IUser,
    docs?: IAlternativeDataDocument[]
  ): void {
    if (docs?.length > 0) {
      // alternativeData available
      companyTableRow.alternativeData = docs[0].alternativeData;
      if (docs.length > 1) {
        console.error(
          'More than 1 alternativeDataDocument found for user ' + user.uid + ' ; company ' + docs[0].companyUid
        );
      }
    } else if (!companyTableRow.alternativeData) {
      companyTableRow.alternativeData = [];
    }

    CompanyTableRow.updateCompanyTableRow(
      companyTableRow,
      this.tmpPreferences.alternativeDataCloudStorage,
      this.tmpPreferences.useMatchingPrediction,
      this.tmpPreferences.matchingThreshold
    );

    companyTableRow.state = State.CHECKED;
  }

  private async updateSessionRowToCloud(user: IUser, companyTableRow: ICompanyTableRow): Promise<void> {
    if (companyTableRow.sessionRowDocId && companyTableRow.sessionDocId) {
      const sessionRow: ISessionRow = {
        docId: companyTableRow.sessionRowDocId,
        sessionDocId: companyTableRow.sessionDocId,
        companyTableRow,
      };
      try {
        await this.firestoreService.updateSessionRow(user.uid, sessionRow, user.preferences.encryptionEnabled);
      } catch (error) {
        console.error(error);
        this._snackBar.open('Failed to update session to cloud storage', 'OK', {
          duration: 5000,
          panelClass: ['snackbar-warn'],
        });
      }
    }
  }

  togglePause() {
    if (this._checkPaused && this._batchFinished) {
      // only proceed if no batch is currently running
      this.serverUnreachableCounter = 0;
      this._checkPaused = false;
      this.checkData(this.resumeAtIndex, this.lastIncludeUnchecked);
    } else if (!this._checkPaused) {
      this._checkPaused = true;
    }
  }

  resetImportedData(): void {
    this._importedData = [];
    this._importedHeaders = [];
    this._importedHeadersColumnIndices = [];
    this._currentSession$ = null;
    this.resetControls();
  }

  private resetControls(): void {
    this._checkedRowsProgress = 0;
    this.setCheckFinished(false);
    this._checkStarted = false;
    this._checkPaused = false;
    this._batchFinished = true;
    this.resumeAtIndex = 0;
    this.serverUnreachableCounter = 0;
    this.currentFilters = [];
  }
}
