import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiResponse } from 'src/app/_classes/api-response';

@Component({
  selector: 'app-single-check-response',
  templateUrl: './single-check-response.component.html',
  styleUrls: ['./single-check-response.component.scss'],
})
export class SingleCheckResponseComponent implements OnInit {
  @Input() singleCheckResponse: ApiResponse;
  @Output() responseClearedEvent = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  clearSingleCheck() {
    this.responseClearedEvent.emit(true);
  }
}
