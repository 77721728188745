import { IImportData } from '../../_interfaces/import-data';
import { Component, Input, OnInit } from '@angular/core';
import { PropertyName } from 'src/app/_enums/property-name.enum';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit {
  @Input() import: IImportData;

  constructor() {}

  ngOnInit(): void {}

  getColumnDefinition(index: number): string {
    for (let i = 0; i < this.import.columnIndices.length; i++) {
      const element = this.import.columnIndices[i];
      if (element === index) { return PropertyName[i].toString(); }
    }
    return null;
  }
}
