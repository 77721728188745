import { ApiResponse } from './../../_classes/api-response';
import { Address } from './../../_classes/address';
import { ApiService } from './../../_services/api.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { IAlternativeData } from 'src/app/_interfaces/alternative-data';
import { IAlternativeDataDocument } from 'src/app/_interfaces/alternative-data-document';
import { ISession } from 'src/app/_interfaces/session';
import { AuthService } from 'src/app/_services/auth.service';
import { FirestoreService } from 'src/app/_services/firestore.service';
import { PromptInput } from 'src/app/_interfaces/prompt-input';
import { PromptDialogComponent } from 'src/app/_components/prompt-dialog/prompt-dialog.component';
import { switchMap, take } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IAddress } from 'src/app/_interfaces/address';
import { IApiResponse } from 'src/app/_interfaces/api-response';

export interface IAltTableData {
  alternativeName: string;
  alternativeAddress: IAddress;
  apiResponse: IApiResponse;
  parentDoc: IAlternativeDataDocument;
}

@Component({
  selector: 'app-alternative-data-dialog',
  templateUrl: './alternative-data-dialog.component.html',
  styleUrls: ['./alternative-data-dialog.component.scss'],
})
export class AlternativeDataDialogComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<IAltTableData>();
  displayedColumns: string[] = ['uid', 'name', 'addr1', 'addr2', 'addr3', 'addr4', 'addr5', 'addr6', 'action'];
  private subscriptions: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private firestoreService: FirestoreService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private apiService: ApiService,
    private dialogRef: MatDialogRef<any>
  ) {}

  ngOnInit(): void {
    this.authService.user$.pipe(take(1)).subscribe((user) => {
      if (user) {
        this.subscriptions.push(
          this.firestoreService.queryAlternativeData(user.uid, user.preferences.encryptionEnabled).subscribe(
            (docs) => {
              const rows: IAltTableData[] = [];
              docs.forEach((doc) => {
                doc.alternativeData.forEach((altData) => {
                  rows.push({
                    alternativeName: altData.alternativeName,
                    alternativeAddress: altData.alternativeAddress,
                    apiResponse: altData.apiResponse,
                    parentDoc: doc,
                  });
                });
              });

              this.dataSource = new MatTableDataSource<IAltTableData>(rows);
              this.dataSource.paginator = this.paginator;

              this.dataSource.sort = this.sort;
              const sortState: Sort = { active: 'name', direction: 'asc' };
              this.sort.active = sortState.active;
              this.sort.direction = sortState.direction;
              this.sort.sortChange.emit(sortState);
            },
            (error) => {
              console.error(error);
              this._snackBar.open('Failed to receive alternative data documents from cloud storage', 'OK', {
                duration: 5000,
                panelClass: ['snackbar-warn'],
              });
            }
          )
        );
      }
    });
  }

  deleteAlternativeDataRow(element: IAltTableData): void {
    const deleteItemsIndices: number[] = [];
    // find indices (also deletes duplicates if more than 1 is found)
    for (let i = 0; i < element.parentDoc.alternativeData.length; i++) {
      const item = element.parentDoc.alternativeData[i];
      if (
        Address.isEqual(item.alternativeAddress, element.alternativeAddress) &&
        item.alternativeName === element.alternativeName &&
        ApiResponse.isEqual(item.apiResponse, element.apiResponse)
      ) {
        deleteItemsIndices.push(i);
      }
    }
    // delete indices in reverse order to avoid messing up the indices of the base array
    for (let i = deleteItemsIndices.length - 1; i >= 0; i--) {
      element.parentDoc.alternativeData.splice(deleteItemsIndices[i], 1);
    }

    this._snackBar.open('Deleted alternative data row', 'OK', {
      duration: 2000,
    });

    this.authService.user$.pipe(take(1)).subscribe(async (user) => {
      try {
        if (element.parentDoc.alternativeData.length === 0) {
          await this.firestoreService.deleteAlternativeDataDocument(
            element.parentDoc,
            user.preferences.encryptionEnabled
          );
        }
        else { await this.firestoreService.updateAlternativeData(element.parentDoc, user.preferences.encryptionEnabled); }
      } catch (error) {
        console.error(error);
        this._snackBar.open('Failed to update alternative data cloud storage', 'OK', {
          duration: 5000,
          panelClass: ['snackbar-warn'],
        });
      }
    });
  }

  deleteAll(): void {
    const data: PromptInput = {
      title: 'Confirm deletion',
      text: 'Are you sure you want to delete all alternative data entries? This action cannot be undone and all data will be lost.',
    };
    const dialogRef = this.dialog.open(PromptDialogComponent, {
      maxWidth: '400px',
      data,
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.authService.user$.pipe(take(1)).subscribe((user) => {
          this.apiService.deleteAllAlternatives(user.preferences.encryptionEnabled);
          this._snackBar.open('All alternative data rows will be deleted in the next few minutes', 'OK', {
            duration: 2000,
          });
          this.dialogRef.close();
        });
      }
    });
  }
}
