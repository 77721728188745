<div *ngIf="authService.user$ | async as user" class="container create-profile">
  <mat-card *ngIf="user.preferences" class="create-profile__card">
    <h1>Set your Preferences</h1>
    <p class="create-profile__description">Your preferences can be changed afterwards on the Settings page.</p>

    <app-personal-settings [user]="user"></app-personal-settings>

    <mat-checkbox
      (change)="changeSetCompanyDataEnabled($event)"
      [checked]="setCompanyDataEnabled"
      color="primary"
      class="create-profile__light-header"
      >Set company data
    </mat-checkbox>
    <br />
    Setting your company data is required for paid-plans. Please note that for receiving Databox entries your VAT number
    needs to be set and validated.
    <form class="create-profile__tax-id-form" [formGroup]="taxIdForm">
      <mat-form-field class="create-profile__tax-id-input">
        <mat-label>VAT Number</mat-label>
        <input
          (input)="onTaxIdInputChange()"
          cdkFocusInitial
          formControlName="taxId"
          placeholder="e.g. ATU12345678"
          matInput
          type="text"
        />
      </mat-form-field>

      <mat-form-field class="create-profile__tax-id-input">
        <mat-label>Company Name</mat-label>
        <input
          cdkFocusInitial
          formControlName="companyName"
          placeholder="e.g. danubelabs Softwarelabor OG"
          matInput
          type="text"
        />
      </mat-form-field>
    </form>

    <div class="create-profile__actions">
      <button
        [disabled]="(taxIdForm.invalid && setCompanyDataEnabled) || taxIdSaving"
        (click)="createProfile(user)"
        mat-raised-button
        color="primary"
        type="submit"
      >
        <div class="create-profile__tax-id-btn-container">
          <div>Save preferences</div>
          <div *ngIf="taxIdSaving" class="create-profile__tax-id-btn-spinner">
            <mat-progress-spinner diameter="17" mode="indeterminate"></mat-progress-spinner>
          </div>
        </div>
      </button>
    </div>
  </mat-card>
</div>
