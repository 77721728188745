import { ApiService } from './api.service';
import { FirestoreService } from './firestore.service';
import { AuthService } from 'src/app/_services/auth.service';
import { IEncryptedSessionRow } from './../_interfaces/iencrypted-session-row';
import { ISessionRow } from './../_interfaces/session-row';
import { IEncryptedAltData } from './../_interfaces/iencrypted-alt-data';
import { Injectable } from '@angular/core';
import * as aesjs from 'aes-js';
import * as crypto from 'crypto-browserify';
import { IAlternativeDataDocument } from '../_interfaces/alternative-data-document';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  private _key: any;
  public get key(): any {
    return this._key; // Uint8Array
  }

  constructor(private authService: AuthService, private apiService: ApiService, private _snackBar: MatSnackBar) {}

  setNewKey(secret: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.authService.user$.pipe(take(1)).subscribe((user) => {
        if (user) {
          crypto.pbkdf2(secret, 'salt', 100000, 16, 'sha512', async (err, key) => {
            // set new password
            try {
              const res = await this.apiService.hashPassword(secret);
              user.preferences.passwordHash = res.hash;
              this.authService.updateUser(user);
              this._key = key;
              resolve();
            } catch (error) {
              console.error(error);
              this._snackBar.open('Password hashing function failed', 'OK', {
                duration: 5000,
                panelClass: ['snackbar-warn'],
              });
              reject(error);
            }
          });
        }
      });
    });
  }

  verifyKey(secret: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.authService.user$.pipe(take(1)).subscribe((user) => {
        if (user) {
          crypto.pbkdf2(secret, 'salt', 100000, 16, 'sha512', async (err, key) => {
            try {
              // password already set, check validity
              const res = await this.apiService.verifyPassword(user.preferences.passwordHash, secret);
              if (res.verified) {
                this._key = key;
                resolve(true);
              } else { resolve(false); }
            } catch (error) {
              console.error(error);
              this._snackBar.open('Password verification function failed', 'OK', {
                duration: 5000,
                panelClass: ['snackbar-warn'],
              });
              reject(error);
            }
          });
        }
      });
    });
  }

  encryptAlternativeDataDocument(input: IAlternativeDataDocument): IEncryptedAltData {
    const output: IEncryptedAltData = {
      docId: input.docId,
      userUid: input.userUid,
      encryptedOriginalDocHex: this.encrypt(JSON.stringify(input), this.key),
    };

    return output;
  }

  decryptAlternativeDataDocument(encryptedData: IEncryptedAltData): IAlternativeDataDocument {
    return JSON.parse(this.decrypt(encryptedData.encryptedOriginalDocHex, this.key));
  }

  encryptSessionRow(input: ISessionRow): IEncryptedSessionRow {
    const output: IEncryptedSessionRow = {
      docId: input.docId,
      sessionDocId: input.sessionDocId,
      encryptedOriginalDocHex: this.encrypt(JSON.stringify(input), this.key),
    };
    return output;
  }

  decryptSessionRow(encryptedData: IEncryptedSessionRow): ISessionRow {
    return JSON.parse(this.decrypt(encryptedData.encryptedOriginalDocHex, this.key));
  }

  private encrypt(inputString: string, key: any): string {
    const textBytes = aesjs.utils.utf8.toBytes(inputString);
    const aesCtr = new aesjs.ModeOfOperation.ctr(key);
    const encryptedBytes = aesCtr.encrypt(textBytes);
    return aesjs.utils.hex.fromBytes(encryptedBytes);
  }

  private decrypt(inputHex: string, key: any): string {
    // When ready to decrypt the hex string, convert it back to bytes
    const encryptedBytes = aesjs.utils.hex.toBytes(inputHex);

    // decrypt
    const aesCtr = new aesjs.ModeOfOperation.ctr(key);
    const decryptedBytes = aesCtr.decrypt(encryptedBytes);
    return aesjs.utils.utf8.fromBytes(decryptedBytes);
  }
}
