<div class="container subscription__adblock-enabled-banner">
  <app-adblock-enabled-banner></app-adblock-enabled-banner>
</div>
<div class="container subscription">
  <mat-card class="subscription__card">
    <h1>Subscription</h1>
    <div class="subscription__current-plan" *ngIf="{ role: authService.role | async } as context">
      <ng-container *ngIf="stripeRole[context.role] === stripeRole[stripeRole.FREE]; else paidPlan">
        <p>You're on the free plan (limited to {{ freeChecks }} checks per month).</p>

        <mat-card class="subscription__pro-card mat-elevation-z4">
          <h3>Basic Plan</h3>
          <p class="subscription__feature">
            <mat-icon class="subscription__feature-icon" aria-hidden="false">check_circle</mat-icon>
            € {{ basicPrice | number: '1.2-2' }} per month
          </p>
          <p class="subscription__feature">
            <mat-icon class="subscription__feature-icon" aria-hidden="false">check_circle</mat-icon> Includes
            {{ includedBasicChecks }} checks per month
          </p>
          <p class="subscription__feature">
            <mat-icon class="subscription__feature-icon" aria-hidden="false">check_circle</mat-icon> Additional capacity
            is billed with € {{ additionalBasicCheckCost | number: '1.2-2' }}/check
          </p>
          <button
            class="subscription__stripe-btn"
            (click)="subscribeToPlan(basicPriceId, volumeBasicPriceId, true)"
            [disabled]="basicSubscriptionLoading || proSubscriptionLoading"
            mat-raised-button
            color="primary"
          >
            <div class="subscription__stripe-btn-container">
              <div>Upgrade</div>
              <div *ngIf="basicSubscriptionLoading" class="subscription__stripe-spinner">
                <mat-progress-spinner diameter="17" mode="indeterminate"></mat-progress-spinner>
              </div>
            </div></button
        ></mat-card>
        <mat-card class="subscription__pro-card mat-elevation-z4">
          <h3>Pro Plan</h3>
          <p class="subscription__feature">
            <mat-icon class="subscription__feature-icon" aria-hidden="false">check_circle</mat-icon>
            € {{ proPrice | number: '1.2-2' }} per month
          </p>
          <p class="subscription__feature">
            <mat-icon class="subscription__feature-icon" aria-hidden="false">check_circle</mat-icon> Includes
            {{ includedProChecks }} checks per month
          </p>
          <p class="subscription__feature">
            <mat-icon class="subscription__feature-icon" aria-hidden="false">check_circle</mat-icon> Additional capacity
            is billed with € {{ additionalProCheckCost | number: '1.2-2' }}/check
          </p>
          <button
            class="subscription__stripe-btn"
            (click)="subscribeToPlan(proPriceId, volumeProPriceId, false)"
            mat-raised-button
            color="primary"
            [disabled]="basicSubscriptionLoading || proSubscriptionLoading"
          >
            <div class="subscription__stripe-btn-container">
              <div>Upgrade</div>
              <div *ngIf="proSubscriptionLoading" class="subscription__stripe-spinner">
                <mat-progress-spinner diameter="17" mode="indeterminate"></mat-progress-spinner>
              </div>
            </div></button
        ></mat-card>
      </ng-container>
      <ng-template #paidPlan>
        <ng-container *ngIf="stripeRole[context.role] === stripeRole[stripeRole.BASIC_PLAN]"
          >You're on the basic plan. (Limited to {{ includedBasicChecks }} checks per month, additional capacity is
          priced with € {{ additionalBasicCheckCost | number: '1.2-2' }}/check)

          <mat-card class="subscription__pro-card mat-elevation-z4">
            <h3>Pro Plan</h3>
            <p class="subscription__feature">
              <mat-icon class="subscription__feature-icon" aria-hidden="false">check_circle</mat-icon>
              € {{ proPrice | number: '1.2-2' }} per month
            </p>
            <p class="subscription__feature">
              <mat-icon class="subscription__feature-icon" aria-hidden="false">check_circle</mat-icon> Includes
              {{ includedProChecks }} checks per month
            </p>
            <p class="subscription__feature">
              <mat-icon class="subscription__feature-icon" aria-hidden="false">check_circle</mat-icon> Additional
              capacity is billed with € {{ additionalProCheckCost | number: '1.2-2' }}/check
            </p>
            <button
              class="subscription__stripe-btn"
              (click)="upgradeToProPlan()"
              mat-raised-button
              color="primary"
              [disabled]="upgradeToProLoading"
            >
              <div class="subscription__stripe-btn-container">
                <div>Upgrade to Pro</div>
                <div *ngIf="upgradeToProLoading" class="subscription__stripe-spinner">
                  <mat-progress-spinner diameter="17" mode="indeterminate"></mat-progress-spinner>
                </div>
              </div></button
          ></mat-card> </ng-container
        ><ng-container *ngIf="stripeRole[context.role] === stripeRole[stripeRole.PRO_PLAN]"
          >You're on the pro plan. (Limited to {{ includedProChecks }} checks per month, additional capacity is priced
          with € {{ additionalProCheckCost | number: '1.2-2' }}/check)</ng-container
        >

        <div class="subscription__invoice-amount" *ngIf="invoice$ | async as invoice; else invoiceLoading">
          Next invoice value:
          <ng-container *ngIf="invoice.currency === 'eur'; else notEur">€</ng-container>
          <ng-template #notEur>invoice.currency</ng-template>
          {{ invoice.amount_due / 100 | number: '1.2' }} (updated every 24 hours)
          <app-upcoming-invoice [invoice]="invoice"></app-upcoming-invoice>
        </div>
        <ng-template #invoiceLoading
          ><div class="subscription__loading-spinner-container subscription__invoice-amount">
            Next invoice value:
            <div class="subscription__loading-spinner">
              <mat-progress-spinner diameter="17" mode="indeterminate"></mat-progress-spinner>
            </div></div
        ></ng-template>
      </ng-template>
    </div>

    <ng-container *ngIf="authService.user$ | async as user">
      <button
        *ngIf="user.stripeLink"
        (click)="openCustomerPortal()"
        mat-raised-button
        [disabled]="manageSubscriptionLoading"
      >
        <div class="subscription__manage-btn-container">
          <div>Manage your Stripe payments</div>
          <div *ngIf="manageSubscriptionLoading" class="subscription__manage-spinner">
            <mat-progress-spinner diameter="17" mode="indeterminate"></mat-progress-spinner>
          </div>
        </div>
      </button>
    </ng-container>
  </mat-card>
</div>
