<div class="container settings">
  <mat-card *ngIf="authService.user$ | async as user" class="settings__card">
    <h1>Settings</h1>
    <h2>Preferences</h2>
    <p class="settings__disabled-note" *ngIf="vatService.checkStarted && !vatService.checkFinished">
      Settings are disabled while a check is in progress.
    </p>

    <app-personal-settings [user]="user" [showSetEncryptionButton]="true"></app-personal-settings>

    <h2>Account</h2>
    <div class="settings__light-header">Your company data</div>
    <div *ngIf="user.taxIdValidated; else taxIdNotValidated">
      Your VAT number has been validated ({{ user.taxIdValidated['seconds'] * 1000 | date : 'dd-MM-yyyy HH:mm' }}).
    </div>
    <ng-template #taxIdNotValidated>
      <div *ngIf="user.taxId; else taxIdNotAdded" class="settings__tax-id-not-validated">
        Your VAT number has not been validated yet! Please note that for receiving Databox entries your VAT number needs
        to be set and validated.
      </div>
    </ng-template>
    <ng-template #taxIdNotAdded>
      <div class="settings__tax-id-not-validated">
        No VAT number has been added to your account yet! Please note that for receiving Databox entries your VAT number
        needs to be set and validated.
      </div>
    </ng-template>
    <form class="settings__tax-id-form" [formGroup]="taxIdForm">
      <mat-form-field class="settings__tax-id-input">
        <mat-label>VAT Number</mat-label>
        <input
          (input)="onTaxIdInputChange()"
          cdkFocusInitial
          formControlName="taxId"
          placeholder="e.g. ATU12345678"
          matInput
          type="text"
        />
      </mat-form-field>

      <mat-form-field class="settings__tax-id-input">
        <mat-label>Company Name</mat-label>
        <input
          cdkFocusInitial
          formControlName="companyName"
          placeholder="e.g. danubelabs Softwarelabor OG"
          matInput
          type="text"
        />
      </mat-form-field>
    </form>

    <div>
      <span class="settings__light-header"> Your email address </span><br />
      {{ user.email }}
    </div>
    <button
      class="settings__set-password-btn"
      mat-raised-button
      color="basic"
      [disabled]="!resetButtonEnabled"
      (click)="resetPassword(user.email)"
    >
      Request a link to set a new password
    </button>
    <p>
      <i
        >You can also set a new password if your account is currently connected to Google to provide a second sign-in
        option via your email address.</i
      >
    </p>

    <div class="settings__action-row">
      <button
        [disabled]="deletionLoading"
        class="settings__delete-btn"
        mat-raised-button
        color="warn"
        (click)="deleteAccount(user)"
      >
        <div class="settings__delete-btn-container">
          <div>Delete account</div>
          <div *ngIf="deletionLoading" class="settings__delete-spinner">
            <mat-progress-spinner diameter="17" mode="indeterminate"></mat-progress-spinner>
          </div>
        </div>
      </button>

      <button
        class="settings__tax-id-button"
        mat-raised-button
        color="primary"
        [disabled]="taxIdForm.invalid || taxIdSaving"
        (click)="saveCompanyData(user)"
      >
        <div class="settings__tax-id-btn-container">
          <div>Save and validate company data</div>
          <div *ngIf="taxIdSaving" class="settings__tax-id-btn-spinner">
            <mat-progress-spinner diameter="17" mode="indeterminate"></mat-progress-spinner>
          </div>
        </div>
      </button>
    </div>
  </mat-card>
</div>
