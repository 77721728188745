import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { xhrCallback } from '@sentry/tracing/types/browser/request';
import jsPDF, { CellConfig, TableConfig } from 'jspdf';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { IDataboxEntry } from '../_interfaces/idatabox-entry';
import { AuthService } from '../_services/auth.service';
import { FirestoreService } from '../_services/firestore.service';

@Component({
  selector: 'app-databox-dialog',
  templateUrl: './databox-dialog.component.html',
  styleUrls: ['./databox-dialog.component.scss'],
})
export class DataboxDialogComponent implements OnInit {
  databoxEntries$: Observable<IDataboxEntry[]>;
  private readonly config: TableConfig = {
    fontSize: 9,
    headerBackgroundColor: 'white',
  };
  private readonly cellConfigs: CellConfig[] = [
    {
      name: 'UID',
      width: 140,
      align: 'left',
      padding: 0,
      prompt: null,
    },
    {
      name: 'Stufe',
      width: 55,
      align: 'left',
      padding: 0,
      prompt: null,
    },
    {
      name: 'gültig',
      width: 55,
      align: 'left',
      padding: 0,
      prompt: null,
    },
  ];

  constructor(
    private authService: AuthService,
    private firestoreService: FirestoreService,
    private _snackBar: MatSnackBar
  ) {
    this.authService.user$.pipe(take(1)).subscribe((user) => {
      if (!user) return;
      this.databoxEntries$ = this.firestoreService.queryDataboxEntries(user.uid);
    });
  }

  ngOnInit(): void {}

  exportPdf(entry: IDataboxEntry) {
    const date = new Date(entry.date['seconds'] * 1000);
    const dateString = date.getDate() + '.' + date.getMonth() + '.' + date.getFullYear();
    const doc = new jsPDF();

    doc.setFontSize(9);
    doc.text(`Teilnehmer:`, 10, 10);
    doc.text(entry.name, 45, 10);
    doc.text(`Datum: ${dateString}`, 170, 10);

    doc.text(`Benutzer:`, 10, 14);
    doc.text(`DanubelabsSupervisor`, 45, 14);

    doc.setFontSize(20);
    doc.setFont(undefined, 'bold');
    doc.text(
      `Mitteilung über die durchgeführten Bestätigungen von
Umsatzsteuer-Identifikations-Nummern
über das Webservice von FinanzOnline vom ${dateString}`,
      10,
      30
    );

    doc.setLineHeightFactor(1.5);
    doc.setFontSize(9);
    doc.setFont(undefined, 'normal');
    doc.text(
      `Bei Abfrage der Stufe 1 (einfaches Bestätigungsverfahren) wird lediglich überprüft, ob die angeführte UID gültig ist oder nicht.

Bei Abfrage der Stufe 2 (qualifiziertes Bestätigungsverfahren) sind die Ihnen über das Webservice mitgeteilten Daten mit den Ihnen
vorliegenden Daten des Erwerbers zu vergleichen.
Zur Abklärung etwaiger Datendifferenzen wenden Sie sich bitte direkt an Ihren Kunden.

Der Ausdruck dieser Mitteilung erfolgte durch den Abfragenden selbst.
Die ausgedruckte Bestätigung gilt als Beleg und ist gemäß § 132 BAO aufzubewahren.`,
      10,
      60
    );
    doc.setLineHeightFactor(1);

    doc.setFontSize(16);
    doc.setFont(undefined, 'bold');
    doc.text(`Abfragendes Unternehmen:`, 10, 110);

    doc.setFontSize(9);
    doc.setFont(undefined, 'bold');
    doc.text(`UID:`, 10, 120);

    doc.setFontSize(9);
    doc.setFont(undefined, 'normal');
    doc.text(entry.uid, 60, 120);

    doc.line(10, 123, doc.internal.pageSize.getWidth() - 10, 123);

    doc.setFontSize(9);
    doc.setFont(undefined, 'bold');
    doc.text(`Name:`, 10, 128);

    doc.setFontSize(9);
    doc.setFont(undefined, 'normal');
    doc.text(entry.name, 60, 128);

    doc.line(10, 131, doc.internal.pageSize.getWidth() - 10, 131);

    doc.setFontSize(9);
    doc.setFont(undefined, 'bold');
    doc.text(`FA/StNr:`, 10, 136);

    doc.setFontSize(9);
    doc.setFont(undefined, 'normal');
    doc.text(entry.fastNr, 60, 136);

    doc.line(10, 139, doc.internal.pageSize.getWidth() - 10, 139);

    doc.setFontSize(16);
    doc.setFont(undefined, 'bold');
    doc.text(`Resultat der Bestätigungen:`, 10, 155);

    doc.setFontSize(9);

    const firstPageAmount = 11;
    const fullPageAmount = 26;
    const iterations = Math.ceil((entry.checks.length - firstPageAmount) / fullPageAmount) + 1;

    let lowerLimit;
    let uppperLimit;
    let yDistance;
    doc.setFont(undefined, 'normal');

    for (let i = 0; i < iterations; i++) {
      if (i === 0) {
        yDistance = 165;
        lowerLimit = 0;
        uppperLimit = firstPageAmount;
      } else {
        yDistance = 25;
        lowerLimit = uppperLimit;
        uppperLimit = uppperLimit += fullPageAmount;

        // add headers
        doc.setFontSize(9);
        doc.text(`Teilnehmer:`, 10, 10);
        doc.text(entry.name, 45, 10);
        doc.text(`Datum: ${dateString}`, 170, 10);
        doc.text(`Benutzer:`, 10, 14);
        doc.text(`DanubelabsSupervisor`, 45, 14);
      }

      // add page number
      doc.setFontSize(9);
      doc.text(i + 1 + '/' + iterations, 190, 288);

      // add table
      const tmpTableData = entry.checks
        .map((data, index) => {
          if (index >= lowerLimit && index < uppperLimit)
            return { UID: data.uid, Stufe: data.stufe.toString(), gültig: data.valid ? 'J' : 'N' };
        })
        .filter((data) => data);

      doc.table(10, yDistance, tmpTableData, this.cellConfigs, this.config);

      // add new page if it is not last iteration
      if (i < iterations - 1) {
        doc.addPage();
      }
    }

    doc.save(`databox_${date.getDate() + '' + date.getMonth() + '' + date.getFullYear()}.pdf`);
  }

  markDataboxEntryAsRead(entry: IDataboxEntry) {
    entry.read = !entry.read;
    this.authService.user$.pipe(take(1)).subscribe(async (user) => {
      if (!user) {
        return;
      }

      await this.firestoreService.updateDataboxEntry(user.uid, entry);

      const message = entry.read ? 'Set databox entry to read.' : 'Set databox entry to unread.';
      this._snackBar.open(message, 'OK', {
        duration: 2000,
      });
    });
  }
}
