export enum ErrorState {
  UnauthenticatedError = 1,
  ProgrammingError = 2,
  ResourceRequestError = 3,
  UnexpectedFormatError = 4,
  FrameworkError = 5,
  UnavailableError = 6,
  VatNumberUnknownError = 7,
  InternalStateConsistencyError = 8,
  FirestoreError = 9,
  FinOnError = 10,
}
