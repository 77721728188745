import { Component, Input, OnInit } from '@angular/core';
import { Filter } from 'src/app/_enums/filter.enum';
import { State } from 'src/app/_enums/state.enum';

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
})
export class TableFilterComponent implements OnInit {
  @Input() dataSource: any;
  @Input() currentFilters: any[] = []; // indices are Filter enum values

  validFilterSelected = false;
  invalidFilterSelected = false;
  correctAddressFilterSelected = false;
  incorrectAddressFilterSelected = false;
  correctNameFilterSelected = false;
  incorrectNameFilterSelected = false;
  checkedFilterSelected = false;
  uncheckedFilterSelected = false;
  unavailableFilterSelected = false;
  errorFilterSelected = false;
  dataModifiedFilterSelected = false;
  thresholdActiveFilterSelected = false;

  filter: typeof Filter = Filter;

  constructor() {}

  ngOnInit(): void {}

  resetFilters(): void {
    // make sure to update MatTable after FilterReset
    this.currentFilters = [];

    this.validFilterSelected = false;
    this.invalidFilterSelected = false;
    this.correctAddressFilterSelected = false;
    this.incorrectAddressFilterSelected = false;
    this.correctNameFilterSelected = false;
    this.incorrectNameFilterSelected = false;
    this.checkedFilterSelected = false;
    this.uncheckedFilterSelected = false;
    this.unavailableFilterSelected = false;
    this.errorFilterSelected = false;
    this.dataModifiedFilterSelected = false;
    this.thresholdActiveFilterSelected = false;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.currentFilters[Filter.STRING_MATCHING] = filterValue.trim().toUpperCase();
    this.dataSource.filter = this.currentFilters;
  }

  filterTable(filter: string): void {
    switch (filter) {
      case 'valid': {
        if (this.currentFilters[Filter.VALID_UID] == true) { this.currentFilters[Filter.VALID_UID] = null; }
        else { this.currentFilters[Filter.VALID_UID] = true; }
        this.dataSource.filter = this.currentFilters;

        this.validFilterSelected = !this.validFilterSelected;
        this.invalidFilterSelected = false;
        break;
      }
      case 'invalid': {
        if (this.currentFilters[Filter.VALID_UID] == false) { this.currentFilters[Filter.VALID_UID] = null; }
        else { this.currentFilters[Filter.VALID_UID] = false; }
        this.dataSource.filter = this.currentFilters;

        this.invalidFilterSelected = !this.invalidFilterSelected;
        this.validFilterSelected = false;
        break;
      }
      case 'correctAddress': {
        if (this.currentFilters[Filter.CORRECT_ADDRESS] == true) { this.currentFilters[Filter.CORRECT_ADDRESS] = null; }
        else { this.currentFilters[Filter.CORRECT_ADDRESS] = true; }
        this.dataSource.filter = this.currentFilters;

        this.correctAddressFilterSelected = !this.correctAddressFilterSelected;
        this.incorrectAddressFilterSelected = false;
        break;
      }
      case 'incorrectAddress': {
        if (this.currentFilters[Filter.CORRECT_ADDRESS] == false) { this.currentFilters[Filter.CORRECT_ADDRESS] = null; }
        else { this.currentFilters[Filter.CORRECT_ADDRESS] = false; }
        this.dataSource.filter = this.currentFilters;

        this.incorrectAddressFilterSelected = !this.incorrectAddressFilterSelected;
        this.correctAddressFilterSelected = false;
        break;
      }
      case 'correctName': {
        if (this.currentFilters[Filter.CORRECT_NAME] == true) { this.currentFilters[Filter.CORRECT_NAME] = null; }
        else { this.currentFilters[Filter.CORRECT_NAME] = true; }
        this.dataSource.filter = this.currentFilters;

        this.correctNameFilterSelected = !this.correctNameFilterSelected;
        this.incorrectNameFilterSelected = false;
        break;
      }
      case 'incorrectName': {
        if (this.currentFilters[Filter.CORRECT_NAME] == false) { this.currentFilters[Filter.CORRECT_NAME] = null; }
        else { this.currentFilters[Filter.CORRECT_NAME] = false; }
        this.dataSource.filter = this.currentFilters;

        this.incorrectNameFilterSelected = !this.incorrectNameFilterSelected;
        this.correctNameFilterSelected = false;
        break;
      }
      case 'checked': {
        if (this.currentFilters[Filter.STATE] == State.CHECKED) { this.currentFilters[Filter.STATE] = null; }
        else { this.currentFilters[Filter.STATE] = State.CHECKED; }
        this.dataSource.filter = this.currentFilters;

        this.checkedFilterSelected = !this.checkedFilterSelected;
        this.uncheckedFilterSelected = false;
        this.unavailableFilterSelected = false;
        this.errorFilterSelected = false;
        break;
      }
      case 'unchecked': {
        if (this.currentFilters[Filter.STATE] == State.UNCHECKED) { this.currentFilters[Filter.STATE] = null; }
        else { this.currentFilters[Filter.STATE] = State.UNCHECKED; }
        this.dataSource.filter = this.currentFilters;

        this.uncheckedFilterSelected = !this.uncheckedFilterSelected;
        this.checkedFilterSelected = false;
        this.unavailableFilterSelected = false;
        this.errorFilterSelected = false;
        break;
      }
      case 'unavailable': {
        if (this.currentFilters[Filter.STATE] == State.UNAVAILABLE) { this.currentFilters[Filter.STATE] = null; }
        else { this.currentFilters[Filter.STATE] = State.UNAVAILABLE; }
        this.dataSource.filter = this.currentFilters;

        this.unavailableFilterSelected = !this.unavailableFilterSelected;
        this.checkedFilterSelected = false;
        this.uncheckedFilterSelected = false;
        this.errorFilterSelected = false;
        break;
      }
      case 'error': {
        if (this.currentFilters[Filter.STATE] == State.ERROR) { this.currentFilters[Filter.STATE] = null; }
        else { this.currentFilters[Filter.STATE] = State.ERROR; }
        this.dataSource.filter = this.currentFilters;

        this.errorFilterSelected = !this.errorFilterSelected;
        this.checkedFilterSelected = false;
        this.unavailableFilterSelected = false;
        this.uncheckedFilterSelected = false;
        break;
      }
      case 'modified': {
        if (this.currentFilters[Filter.DATA_MODIFIED] == true) { this.currentFilters[Filter.DATA_MODIFIED] = null; }
        else { this.currentFilters[Filter.DATA_MODIFIED] = true; }
        this.dataSource.filter = this.currentFilters;

        this.dataModifiedFilterSelected = !this.dataModifiedFilterSelected;
        break;
      }
      case 'threshold': {
        if (this.currentFilters[Filter.THRESHOLD_ACTIVE] == true) { this.currentFilters[Filter.THRESHOLD_ACTIVE] = null; }
        else { this.currentFilters[Filter.THRESHOLD_ACTIVE] = true; }
        this.dataSource.filter = this.currentFilters;

        this.thresholdActiveFilterSelected = !this.thresholdActiveFilterSelected;
        break;
      }
      default:
        break;
    }
  }
}
