<div class="import__parent">
  <div class="container import__adblock-enabled-banner"><app-adblock-enabled-banner></app-adblock-enabled-banner></div>

  <div class="container import">
    <mat-card class="import__card">
      <mat-horizontal-stepper #stepper linear>
        <mat-step completed="false">
          <br />
          <ng-template matStepLabel>VAT No. Data</ng-template>
          <app-uid-import (fileImportFinishedEvent)="uidDataImported($event)"></app-uid-import>
        </mat-step>
        <mat-step completed="false">
          <br />
          <ng-template matStepLabel>Review</ng-template>
          <app-review *ngIf="uidImportData" [import]="uidImportData"></app-review>
          <div class="import__action-bar">
            <button matStepperPrevious mat-button color="primary">
              <mat-icon aria-hidden="false">west</mat-icon>
            </button>
            <button (click)="proceedStepper()" mat-raised-button color="primary">Next</button>
          </div>
        </mat-step>
        <mat-step completed="false" optional>
          <br />
          <ng-template matStepLabel>Alternative Data</ng-template>
          <app-alternative-data-import
            (fileImportFinishedEvent)="altDataImported($event)"
          ></app-alternative-data-import>
          <div class="import__action-bar">
            <button matStepperPrevious mat-button color="primary">
              <mat-icon aria-hidden="false">west</mat-icon>
            </button>
            <div>
              <button (click)="proceedStepper(); proceedStepper()" mat-raised-button color="primary">Skip</button>
            </div>
          </div>
        </mat-step>
        <mat-step completed="false" optional>
          <br />
          <ng-template matStepLabel>Review</ng-template>
          <app-review
            *ngIf="alternativeDataImportData; else noAltDataImported"
            [import]="alternativeDataImportData"
          ></app-review>
          <ng-template #noAltDataImported>No alternative data imported.</ng-template>
          <div class="import__action-bar">
            <button matStepperPrevious mat-button color="primary">
              <mat-icon aria-hidden="false">west</mat-icon>
            </button>
            <button (click)="proceedStepper()" mat-raised-button color="primary">Next</button>
          </div>
        </mat-step>
        <mat-step completed="false">
          <br />
          <ng-template matStepLabel>Import</ng-template>
          <ng-container *ngIf="uidImportData">
            <app-validation
              (uidDataValidatedEvent)="setUidImportValidated($event)"
              (altDataValidatedEvent)="setAlternativeDataImportValidated($event)"
              [uidData]="uidImportData"
              [altData]="alternativeDataImportData"
              [importRunning]="spinnerEnabled"
            >
            </app-validation>
            <div class="import__action-bar">
              <button *ngIf="!spinnerEnabled; else spacerDiv" matStepperPrevious mat-button color="primary">
                <mat-icon aria-hidden="false">west</mat-icon>
              </button>
              <ng-template #spacerDiv><div></div></ng-template>
              <button
                *ngIf="!spinnerEnabled; else spinner"
                mat-raised-button
                [disabled]="!uidImportReady || !alternativeDataImportReady"
                (click)="import()"
                color="primary"
              >
                Import
              </button>

              <ng-template #spinner>
                <div class="import__spinner-row">
                  <div class="import__spinner">
                    <mat-progress-spinner
                      *ngIf="sessionCloudStorageProgress === 0; else showProgress"
                      diameter="25"
                      class="example-margin"
                      mode="indeterminate"
                    >
                    </mat-progress-spinner>
                    <ng-template #showProgress>
                      <mat-progress-spinner
                        diameter="25"
                        class="example-margin"
                        [value]="sessionCloudStorageProgress"
                        mode="determinate"
                      >
                      </mat-progress-spinner
                    ></ng-template>
                  </div>
                  <div>{{ cloudStorageMessage }}</div>
                </div>
              </ng-template>
            </div>
          </ng-container>
        </mat-step>
      </mat-horizontal-stepper>
    </mat-card>
  </div>
</div>
