<div class="container">
  <app-adblock-enabled-banner></app-adblock-enabled-banner>
  <div class="container-card">
    <mat-card class="card-login">
      <h1>Sign in</h1>
      <div class="container-actions">
        <form [formGroup]="loginForm" (ngSubmit)="loginEmail()">
          <mat-form-field class="login-input">
            <mat-label>Email</mat-label>
            <input
              autocomplete="email"
              formControlName="email"
              name="email"
              type="email"
              matInput
              placeholder="john.doe@gmail.com"
            /> </mat-form-field
          ><br />
          <mat-form-field class="login-input">
            <mat-label>Password</mat-label>
            <input
              autocomplete="current-password"
              formControlName="password"
              name="password"
              type="password"
              matInput
            />
          </mat-form-field>
          <br />
          <button
            [disabled]=""
            class="button-action"
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!loginForm.valid || emailLoginLoading || googleLoginLoading"
          >
            <div class="login__google-btn-container">
              <div>Sign in</div>
              <div *ngIf="emailLoginLoading" class="login__google-spinner">
                <mat-progress-spinner diameter="17" mode="indeterminate"></mat-progress-spinner>
              </div>
            </div>
          </button>
        </form>

        <p class="login__spacer">or</p>
        <button
          [disabled]="emailLoginLoading || googleLoginLoading"
          class="button-action"
          (click)="loginGoogle()"
          mat-raised-button
          color="primary"
        >
          <div class="login__google-btn-container">
            <div>Sign in with Google</div>
            <div *ngIf="googleLoginLoading" class="login__google-spinner">
              <mat-progress-spinner diameter="17" mode="indeterminate"></mat-progress-spinner>
            </div>
          </div>
        </button>
        <a class="login__forgot-pwd-link" routerLink="/password-reset">Forgot your password?</a>
        <p class="login__terms-paragraph">
          By signing in, you agree to our
          <a
            class="login__forgot-pwd-link"
            target="_blank"
            href="https://firebasestorage.googleapis.com/v0/b/vat-check-f32e6.appspot.com/o/docs%2Fgeneral-terms-and-conditions.pdf?alt=media&token=a10be697-6327-45f5-9f84-f47ad9f96839"
            >terms of service</a
          >,
          <a
            class="login__forgot-pwd-link"
            target="_blank"
            href="https://firebasestorage.googleapis.com/v0/b/vat-check-f32e6.appspot.com/o/docs%2Fservice-level-agreement.pdf?alt=media&token=f4f72a92-7624-4611-92b1-de5479004c39"
            >service level agreements</a
          >
          and
          <a class="login__forgot-pwd-link" target="_blank" href="https://danubelabs.at/privacy-policy.html"
            >privacy policy</a
          >.
        </p>
      </div>
    </mat-card>
  </div>
</div>
