<div mat-dialog-title class="row-dialog__title-row">
  <div>
    <div class="row-dialog__title">{{ row.Name }}</div>
    <div class="row-dialog__title-uid">{{ row.UID }}</div>
  </div>

  <div class="row-dialog__status">
    <div class="status-item">
      VAT No.:
      <span [ngClass]="row.apiResponse?.valid ? 'status-item--valid' : 'status-item--invalid'">
        {{ row.apiResponse?.valid ? 'valid' : 'invalid' }}
      </span>
    </div>
    <div class="status-item">
      Address:
      <span [ngClass]="row.correctAddress ? 'status-item--valid' : 'status-item--invalid'">
        {{ row.correctAddress ? 'valid' : 'invalid' }}</span
      >
    </div>
    <div class="status-item">
      Name:
      <span [ngClass]="row.correctName ? 'status-item--valid' : 'status-item--invalid'">{{
        row.correctName ? 'valid' : 'invalid'
      }}</span>
    </div>
  </div>
</div>

<mat-dialog-content class="mat-typography">
  <div>
    <mat-tab-group>
      <mat-tab label="Compare Data">
        <div class="row-dialog__tab-content">
          <br />
          <table>
            <tr>
              <td></td>
              <th>Imported Data</th>
              <th>API Response Data</th>
            </tr>
            <tr>
              <th>Name</th>
              <td *ngIf="!isResponseAccepted(); else lineThrough0">
                {{ row.Name }}
              </td>
              <ng-template #lineThrough0
                ><td class="row-dialog__td--line-through">
                  {{ row.Name }}
                </td></ng-template
              >
              <td>{{ row.apiResponse.name }}</td>
            </tr>
            <tr>
              <th>Addr1</th>
              <td *ngIf="!isResponseAccepted(); else lineThrough1">
                {{ row.address.Addr1 }}
              </td>
              <ng-template #lineThrough1
                ><td class="row-dialog__td--line-through">
                  {{ row.address.Addr1 }}
                </td></ng-template
              >
              <td>{{ row.apiResponse.address?.Addr1 }}</td>
            </tr>
            <tr>
              <th>Addr2</th>
              <td *ngIf="!isResponseAccepted(); else lineThrough2">
                {{ row.address.Addr2 }}
              </td>
              <ng-template #lineThrough2
                ><td class="row-dialog__td--line-through">
                  {{ row.address.Addr2 }}
                </td></ng-template
              >
              <td>{{ row.apiResponse.address?.Addr2 }}</td>
            </tr>
            <tr>
              <th>Addr3</th>
              <td *ngIf="!isResponseAccepted(); else lineThrough3">
                {{ row.address.Addr3 }}
              </td>
              <ng-template #lineThrough3
                ><td class="row-dialog__td--line-through">
                  {{ row.address.Addr3 }}
                </td></ng-template
              >
              <td>{{ row.apiResponse.address?.Addr3 }}</td>
            </tr>
            <tr>
              <th>Addr4</th>
              <td *ngIf="!isResponseAccepted(); else lineThrough4">
                {{ row.address.Addr4 }}
              </td>
              <ng-template #lineThrough4
                ><td class="row-dialog__td--line-through">
                  {{ row.address.Addr4 }}
                </td></ng-template
              >
              <td>{{ row.apiResponse.address?.Addr4 }}</td>
            </tr>
            <tr>
              <th>Addr5</th>
              <td *ngIf="!isResponseAccepted(); else lineThrough5">
                {{ row.address.Addr5 }}
              </td>
              <ng-template #lineThrough5
                ><td class="row-dialog__td--line-through">
                  {{ row.address.Addr5 }}
                </td></ng-template
              >
              <td>{{ row.apiResponse.address?.Addr5 }}</td>
            </tr>
            <tr>
              <th>Addr6</th>
              <td *ngIf="!isResponseAccepted(); else lineThrough6">
                {{ row.address.Addr6 }}
              </td>
              <ng-template #lineThrough6
                ><td class="row-dialog__td--line-through">
                  {{ row.address.Addr6 }}
                </td></ng-template
              >
              <td>{{ row.apiResponse.address?.Addr6 }}</td>
            </tr>
            <tr class="row-dialog__action-row">
              <td></td>
              <td>
                <br />
                <button
                  class="row-dialog__action-button"
                  (click)="addAlternativeData()"
                  mat-raised-button
                  color="primary"
                >
                  Add as alternative data
                </button>
              </td>
              <td>
                <ng-container *ngIf="!isResponseAccepted(); else accepted">
                  <br />
                  <button
                    class="row-dialog__action-button"
                    (click)="acceptResponse(row.apiResponse)"
                    mat-raised-button
                    color="primary"
                    [disabled]="!row.apiResponse.valid"
                  >
                    Accept response data
                  </button>
                </ng-container>

                <ng-template #accepted>
                  <br />
                  <button class="row-dialog__action-button" (click)="removeResponse()" mat-raised-button color="accent">
                    Remove Response Data
                  </button>
                </ng-template>
              </td>
            </tr>
          </table>
        </div>
      </mat-tab>
      <mat-tab label="Add Custom Alternative Data">
        <div class="row-dialog__tab-content">
          <br />

          <form [formGroup]="alternativeDataForm">
            <mat-form-field class="address-input">
              <mat-label>Name</mat-label>
              <input formControlName="name" type="text" matInput placeholder="e.g. Company Name" />
            </mat-form-field>
            <mat-form-field class="address-input">
              <mat-label>Addr1</mat-label>
              <input formControlName="addr1" type="text" matInput placeholder="e.g. Austria" />
            </mat-form-field>
            <mat-form-field class="address-input">
              <mat-label>Addr2</mat-label>
              <input formControlName="addr2" type="text" matInput placeholder="e.g. 1010 Vienna" />
            </mat-form-field>
            <mat-form-field class="address-input">
              <mat-label>Addr3</mat-label>
              <input formControlName="addr3" type="text" matInput placeholder="e.g. Street 1" />
            </mat-form-field>
            <mat-form-field class="address-input">
              <mat-label>Addr4</mat-label>
              <input formControlName="addr4" type="text" matInput placeholder="e.g. Street 1" />
            </mat-form-field>
            <mat-form-field class="address-input">
              <mat-label>Addr5</mat-label>
              <input formControlName="addr5" type="text" matInput placeholder="e.g. Street 1" />
            </mat-form-field>
            <mat-form-field class="address-input">
              <mat-label>Addr6</mat-label>
              <input formControlName="addr6" type="text" matInput placeholder="e.g. Street 1" />
            </mat-form-field>
            <div class="row-dialog__stepper-action-div">
              <button
                [disabled]="!alternativeDataForm.valid"
                (click)="addAlternativeData(true)"
                mat-raised-button
                color="primary"
              >
                Add alternative data
              </button>
            </div>
          </form>
        </div>
      </mat-tab>
      <mat-tab label="Stored Alternatives">
        <div class="row-dialog__tab-content">
          <br />
          <table>
            <tr>
              <th>Name</th>
              <th>Addr1</th>
              <th>Addr2</th>
              <th>Addr3</th>
              <th>Addr4</th>
              <th>Addr5</th>
              <th>Addr6</th>
              <th></th>
            </tr>
            <tr *ngFor="let data of row.alternativeData">
              <td>{{ data.alternativeName }}</td>
              <td>{{ data.alternativeAddress.Addr1 }}</td>
              <td>{{ data.alternativeAddress.Addr2 }}</td>
              <td>{{ data.alternativeAddress.Addr3 }}</td>
              <td>{{ data.alternativeAddress.Addr4 }}</td>
              <td>{{ data.alternativeAddress.Addr5 }}</td>
              <td>{{ data.alternativeAddress.Addr6 }}</td>
              <td>
                <button (click)="removeAlternativeData(data)" mat-button>
                  <mat-icon aria-hidden="false">delete_outline</mat-icon>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
