<div class="app-container">
  <div>
    <div class="nav-bar">
      <app-nav-bar (restoreCloudSessionClicked)="restoreCloudSessionClicked()"></app-nav-bar>
    </div>
    <router-outlet (activate)="onOutletLoaded($event)"></router-outlet>
  </div>
  <mat-toolbar class="footer__container">
    <div class="footer__text footer__company-name">
      <a class="footer__url" target="_blank" href="https://danubelabs.at">danubelabs Softwarelabor OG</a> © 2023
    </div>
    <div class="footer__text">
      Need help? <a class="footer__url footer__url-colored" href="mailto:info@danubelabs.at">Contact us</a> or check out
      the
      <a class="footer__url footer__url-colored" target="_blank" href="https://danubelabs.at/user-manual.html"
        >user manual</a
      >.
    </div>
  </mat-toolbar>
</div>
