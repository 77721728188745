import { VatService } from './../../_services/vat.service';
import { EncryptionService } from './../../_services/encryption.service';
import { FirestoreService } from 'src/app/_services/firestore.service';
import { ApiService } from './../../_services/api.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-new-key-dialog',
  templateUrl: './new-key-dialog.component.html',
  styleUrls: ['./new-key-dialog.component.scss'],
})
export class NewKeyDialogComponent implements OnInit {
  encryptionForm = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
  });

  spinnerEnabled = false;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private encryptionService: EncryptionService,
    private vatService: VatService,
    private dialogRef: MatDialogRef<any>,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  async setNewKey(): Promise<void> {
    this.spinnerEnabled = true;

    try {
      await this.apiService.deleteAllAlternatives(true);
      await this.apiService.deleteAllSessions(true);
      await this.encryptionService.setNewKey(this.encryptionForm.controls.password.value);

      this.vatService.resetImportedData();
      this._snackBar.open('New encryption password set', 'OK', {
        duration: 2000,
      });
      this.dialogRef.close();
    } catch (error) {
      console.error(error);
      this.spinnerEnabled = false;
      this._snackBar.open('Deleting old encrypted data failed', 'OK', {
        duration: 5000,
        panelClass: ['snackbar-warn'],
      });
    }
  }
}
