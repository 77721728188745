import { ISessionRow } from './../../_interfaces/session-row';
import { CompanyTableRow } from './../../_classes/company-table-row';
import { ApiResponse } from './../../_classes/api-response';
import { Address } from './../../_classes/address';
import { AuthService } from './../../_services/auth.service';
import { IAlternativeData } from './../../_interfaces/alternative-data';
import { IApiResponse } from './../../_interfaces/api-response';
import { ICompanyTableRow } from '../../_interfaces/company-table-row';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IUser } from 'src/app/_interfaces/user';
import { first, map, take, tap } from 'rxjs/operators';
import { IAlternativeDataDocument } from 'src/app/_interfaces/alternative-data-document';
import { FirestoreService } from 'src/app/_services/firestore.service';
import { Observable, Subscription } from 'rxjs';
import { Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-row-dialog',
  templateUrl: './row-dialog.component.html',
  styleUrls: ['./row-dialog.component.scss'],
})
export class RowDialogComponent implements OnInit {
  private subscriptions: Subscription[] = [];

  alternativeDataForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(50)]],
    addr1: ['', [Validators.maxLength(50)]],
    addr2: ['', [Validators.maxLength(50)]],
    addr3: ['', [Validators.maxLength(50)]],
    addr4: ['', [Validators.maxLength(50)]],
    addr5: ['', [Validators.maxLength(50)]],
    addr6: ['', [Validators.maxLength(50)]],
  });

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public row: ICompanyTableRow,
    private _snackBar: MatSnackBar,
    private firestoreService: FirestoreService,
    private fb: FormBuilder,
    private authService: AuthService
  ) {
    // set form fields for custom alternative data
    this.alternativeDataForm.controls.name.setValue(this.row.Name);
    this.alternativeDataForm.controls.addr1.setValue(this.row.address.Addr1);
    this.alternativeDataForm.controls.addr2.setValue(this.row.address.Addr2);
    this.alternativeDataForm.controls.addr3.setValue(this.row.address.Addr3);
    this.alternativeDataForm.controls.addr4.setValue(this.row.address.Addr4);
    this.alternativeDataForm.controls.addr5.setValue(this.row.address.Addr5);
    this.alternativeDataForm.controls.addr6.setValue(this.row.address.Addr6);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  addAlternativeData(useDataForm?: boolean): void {
    const alternativeDataToAdd: IAlternativeData = useDataForm
      ? {
          alternativeAddress: {
            Addr1: this.alternativeDataForm.controls.addr1.value,
            Addr2: this.alternativeDataForm.controls.addr2.value,
            Addr3: this.alternativeDataForm.controls.addr3.value,
            Addr4: this.alternativeDataForm.controls.addr4.value,
            Addr5: this.alternativeDataForm.controls.addr5.value,
            Addr6: this.alternativeDataForm.controls.addr6.value,
          },
          alternativeName: this.alternativeDataForm.controls.name.value,
          apiResponse: this.row.apiResponse,
        }
      : {
          alternativeAddress: this.row.address,
          alternativeName: this.row.Name,
          apiResponse: this.row.apiResponse,
        };

    if (this.alternativeDataExists(alternativeDataToAdd, this.row)) {
      this._snackBar.open('Alternative data already exists', 'OK', {
        duration: 5000,
        panelClass: ['snackbar-warn'],
      });
    } else {
      this.row.alternativeData.push(alternativeDataToAdd);
      this._snackBar.open('Added alternative data', 'OK', {
        duration: 2000,
      });
      this.authService.user$.pipe(take(1)).subscribe((user) => {
        CompanyTableRow.updateCompanyTableRow(
          this.row,
          user.preferences.alternativeDataCloudStorage,
          user.preferences.useMatchingPrediction,
          user.preferences.matchingThreshold
        );

        if (user?.preferences.alternativeDataCloudStorage) {
          this.addAlternativeDataToCloud(user, this.row.UID, alternativeDataToAdd);
        }
        if (user?.preferences.sessionsCloudStorage) {
          this.updateSessionRowToCloud(user, this.row);
        }
      });
    }
  }

  removeAlternativeData(data: IAlternativeData): void {
    this.row.alternativeData = this.row.alternativeData.filter((element) => element !== data);
    this._snackBar.open('Removed alternative data', 'OK', {
      duration: 2000,
    });
    this.authService.user$.pipe(take(1)).subscribe((user) => {
      CompanyTableRow.updateCompanyTableRow(
        this.row,
        user.preferences.alternativeDataCloudStorage,
        user.preferences.useMatchingPrediction,
        user.preferences.matchingThreshold
      );

      if (user?.preferences.alternativeDataCloudStorage) {
        this.removeAlternativeDataFromCloud(user, this.row.UID, data);
      }
      if (user?.preferences.sessionsCloudStorage) {
        this.updateSessionRowToCloud(user, this.row);
      }
    });
  }

  private alternativeDataExists(data: IAlternativeData, companyTableRow: ICompanyTableRow): boolean {
    for (let i = 0; i < companyTableRow.alternativeData.length; i++) {
      const element: IAlternativeData = companyTableRow.alternativeData[i];
      // check if alternative data already exists
      if (
        data.alternativeName === element.alternativeName &&
        Address.isEqual(data.alternativeAddress, element.alternativeAddress) &&
        ApiResponse.isEqual(data.apiResponse, element.apiResponse)
      ) {
        return true;
      }
    }
    return false;
  }

  acceptResponse(response: IApiResponse): void {
    if (response.address && response.name) {
      this.row.acceptedResponse = response;
      this.authService.user$.pipe(take(1)).subscribe((user) => {
        CompanyTableRow.updateCompanyTableRow(
          this.row,
          user.preferences.alternativeDataCloudStorage,
          user.preferences.useMatchingPrediction,
          user.preferences.matchingThreshold
        );

        if (user?.preferences.sessionsCloudStorage) {
          this.updateSessionRowToCloud(user, this.row);
        }
      });
      this._snackBar.open('Response accepted', 'OK', {
        duration: 2000,
      });
    }
  }

  removeResponse(): void {
    this.row.acceptedResponse = null;
    this.authService.user$.pipe(take(1)).subscribe((user) => {
      CompanyTableRow.updateCompanyTableRow(
        this.row,
        user.preferences.alternativeDataCloudStorage,
        user.preferences.useMatchingPrediction,
        user.preferences.matchingThreshold
      );

      if (user?.preferences.sessionsCloudStorage) {
        this.updateSessionRowToCloud(user, this.row);
      }
    });
    this._snackBar.open('Response removed', 'OK', {
      duration: 2000,
    });
  }

  isResponseAccepted(): boolean {
    if (this.row.acceptedResponse) {
      return true;
    } else {
      return false;
    }
  }

  private addAlternativeDataToCloud(user: IUser, companyUid: string, alternativeDataToAdd: IAlternativeData): void {
    this.firestoreService
      .queryAlternativeData(user.uid, user.preferences.encryptionEnabled)
      .pipe(take(1))
      .subscribe(
        async (docs) => {
          const filteredDocs = docs.filter((item) => item.companyUid === companyUid);

          const alternativeDataDocument: IAlternativeDataDocument = {
            docId: null,
            userUid: user.uid,
            companyUid,
            alternativeData: [],
          };
          if (filteredDocs.length > 0) {
            // doc already exists
            alternativeDataDocument.docId = filteredDocs[0].docId;
            alternativeDataDocument.alternativeData = [...filteredDocs[0].alternativeData, alternativeDataToAdd];

            try {
              await this.firestoreService.updateAlternativeData(
                alternativeDataDocument,
                user.preferences.encryptionEnabled
              );
            } catch (error) {
              console.error(error);
              this._snackBar.open('Failed to update alternative data cloud storage', 'OK', {
                duration: 5000,
                panelClass: ['snackbar-warn'],
              });
            }

            if (filteredDocs.length > 1) {
              console.error(
                'More than 1 alternativeDataDocument found for user ' +
                  user.uid +
                  ' ; company ' +
                  filteredDocs[0].companyUid
              );
            }
          } else {
            // doc does not exist yet, create first
            alternativeDataDocument.alternativeData = [alternativeDataToAdd];
            try {
              await this.firestoreService.createAlternativeData(
                alternativeDataDocument,
                user.preferences.encryptionEnabled
              );
            } catch (error) {
              console.error(error);
              this._snackBar.open('Failed to store alternative data to cloud', 'OK', {
                duration: 5000,
                panelClass: ['snackbar-warn'],
              });
            }
          }
        },
        (error) => {
          console.error(error);
          this._snackBar.open('Failed to receive alternative data documents from cloud storage', 'OK', {
            duration: 5000,
            panelClass: ['snackbar-warn'],
          });
        }
      );
  }
  private removeAlternativeDataFromCloud(
    user: IUser,
    companyUid: string,
    alternativeDataToRemove: IAlternativeData
  ): void {
    this.firestoreService
      .queryAlternativeData(user.uid, user.preferences.encryptionEnabled)
      .pipe(take(1))
      .subscribe(
        async (docs) => {
          const filteredDocs = docs.filter((item) => item.companyUid === companyUid);

          if (filteredDocs.length > 0) {
            // remove data from array
            filteredDocs[0].alternativeData = filteredDocs[0].alternativeData.filter(
              (data) =>
                !Address.isEqual(data.alternativeAddress, alternativeDataToRemove.alternativeAddress) ||
                !ApiResponse.isEqual(data.apiResponse, alternativeDataToRemove.apiResponse) ||
                data.alternativeName !== alternativeDataToRemove.alternativeName
            );
            try {
              if (filteredDocs[0].alternativeData.length > 0) {
                await this.firestoreService.updateAlternativeData(filteredDocs[0], user.preferences.encryptionEnabled);
              } else {
                await this.firestoreService.deleteAlternativeDataDocument(
                  filteredDocs[0],
                  user.preferences.encryptionEnabled
                );
              }
            } catch (error) {
              console.error(error);
              this._snackBar.open('Failed to update alternative data cloud storage', 'OK', {
                duration: 5000,
                panelClass: ['snackbar-warn'],
              });
            }

            if (filteredDocs.length > 1) {
              console.error(
                'More than 1 alternativeDataDocument found for user ' +
                  user.uid +
                  ' ; company ' +
                  filteredDocs[0].companyUid
              );
            }
          }
        },
        (error) => {
          console.error(error);
          this._snackBar.open('Failed to receive alternative data documents from cloud storage', 'OK', {
            duration: 5000,
            panelClass: ['snackbar-warn'],
          });
        }
      );
  }

  private async updateSessionRowToCloud(user: IUser, companyTableRow: ICompanyTableRow): Promise<void> {
    if (companyTableRow.sessionDocId && companyTableRow.sessionRowDocId) {
      const sessionRow: ISessionRow = {
        companyTableRow,
        docId: companyTableRow.sessionRowDocId,
        sessionDocId: companyTableRow.sessionDocId,
      };
      try {
        await this.firestoreService.updateSessionRow(user.uid, sessionRow, user.preferences.encryptionEnabled);
      } catch (error) {
        console.error(error);
        this._snackBar.open('Failed to update session to cloud storage', 'OK', {
          duration: 5000,
          panelClass: ['snackbar-warn'],
        });
      }
    }
  }
}
