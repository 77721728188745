export class Currency {
  private static curSymbols = new Map();

  public static getSymbolFor(iso: string): string {
    const res = this.curSymbols.get(iso.toUpperCase());
    return res ?? iso.toUpperCase;
  }

  // info retrieved from https://www.newbridgefx.com/currency-codes-symbols/
  static {
    Currency.curSymbols.set('AFN', '؋');
    Currency.curSymbols.set('ALL', 'Lek');
    Currency.curSymbols.set('DZD', 'د.ج');
    Currency.curSymbols.set('USD', '$');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('AOA', 'is');
    Currency.curSymbols.set('XCD', '$');
    Currency.curSymbols.set('XCD', '$');
    Currency.curSymbols.set('ARS', '$');
    Currency.curSymbols.set('AMD', 'Դ');
    Currency.curSymbols.set('AWG', 'ƒ');
    Currency.curSymbols.set('AUD', '$');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('AZN', '₼');
    Currency.curSymbols.set('BSD', '$');
    Currency.curSymbols.set('BHD', 'ب.د');
    Currency.curSymbols.set('BDT', '৳');
    Currency.curSymbols.set('BBD', '$');
    Currency.curSymbols.set('BYN', 'p.');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('BZD', '$');
    Currency.curSymbols.set('XOF', 'XOF');
    Currency.curSymbols.set('BMD', '$');
    Currency.curSymbols.set('BTN', 'BTN');
    Currency.curSymbols.set('BOB', 'Bs.');
    Currency.curSymbols.set('BOV', 'Mvdol');
    Currency.curSymbols.set('BES', '$');
    Currency.curSymbols.set('BAM', 'КМ');
    Currency.curSymbols.set('BWP', 'P');
    Currency.curSymbols.set('NOK', 'NOK');
    Currency.curSymbols.set('BRL', 'R$');
    Currency.curSymbols.set('USD', '$');
    Currency.curSymbols.set('BND', '$');
    Currency.curSymbols.set('BGN', 'лв');
    Currency.curSymbols.set('XOF', 'XOF');
    Currency.curSymbols.set('BIF', '₣');
    Currency.curSymbols.set('CVE', 'CVE');
    Currency.curSymbols.set('KHR', '៛');
    Currency.curSymbols.set('XAF', 'XAF');
    Currency.curSymbols.set('CAD', '$');
    Currency.curSymbols.set('KYD', '$');
    Currency.curSymbols.set('XAF', '₣');
    Currency.curSymbols.set('XAF', 'XAF');
    Currency.curSymbols.set('CLP', '$');
    Currency.curSymbols.set('CNY', '¥');
    Currency.curSymbols.set('AUD', '$');
    Currency.curSymbols.set('AUD', '$');
    Currency.curSymbols.set('COP', '$');
    Currency.curSymbols.set('KMF', 'KMF');
    Currency.curSymbols.set('CDF', '₣');
    Currency.curSymbols.set('NZD', '$');
    Currency.curSymbols.set('CRC', '₡');
    Currency.curSymbols.set('HRK', 'Kn');
    Currency.curSymbols.set('CUP', 'CUP');
    Currency.curSymbols.set('ANG', 'ANG');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('CZK', 'Kč');
    Currency.curSymbols.set('XOF', 'XOF');
    Currency.curSymbols.set('DKK', 'kr');
    Currency.curSymbols.set('DJF', '₣');
    Currency.curSymbols.set('XCD', 'XCD');
    Currency.curSymbols.set('DOP', '$');
    Currency.curSymbols.set('USD', '$');
    Currency.curSymbols.set('EGP', '£');
    Currency.curSymbols.set('USD', '$');
    Currency.curSymbols.set('XAF', 'XAF');
    Currency.curSymbols.set('ERN', 'Nfk');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('ETB', 'ETB');
    Currency.curSymbols.set('FKP', '£');
    Currency.curSymbols.set('DKK', 'DKK');
    Currency.curSymbols.set('FJD', '$');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('XPF', '₣');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('XAF', 'XAF');
    Currency.curSymbols.set('GMD', 'D');
    Currency.curSymbols.set('GEL', 'ლ');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('GHS', '₵');
    Currency.curSymbols.set('GIP', '£');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('DKK', 'DKK');
    Currency.curSymbols.set('XCD', '$');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('USD', '$');
    Currency.curSymbols.set('GTQ', 'Q');
    Currency.curSymbols.set('GBP', '£');
    Currency.curSymbols.set('GNF', '₣');
    Currency.curSymbols.set('XOF', 'XOF');
    Currency.curSymbols.set('GYD', '$');
    Currency.curSymbols.set('HTG', 'G');
    Currency.curSymbols.set('USD', '$');
    Currency.curSymbols.set('AUD', '$');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('HNL', 'L');
    Currency.curSymbols.set('HKD', '$');
    Currency.curSymbols.set('HUF', 'Ft');
    Currency.curSymbols.set('ISK', 'Kr');
    Currency.curSymbols.set('INR', '₨');
    Currency.curSymbols.set('IDR', 'Rp');
    Currency.curSymbols.set('IRR', '﷼');
    Currency.curSymbols.set('IQD', 'ع.د');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('GBP', '£');
    Currency.curSymbols.set('ILS', '₪');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('JMD', '$');
    Currency.curSymbols.set('JPY', '¥');
    Currency.curSymbols.set('GBP', '£');
    Currency.curSymbols.set('JOD', 'د.ا');
    Currency.curSymbols.set('KZT', '〒');
    Currency.curSymbols.set('KES', 'Sh');
    Currency.curSymbols.set('AUD', 'AUD');
    Currency.curSymbols.set('KPW', 'KPW');
    Currency.curSymbols.set('KRW', 'KRW');
    Currency.curSymbols.set('KWD', 'د.ك');
    Currency.curSymbols.set('KGS', 'KGS');
    Currency.curSymbols.set('LAK', 'LAK');
    Currency.curSymbols.set('EUR', 'EUR');
    Currency.curSymbols.set('LBP', 'ل.ل');
    Currency.curSymbols.set('LSL', 'L');
    Currency.curSymbols.set('ZAR', 'ZAR');
    Currency.curSymbols.set('LRD', '$');
    Currency.curSymbols.set('LYD', 'ل.د');
    Currency.curSymbols.set('CHF', 'CHF');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('MOP', 'P');
    Currency.curSymbols.set('MGA', 'MK');
    Currency.curSymbols.set('MWK', 'MWK');
    Currency.curSymbols.set('MYR', 'RM');
    Currency.curSymbols.set('MVR', 'ރ');
    Currency.curSymbols.set('XOF', 'XOF');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('USD', 'USD');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('MRU', 'UM');
    Currency.curSymbols.set('MUR', '₨');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('MXN', '$');
    Currency.curSymbols.set('MXV', 'MXV');
    Currency.curSymbols.set('USD', 'USD');
    Currency.curSymbols.set('MDL', 'L');
    Currency.curSymbols.set('EUR', 'EUR');
    Currency.curSymbols.set('MNT', '₮');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('XCD', '$');
    Currency.curSymbols.set('MAD', 'د.م.');
    Currency.curSymbols.set('MZN', 'MTn');
    Currency.curSymbols.set('MMK', 'K');
    Currency.curSymbols.set('NAD', '$');
    Currency.curSymbols.set('ZAR', 'ZAR');
    Currency.curSymbols.set('AUD', 'AUD');
    Currency.curSymbols.set('NPR', '₨');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('XPF', '₣');
    Currency.curSymbols.set('NZD', '$');
    Currency.curSymbols.set('NIO', 'C$');
    Currency.curSymbols.set('XOF', 'XOF');
    Currency.curSymbols.set('NGN', '₦');
    Currency.curSymbols.set('NZD', 'NZD');
    Currency.curSymbols.set('AUD', 'AUD');
    Currency.curSymbols.set('USD', 'USD');
    Currency.curSymbols.set('NOK', 'kr');
    Currency.curSymbols.set('OMR', 'OMR');
    Currency.curSymbols.set('PKR', '₨');
    Currency.curSymbols.set('USD', 'USD');
    Currency.curSymbols.set('PAB', 'B/.');
    Currency.curSymbols.set('USD', 'USD');
    Currency.curSymbols.set('PGK', 'K');
    Currency.curSymbols.set('PYG', '₲');
    Currency.curSymbols.set('PEN', 'S/.');
    Currency.curSymbols.set('PHP', '₱');
    Currency.curSymbols.set('NZD', 'NZD');
    Currency.curSymbols.set('PLN', 'zł');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('USD', 'USD');
    Currency.curSymbols.set('QAR', 'ر.ق');
    Currency.curSymbols.set('MKD', 'MKD');
    Currency.curSymbols.set('RON', 'L');
    Currency.curSymbols.set('RUB', 'RUB');
    Currency.curSymbols.set('RWF', '₣');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('SHP', '£');
    Currency.curSymbols.set('XCD', '$');
    Currency.curSymbols.set('XCD', '$');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('XCD', '$');
    Currency.curSymbols.set('WST', 'WST');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('STN', 'Db');
    Currency.curSymbols.set('SAR', 'ر.س');
    Currency.curSymbols.set('XOF', 'XOF');
    Currency.curSymbols.set('RSD', 'din');
    Currency.curSymbols.set('SCR', 'SCR');
    Currency.curSymbols.set('SLL', 'Le');
    Currency.curSymbols.set('SGD', 'SGD');
    Currency.curSymbols.set('ANG', 'ANG');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('SBD', '$');
    Currency.curSymbols.set('SOS', 'Sh');
    Currency.curSymbols.set('ZAR', 'R');
    Currency.curSymbols.set('SSP', 'SSP');
    Currency.curSymbols.set('EUR', '€');
    Currency.curSymbols.set('LKR', 'Rs');
    Currency.curSymbols.set('SDG', '£');
    Currency.curSymbols.set('SRD', '$');
    Currency.curSymbols.set('NOK', 'NOK');
    Currency.curSymbols.set('SZL', 'L');
    Currency.curSymbols.set('SEK', 'kr');
    Currency.curSymbols.set('CHF', '₣');
    Currency.curSymbols.set('SYP', 'SYP');
    Currency.curSymbols.set('TWD', 'NT$');
    Currency.curSymbols.set('TJS', 'ЅМ');
    Currency.curSymbols.set('TZS', 'Sh');
    Currency.curSymbols.set('THB', '฿');
    Currency.curSymbols.set('USD', 'USD');
    Currency.curSymbols.set('XOF', 'XOF');
    Currency.curSymbols.set('NZD', 'NZD');
    Currency.curSymbols.set('TOP', 'T$');
    Currency.curSymbols.set('TTD', '$');
    Currency.curSymbols.set('TND', 'د.ت');
    Currency.curSymbols.set('TRY', 'TRY');
    Currency.curSymbols.set('TMT', 'm');
    Currency.curSymbols.set('USD', 'USD');
    Currency.curSymbols.set('AUD', 'AUD');
    Currency.curSymbols.set('UGX', 'Sh');
    Currency.curSymbols.set('UAH', '₴');
    Currency.curSymbols.set('AED', 'AED');
    Currency.curSymbols.set('GBP', 'GBP');
    Currency.curSymbols.set('USD', '$');
    Currency.curSymbols.set('USD', '$');
    Currency.curSymbols.set('UYU', '$');
    Currency.curSymbols.set('UZS', 'UZS');
    Currency.curSymbols.set('VUV', 'Vt');
    Currency.curSymbols.set('VEF', 'VEF');
    Currency.curSymbols.set('VND', '₫');
    Currency.curSymbols.set('USD', 'USD');
    Currency.curSymbols.set('USD', 'USD');
    Currency.curSymbols.set('XPF', 'XPF');
    Currency.curSymbols.set('MAD', 'MAD');
    Currency.curSymbols.set('YER', '﷼');
    Currency.curSymbols.set('ZMW', 'ZK');
    Currency.curSymbols.set('ZWL', '$');
    Currency.curSymbols.set('EUR', '€');
  }
}
