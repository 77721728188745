import { ITemplateDialogInputData } from './../../_interfaces/template-dialog-input-data';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { PropertyName } from 'src/app/_enums/property-name.enum';
import { IImportTemplate } from 'src/app/_interfaces/import-template';
import { AuthService } from 'src/app/_services/auth.service';
import { FirestoreService } from 'src/app/_services/firestore.service';
import { take } from 'rxjs/operators';
import { Uid } from 'src/app/_classes/uid';

@Component({
  selector: 'app-template-dialog',
  templateUrl: './template-dialog.component.html',
  styleUrls: ['./template-dialog.component.scss'],
})
export class TemplateDialogComponent implements OnInit {
  templates$: Observable<IImportTemplate[]>;

  constructor(
    private authService: AuthService,
    private firestoreService: FirestoreService,
    @Inject(MAT_DIALOG_DATA) public dialogInputData: ITemplateDialogInputData,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<any>
  ) {}

  ngOnInit(): void {
    this.authService.user$.pipe(take(1)).subscribe((user) => {
      if (user) {
        if (this.dialogInputData.showAlternativeDataTemplates) {
          this.templates$ = this.firestoreService.queryAltImportTemplates(user.uid);
        }
        else { this.templates$ = this.firestoreService.queryImportTemplates(user.uid); }
      }
    });
  }

  selectTemplate(template: IImportTemplate): void {
    // check if any template-index would be out of bounds
    for (let i = 0; i < template.columnIndices.length; i++) {
      const columnIndex = template.columnIndices[i];
      if (columnIndex > this.dialogInputData.importedHeaders.length - 1) {
        this._snackBar.open('Template is not applicable to imported data', 'OK', {
          duration: 5000,
          panelClass: ['snackbar-warn'],
        });
        return;
      }
    }
    const invalidUids = this.dialogInputData.importedData.filter(
      (row) => !Uid.validUidFormat(row[template.columnIndices[PropertyName.UID]])
    );
    if (invalidUids.length > 0) {
      this._snackBar.open('Sheet contains invalid VAT numbers', 'OK', {
        duration: 5000,
        panelClass: ['snackbar-warn'],
      });
      return;
    }

    this.dialogRef.close(template.columnIndices);
  }

  deleteTemplate(template: IImportTemplate): void {
    this.authService.user$.pipe(take(1)).subscribe(async (user) => {
      if (user) {
        try {
          if (!this.dialogInputData.showAlternativeDataTemplates) {
            await this.firestoreService.deleteImportTemplate(user.uid, template.docId);
          }
          else { await this.firestoreService.deleteAltImportTemplate(user.uid, template.docId); }
          this._snackBar.open('Template deleted', 'OK', {
            duration: 2000,
          });
        } catch (error) {
          console.error(error);
          this._snackBar.open('Failed to delete import template from cloud storage', 'OK', {
            duration: 5000,
            panelClass: ['snackbar-warn'],
          });
        }
      }
    });
  }
}
