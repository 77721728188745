<mat-horizontal-stepper class="dialog__stepper" #stepper linear>
  <mat-step class="dialog__step" completed="false">
    <ng-template matStepLabel>VAT No.</ng-template>

    <h2>Select VAT Number Column</h2>
    <div class="dialog__overflow">
      <div class="dialog__table-container">
        <div class="mat-elevation-z8">
          <table class="n-bordered dialog__table-data">
            <tr>
              <ng-container *ngFor="let column of dialogInputData.importedHeaders; let i = index">
                <td
                  (click)="selectCell(propertyName.UID, i)"
                  *ngIf="columnIndices[0] == i; else notSelectedHeader"
                  class="dialog__table-data-content table-header-cell table-header-cell--selected"
                >
                  <b>{{ column }}</b>
                </td>
                <ng-template #notSelectedHeader>
                  <td (click)="selectCell(propertyName.UID, i)" class="table-header-cell dialog__table-data-content">
                    <b>{{ column }}</b>
                  </td>
                </ng-template>
              </ng-container>
            </tr>
            <tr *ngFor="let dataRow of dialogInputData.importedData | slice: 0:5">
              <td class="dialog__table-data-content" *ngFor="let dataColumn of dataRow">
                {{ dataColumn }}
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf="dialogInputData.importedData.length >= 5" class="gradient"></div>
      </div>
    </div>
    <div class="dialog__action-bottom">
      <button matStepperPrevious disabled mat-button color="primary">
        <mat-icon aria-hidden="false">west</mat-icon>
      </button>
      <button disabled mat-raised-button (click)="resetStepper()">Reset</button>
      <button matStepperNext [disabled]="nextButtonDisabled(propertyName.UID)" mat-button color="primary">
        <mat-icon aria-hidden="false">east</mat-icon>
      </button>
    </div>
  </mat-step>

  <mat-step completed="false">
    <ng-template matStepLabel>Name</ng-template>

    <h2>Select Name Column</h2>
    <div class="dialog__overflow">
      <div class="dialog__table-container">
        <div class="mat-elevation-z8">
          <table class="n-bordered dialog__table-data">
            <tr>
              <ng-container *ngFor="let column of dialogInputData.importedHeaders; let i = index">
                <td
                  (click)="selectCell(propertyName.Name, i)"
                  *ngIf="columnIndices[1] == i; else notSelectedHeader"
                  class="table-header-cell table-header-cell--selected dialog__table-data-content"
                >
                  <b>{{ column }}</b>
                </td>
                <ng-template #notSelectedHeader>
                  <td (click)="selectCell(propertyName.Name, i)" class="table-header-cell dialog__table-data-content">
                    <b>{{ column }}</b>
                  </td>
                </ng-template>
              </ng-container>
            </tr>
            <tr *ngFor="let dataRow of dialogInputData.importedData | slice: 0:5">
              <td class="dialog__table-data-content" *ngFor="let dataColumn of dataRow">
                {{ dataColumn }}
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf="dialogInputData.importedData.length >= 5" class="gradient"></div>
      </div>
    </div>
    <div class="dialog__action-bottom">
      <button matStepperPrevious mat-button color="primary">
        <mat-icon aria-hidden="false">west</mat-icon>
      </button>
      <button mat-raised-button (click)="resetStepper()">Reset</button>
      <button matStepperNext [disabled]="nextButtonDisabled(propertyName.Name)" mat-button color="primary">
        <mat-icon aria-hidden="false">east</mat-icon>
      </button>
    </div>
  </mat-step>

  <mat-step completed="false">
    <ng-template matStepLabel>Addr1</ng-template>
    <h2>Select Addr1 Column</h2>
    <div class="dialog__overflow">
      <div class="dialog__table-container">
        <div class="mat-elevation-z8">
          <table class="n-bordered dialog__table-data">
            <tr>
              <ng-container *ngFor="let column of dialogInputData.importedHeaders; let i = index">
                <td
                  (click)="selectCell(propertyName.Addr1, i)"
                  *ngIf="columnIndices[2] == i; else notSelectedHeader"
                  class="dialog__table-data-content table-header-cell table-header-cell--selected"
                >
                  <b>{{ column }}</b>
                </td>
                <ng-template #notSelectedHeader>
                  <td (click)="selectCell(propertyName.Addr1, i)" class="table-header-cell dialog__table-data-content">
                    <b>{{ column }}</b>
                  </td>
                </ng-template>
              </ng-container>
            </tr>
            <tr *ngFor="let dataRow of dialogInputData.importedData | slice: 0:5">
              <td class="dialog__table-data-content" *ngFor="let dataColumn of dataRow">
                {{ dataColumn }}
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf="dialogInputData.importedData.length >= 5" class="gradient"></div>
      </div>
    </div>
    <div class="dialog__action-bottom">
      <button matStepperPrevious mat-button color="primary">
        <mat-icon aria-hidden="false">west</mat-icon>
      </button>
      <button mat-raised-button (click)="resetStepper()">Reset</button>
      <button matStepperNext [disabled]="nextButtonDisabled(propertyName.Addr1)" mat-button color="primary">
        <mat-icon aria-hidden="false">east</mat-icon>
      </button>
    </div>
  </mat-step>

  <mat-step completed="false">
    <ng-template matStepLabel>Addr2</ng-template>
    <h2>Select Addr2 Column</h2>
    <div class="dialog__overflow">
      <div class="dialog__table-container">
        <div class="mat-elevation-z8">
          <table class="n-bordered dialog__table-data">
            <tr>
              <ng-container *ngFor="let column of dialogInputData.importedHeaders; let i = index">
                <td
                  (click)="selectCell(propertyName.Addr2, i)"
                  *ngIf="columnIndices[3] == i; else notSelectedHeader"
                  class="table-header-cell table-header-cell--selected dialog__table-data-content"
                >
                  <b>{{ column }}</b>
                </td>
                <ng-template #notSelectedHeader>
                  <td (click)="selectCell(propertyName.Addr2, i)" class="table-header-cell dialog__table-data-content">
                    <b>{{ column }}</b>
                  </td>
                </ng-template>
              </ng-container>
            </tr>
            <tr *ngFor="let dataRow of dialogInputData.importedData | slice: 0:5">
              <td class="dialog__table-data-content" *ngFor="let dataColumn of dataRow">
                {{ dataColumn }}
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf="dialogInputData.importedData.length >= 5" class="gradient"></div>
      </div>
    </div>
    <div class="dialog__action-bottom">
      <button matStepperPrevious mat-button color="primary">
        <mat-icon aria-hidden="false">west</mat-icon>
      </button>
      <button mat-raised-button (click)="resetStepper()">Reset</button>
      <button matStepperNext [disabled]="nextButtonDisabled(propertyName.Addr2)" mat-button color="primary">
        <mat-icon aria-hidden="false">east</mat-icon>
      </button>
    </div>
  </mat-step>

  <mat-step completed="false">
    <ng-template matStepLabel>Addr3</ng-template>
    <h2>Select Addr3 Column</h2>
    <div class="dialog__overflow">
      <div class="dialog__table-container">
        <div class="mat-elevation-z8">
          <table class="n-bordered dialog__table-data">
            <tr>
              <ng-container *ngFor="let column of dialogInputData.importedHeaders; let i = index">
                <td
                  (click)="selectCell(propertyName.Addr3, i)"
                  *ngIf="columnIndices[4] == i; else notSelectedHeader"
                  class="table-header-cell table-header-cell--selected dialog__table-data-content"
                >
                  <b>{{ column }}</b>
                </td>
                <ng-template #notSelectedHeader>
                  <td (click)="selectCell(propertyName.Addr3, i)" class="table-header-cell dialog__table-data-content">
                    <b>{{ column }}</b>
                  </td>
                </ng-template>
              </ng-container>
            </tr>
            <tr *ngFor="let dataRow of dialogInputData.importedData | slice: 0:5">
              <td class="dialog__table-data-content" *ngFor="let dataColumn of dataRow">
                {{ dataColumn }}
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf="dialogInputData.importedData.length >= 5" class="gradient"></div>
      </div>
    </div>
    <div class="dialog__action-bottom">
      <button matStepperPrevious mat-button color="primary">
        <mat-icon aria-hidden="false">west</mat-icon>
      </button>
      <button mat-raised-button (click)="resetStepper()">Reset</button>
      <button matStepperNext [disabled]="nextButtonDisabled(propertyName.Addr3)" mat-button color="primary">
        <mat-icon aria-hidden="false">east</mat-icon>
      </button>
    </div>
  </mat-step>

  <mat-step completed="false">
    <ng-template matStepLabel>Addr4</ng-template>
    <h2>Select Addr4 Column</h2>
    <div class="dialog__overflow">
      <div class="dialog__table-container">
        <div class="mat-elevation-z8">
          <table class="n-bordered dialog__table-data">
            <tr>
              <ng-container *ngFor="let column of dialogInputData.importedHeaders; let i = index">
                <td
                  (click)="selectCell(propertyName.Addr4, i)"
                  *ngIf="columnIndices[5] == i; else notSelectedHeader"
                  class="table-header-cell table-header-cell--selected dialog__table-data-content"
                >
                  <b>{{ column }}</b>
                </td>
                <ng-template #notSelectedHeader>
                  <td (click)="selectCell(propertyName.Addr4, i)" class="table-header-cell dialog__table-data-content">
                    <b>{{ column }}</b>
                  </td>
                </ng-template>
              </ng-container>
            </tr>
            <tr *ngFor="let dataRow of dialogInputData.importedData | slice: 0:5">
              <td class="dialog__table-data-content" *ngFor="let dataColumn of dataRow">
                {{ dataColumn }}
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf="dialogInputData.importedData.length >= 5" class="gradient"></div>
      </div>
    </div>
    <div class="dialog__action-bottom">
      <button matStepperPrevious mat-button color="primary">
        <mat-icon aria-hidden="false">west</mat-icon>
      </button>
      <button mat-raised-button (click)="resetStepper()">Reset</button>
      <button matStepperNext [disabled]="nextButtonDisabled(propertyName.Addr4)" mat-button color="primary">
        <mat-icon aria-hidden="false">east</mat-icon>
      </button>
    </div>
  </mat-step>

  <mat-step completed="false">
    <ng-template matStepLabel>Addr5</ng-template>
    <h2>Select Addr5 Column</h2>
    <div class="dialog__overflow">
      <div class="dialog__table-container">
        <div class="mat-elevation-z8">
          <table class="n-bordered dialog__table-data">
            <tr>
              <ng-container *ngFor="let column of dialogInputData.importedHeaders; let i = index">
                <td
                  (click)="selectCell(propertyName.Addr5, i)"
                  *ngIf="columnIndices[6] == i; else notSelectedHeader"
                  class="table-header-cell table-header-cell--selected dialog__table-data-content"
                >
                  <b>{{ column }}</b>
                </td>
                <ng-template #notSelectedHeader>
                  <td (click)="selectCell(propertyName.Addr5, i)" class="table-header-cell dialog__table-data-content">
                    <b>{{ column }}</b>
                  </td>
                </ng-template>
              </ng-container>
            </tr>
            <tr *ngFor="let dataRow of dialogInputData.importedData | slice: 0:5">
              <td class="dialog__table-data-content" *ngFor="let dataColumn of dataRow">
                {{ dataColumn }}
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf="dialogInputData.importedData.length >= 5" class="gradient"></div>
      </div>
    </div>
    <div class="dialog__action-bottom">
      <button matStepperPrevious mat-button color="primary">
        <mat-icon aria-hidden="false">west</mat-icon>
      </button>
      <button mat-raised-button (click)="resetStepper()">Reset</button>
      <button matStepperNext [disabled]="nextButtonDisabled(propertyName.Addr5)" mat-button color="primary">
        <mat-icon aria-hidden="false">east</mat-icon>
      </button>
    </div>
  </mat-step>

  <mat-step completed="false">
    <ng-template matStepLabel>Addr6</ng-template>
    <h2>Select Addr6 Column</h2>
    <div class="dialog__overflow">
      <div class="dialog__table-container">
        <div class="mat-elevation-z8">
          <table class="n-bordered dialog__table-data">
            <tr>
              <ng-container *ngFor="let column of dialogInputData.importedHeaders; let i = index">
                <td
                  (click)="selectCell(propertyName.Addr6, i)"
                  *ngIf="columnIndices[7] == i; else notSelectedHeader"
                  class="table-header-cell table-header-cell--selected dialog__table-data-content"
                >
                  <b>{{ column }}</b>
                </td>
                <ng-template #notSelectedHeader>
                  <td (click)="selectCell(propertyName.Addr6, i)" class="table-header-cell dialog__table-data-content">
                    <b>{{ column }}</b>
                  </td>
                </ng-template>
              </ng-container>
            </tr>
            <tr *ngFor="let dataRow of dialogInputData.importedData | slice: 0:5">
              <td class="dialog__table-data-content" *ngFor="let dataColumn of dataRow">
                {{ dataColumn }}
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf="dialogInputData.importedData.length >= 5" class="gradient"></div>
      </div>
    </div>
    <div class="dialog__action-bottom">
      <button matStepperPrevious mat-button color="primary">
        <mat-icon aria-hidden="false">west</mat-icon>
      </button>
      <button mat-raised-button (click)="resetStepper()">Reset</button>
      <button
        mat-raised-button
        [disabled]="nextButtonDisabled(propertyName.Addr6)"
        color="primary"
        (click)="dialogRef.close(this.columnIndices)"
      >
        Finish
      </button>
    </div>
  </mat-step>
</mat-horizontal-stepper>
