import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private afAuth: AngularFireAuth) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return zip(this.authService.user$, this.afAuth.authState).pipe(
      map((userArray) => {
        const user = userArray[0];
        const authState = userArray[1];

        if (!authState) {
          this.router.navigate(['login']);
        } else if (user) {
          if (!user?.firstTimeLoginCompleted && next.routeConfig.path !== 'create-profile') {
            this.router.navigate(['create-profile']);
          } else if (user?.firstTimeLoginCompleted && next.routeConfig.path === 'create-profile') {
            this.router.navigate(['']);
          }
        }
        return !!authState;
      })
    );
  }
}
