import { IFinishedDialogInput } from './../../../_interfaces/ifinished-dialog-input';
import { VatService } from './../../../_services/vat.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-finished-dialog',
  templateUrl: './finished-dialog.component.html',
  styleUrls: ['./finished-dialog.component.scss'],
})
export class FinishedDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public inputData: IFinishedDialogInput,
    public vatService: VatService
  ) {}

  ngOnInit(): void {}
}
