import { Address } from './address';
import { IAddress } from '../_interfaces/address';
import { IApiResponse } from '../_interfaces/api-response';

export class ApiResponse implements IApiResponse {
  address?: IAddress;
  countryCode: string;
  name?: string;
  requestDate: string;
  valid: boolean;
  vatNumber: string;

  public static isEqual(a1: ApiResponse, a2: ApiResponse): boolean {
    // compare name and address
    if (a1.name && a2.name) {
      if (a1.address && a2.address) {
        if (a1.name === a2.name && Address.isEqual(a1.address, a2.address)) {
          return true;
        }
      } else if (!a1.address && !a2.address && a1.name === a2.name) {
        return true;
      }
    } else if (!a1.name && !a2.name) {
      if (a1.address && a2.address) {
        if (Address.isEqual(a1.address, a2.address)) {
          return true;
        }
      } else if (!a1.address && !a2.address) {
        return true;
      }
    }
    return false;
  }
}
