'use strict';

export const CONCURRENT_REQUESTS = 1;

// keep in sync with BE
export const FREE_CHECKS = 2;
export const INCLUDED_BASIC_CHECKS = 30;
export const INCLUDED_PRO_CHECKS = 400;
export const ADDITIONAL_BASIC_CHECK_COST = 0.5;
export const ADDITIONAL_PRO_CHECK_COST = 0.15;
export const BASIC_PRICE = 29.9;
export const PRO_PRICE = 59.9;

export const MAX_CLOUD_STORAGE_FILE_LENGTH = 5000;

export const GEOLOCATION_SERVICE = 'https://geolocation-db.com/json/';
export const EU_COUNTRIES: string[] = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PO',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
];
