<div class="container">
  <div class="container-card">
    <mat-card class="password-reset__card">
      <h1>Reset your password</h1>
      <div class="container-actions">
        <form [formGroup]="resetForm" (ngSubmit)="sendPasswordReset()">
          <mat-form-field class="password-reset__input">
            <mat-label>Email</mat-label>
            <input
              autocomplete="email"
              formControlName="email"
              name="email"
              type="email"
              matInput
              placeholder="john.doe@gmail.com"
            />
          </mat-form-field>
          <br />
          <button
            [disabled]="!resetButtonEnabled || !resetForm.valid"
            class="button-action"
            mat-raised-button
            color="primary"
            type="submit"
          >
            Request a link to set a new password
          </button>
        </form>
      </div>
    </mat-card>
  </div>
</div>
