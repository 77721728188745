import { IAddress } from './../_interfaces/address';
export class Address implements IAddress {
  Addr1: string;
  Addr2: string;
  Addr3: string;
  Addr4: string;
  Addr5: string;
  Addr6: string;

  public static isEqual(a1: Address, a2: Address): boolean {
    if (
      a1.Addr1 === a2.Addr1 &&
      a1.Addr2 === a2.Addr2 &&
      a1.Addr3 === a2.Addr3 &&
      a1.Addr4 === a2.Addr4 &&
      a1.Addr5 === a2.Addr5 &&
      a1.Addr6 === a2.Addr6
    ) {
      return true;
    }
    return false;
  }
}
