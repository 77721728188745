import { ISessionRow } from './../_interfaces/session-row';
import { IEncryptedSessionRow } from './../_interfaces/iencrypted-session-row';
import { IEncryptedAltData } from './../_interfaces/iencrypted-alt-data';
import { EncryptionService } from './encryption.service';
import { ApiService } from './api.service';
import { IMonthlyUsage } from '../_interfaces/imonthly-usage';
import { ISession } from 'src/app/_interfaces/session';
import { ICheckoutSession } from './../_interfaces/checkout-session';
import { IAlternativeDataDocument } from '../_interfaces/alternative-data-document';
import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IImportTemplate } from '../_interfaces/import-template';
import firebase from 'firebase/compat/app';
import { IStripeSubscription } from '../_interfaces/stripe-subscription';
import { IDataboxEntry } from '../_interfaces/idatabox-entry';
import { increment } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  constructor(
    private db: AngularFirestore,
    private apiService: ApiService,
    private encryptionService: EncryptionService
  ) {}

  async deleteUser(userUid: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.getCurrentSubscriptions(userUid)
        .pipe(take(1))
        .subscribe(
          async (subscriptions) => {
            const subscriptionIds: string[] = [];

            subscriptions.forEach((subscription) => {
              if (!subscription.ended_at) {
                subscription.items.forEach((item) => {
                  if (!subscriptionIds.includes(item.subscription)) {
                    subscriptionIds.push(item.subscription);
                  }
                });
              }
            });

            try {
              if (subscriptionIds.length > 0) {
                await this.apiService.cancelSubscriptions(subscriptionIds);
              }
              await firebase.auth().currentUser.delete();
              resolve();
            } catch (error) {
              reject(error);
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getCurrentSubscriptions(userUid: string): Observable<IStripeSubscription[]> {
    return this.db.collection('users').doc(userUid).collection<IStripeSubscription>('subscriptions').valueChanges();
  }

  updateAlternativeData(alternativeDataDocument: IAlternativeDataDocument, encryptionEnabled: boolean): Promise<void> {
    const data = encryptionEnabled
      ? this.encryptionService.encryptAlternativeDataDocument(alternativeDataDocument)
      : alternativeDataDocument;
    const path = encryptionEnabled ? 'encrypted_alternatives' : 'alternatives';

    return this.db.collection('users').doc(data.userUid).collection(path).doc(data.docId).set(data, { merge: true });
  }

  deleteAlternativeDataDocument(
    alternativeDataDocument: IAlternativeDataDocument,
    encryptionEnabled: boolean
  ): Promise<void> {
    const path = encryptionEnabled ? 'encrypted_alternatives' : 'alternatives';

    return this.db
      .collection('users')
      .doc(alternativeDataDocument.userUid)
      .collection(path)
      .doc(alternativeDataDocument.docId)
      .delete();
  }

  createAlternativeData(alternativeDataDocument: IAlternativeDataDocument, encryptionEnabled: boolean): Promise<void> {
    alternativeDataDocument.docId = this.db.createId();
    const data = encryptionEnabled
      ? this.encryptionService.encryptAlternativeDataDocument(alternativeDataDocument)
      : alternativeDataDocument;
    const path = encryptionEnabled ? 'encrypted_alternatives' : 'alternatives';

    return this.db.collection('users').doc(data.userUid).collection(path).doc(data.docId).set(data, { merge: true });
  }

  queryAlternativeData(userUid: string, encryptionEnabled: boolean): Observable<IAlternativeDataDocument[]> {
    if (encryptionEnabled) {
      return this.db
        .collection('users')
        .doc(userUid)
        .collection<IEncryptedAltData>('encrypted_alternatives')
        .valueChanges()
        .pipe(
          map((docs) => {
            const decrypted: IAlternativeDataDocument[] = docs.map((doc) =>
              this.encryptionService.decryptAlternativeDataDocument(doc)
            );
            return decrypted;
          })
        );
    } else {
      return this.db
        .collection('users')
        .doc(userUid)
        .collection<IAlternativeDataDocument>('alternatives')
        .valueChanges();
    }
  }

  createSession(session: ISession, encryptionEnabled: boolean): Promise<string> {
    const path = encryptionEnabled ? 'encrypted_sessions' : 'sessions';

    // return docId of session after document was created
    return new Promise<string>((resolve, reject) => {
      session.docId = this.db.createId();

      this.db
        .collection('users')
        .doc(session.userUid)
        .collection(path)
        .doc(session.docId)
        .set(session, { merge: true })
        .then(() => {
          resolve(session.docId);
        })
        .catch((error) => reject(error));
    });
  }

  querySessions(userUid: string, encryptionEnabled: boolean): Observable<ISession[]> {
    const path = encryptionEnabled ? 'encrypted_sessions' : 'sessions';

    return this.db.collection('users').doc(userUid).collection<ISession>(path).valueChanges();
  }

  getSession(userUid: string, sessionDocId: string, encryptionEnabled: boolean): Observable<ISession> {
    const path = encryptionEnabled ? 'encrypted_sessions' : 'sessions';

    return this.db.collection('users').doc(userUid).collection(path).doc<ISession>(sessionDocId).valueChanges();
  }

  deleteSession(userUid: string, sessionDocId: string, encryptionEnabled: boolean): Promise<void> {
    // only delete subcollection; delete session via FE to provide responsive UI
    this.apiService.deleteSessionSubcollection(sessionDocId, encryptionEnabled);

    const path = encryptionEnabled ? 'encrypted_sessions' : 'sessions';
    return this.db.collection('users').doc(userUid).collection(path).doc(sessionDocId).delete();
  }

  updateSessionRow(userUid: string, sessionRow: ISessionRow, encryptionEnabled: boolean): Promise<void> {
    const data = encryptionEnabled ? this.encryptionService.encryptSessionRow(sessionRow) : sessionRow;
    const prePath = encryptionEnabled ? 'encrypted_' : '';

    return this.db
      .collection('users')
      .doc(userUid)
      .collection(prePath + 'sessions')
      .doc(data.sessionDocId)
      .collection(prePath + 'session_rows')
      .doc(data.docId)
      .set(data, { merge: true });
  }

  querySessionRows(userUid: string, sessionDocId: string, encryptionEnabled: boolean): Observable<ISessionRow[]> {
    if (encryptionEnabled) {
      return this.db
        .collection('users')
        .doc(userUid)
        .collection('encrypted_sessions')
        .doc(sessionDocId)
        .collection<IEncryptedSessionRow>('encrypted_session_rows', (ref) =>
          ref.where('sessionDocId', '==', sessionDocId)
        )
        .valueChanges()
        .pipe(
          map((docs) => {
            const decrypted: ISessionRow[] = docs.map((doc) => this.encryptionService.decryptSessionRow(doc));
            return decrypted;
          })
        );
    } else {
      return this.db
        .collection('users')
        .doc(userUid)
        .collection('sessions')
        .doc(sessionDocId)
        .collection<ISessionRow>('session_rows', (ref) => ref.where('sessionDocId', '==', sessionDocId))
        .valueChanges();
    }
  }

  createImportTemplate(template: IImportTemplate): Promise<void> {
    template.docId = this.db.createId();
    return this.db
      .collection('users')
      .doc(template.userUid)
      .collection('import_templates')
      .doc(template.docId)
      .set(template, { merge: true });
  }

  queryImportTemplates(userUid: string): Observable<IImportTemplate[]> {
    return this.db.collection('users').doc(userUid).collection<IImportTemplate>('import_templates').valueChanges();
  }

  deleteAltImportTemplate(userUid: string, templateDocId: string): Promise<void> {
    return this.db.collection('users').doc(userUid).collection('import_templates_alt').doc(templateDocId).delete();
  }

  createAltImportTemplate(template: IImportTemplate): Promise<void> {
    template.docId = this.db.createId();
    return this.db
      .collection('users')
      .doc(template.userUid)
      .collection('import_templates_alt')
      .doc(template.docId)
      .set(template, { merge: true });
  }

  queryAltImportTemplates(userUid: string): Observable<IImportTemplate[]> {
    return this.db.collection('users').doc(userUid).collection<IImportTemplate>('import_templates_alt').valueChanges();
  }

  deleteImportTemplate(userUid: string, templateDocId: string): Promise<void> {
    return this.db.collection('users').doc(userUid).collection('import_templates').doc(templateDocId).delete();
  }

  createCheckoutSession(checkoutSession: ICheckoutSession, userUid): Promise<DocumentReference> {
    return this.db.collection('users').doc(userUid).collection('checkout_sessions').add(checkoutSession);
  }

  updateUsageDoc(usage: IMonthlyUsage): Promise<void> {
    return this.db
      .collection('users')
      .doc(usage.userUid)
      .collection('usage')
      .doc(usage.docId)
      .set(usage, { merge: true });
  }

  incrementUsageCounter(usage: IMonthlyUsage, incrementAmount: number): Promise<void> {
    return this.db
      .collection('users')
      .doc(usage.userUid)
      .collection('usage')
      .doc(usage.docId)
      .update({ usageCounter: increment(incrementAmount) });
  }

  queryUsageDoc(currentPeriodStart: Date, currentPeriodEnd: Date, userUid: string): Observable<IMonthlyUsage[]> {
    return this.db
      .collection('users')
      .doc(userUid)
      .collection<IMonthlyUsage>('usage', (ref) =>
        ref.where('currentPeriodStart', '==', currentPeriodStart).where('currentPeriodEnd', '==', currentPeriodEnd)
      )
      .valueChanges();
  }

  getUsageDoc(userUid: string, docId: string): Observable<IMonthlyUsage> {
    return this.db.collection('users').doc(userUid).collection<IMonthlyUsage>('usage').doc(docId).valueChanges();
  }

  createUsageDoc(usage: IMonthlyUsage): Promise<string> {
    // return docId of usage after document was created
    return new Promise<string>((resolve, reject) => {
      usage.docId = this.db.createId();
      this.db
        .collection('users')
        .doc(usage.userUid)
        .collection('usage')
        .doc(usage.docId)
        .set(usage, { merge: true })
        .then(() => resolve(usage.docId))
        .catch((error) => reject(error));
    });
  }

  deleteUsageDoc(userUid: string, usageDocId: string): Promise<void> {
    return this.db.collection('users').doc(userUid).collection('usage').doc(usageDocId).delete();
  }

  queryDataboxEntries(userUid: string): Observable<IDataboxEntry[]> {
    return this.db.collection('users').doc(userUid).collection<IDataboxEntry>('databox').valueChanges();
  }

  updateDataboxEntry(userUid: string, databoxEntry: IDataboxEntry): Promise<void> {
    return this.db
      .collection('users')
      .doc(userUid)
      .collection('databox')
      .doc(databoxEntry.docId)
      .set(databoxEntry, { merge: true });
  }
}
