import { ApiService } from './../_services/api.service';
import { IFinishedDialogInput } from './../_interfaces/ifinished-dialog-input';
import { PromptDialogComponent } from './../_components/prompt-dialog/prompt-dialog.component';
import { AlternativeDataDialogComponent } from './alternative-data-dialog/alternative-data-dialog.component';
import { IFileExportOutput } from './../_interfaces/ifile-export-output';
import { MatTableDataSource } from '@angular/material/table';
import { FirestoreService } from './../_services/firestore.service';
import { Filter } from './../_enums/filter.enum';
import { TableFilterComponent } from './table-filter/table-filter.component';
import { FileExportDialogComponent } from './file-export-dialog/file-export-dialog.component';
import { State } from '../_enums/state.enum';
import { ICompanyTableRow } from './../_interfaces/company-table-row';
import { RowDialogComponent } from './row-dialog/row-dialog.component';
import { AuthService } from './../_services/auth.service';
import { VatService } from './../_services/vat.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { PropertyName } from '../_enums/property-name.enum';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionsDialogComponent } from './sessions-dialog/sessions-dialog.component';
import { take, first } from 'rxjs/operators';
import { PromptInput } from '../_interfaces/prompt-input';
import { Router } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { ApiErrorHandler } from '../_classes/api-error-handler';
import { FirebaseError } from '../_interfaces/firebase-error';
import { ApiResponse } from '../_classes/api-response';
import { IApiResponse } from '../_interfaces/api-response';
import { UsageService } from '../_services/usage.service';
import { FREE_CHECKS } from 'src/global';
import { StripeRole } from '../_enums/stripe-role.enum';
import { IMonthlyUsage } from '../_interfaces/imonthly-usage';

const HEADER_CORRECT_DATA = 'correctData';
const HEADER_VALID = 'valid';
const HEADER_STATE = 'state';
const HEADER_ACTION = 'action';
const HEADER_DATA_MATCH = 'match';

@Component({
  selector: 'app-vat-check',
  templateUrl: './vat-check.component.html',
  styleUrls: ['./vat-check.component.scss'],
})
export class VatCheckComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(TableFilterComponent) tableFilterComponent: TableFilterComponent;

  propertyName: typeof PropertyName = PropertyName;
  state: typeof State = State;
  private subscriptions: Subscription[] = [];
  checkUncheckedBtnDisabled = false;
  singleCheckResponse: IApiResponse;

  spinnerEnabled = false;
  singleVatCheckForm = this.fb.group({
    taxId: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(50)]],
  });

  // constant variables cannot be accessed in template
  headerCorrectData = HEADER_CORRECT_DATA;
  headerValid = HEADER_VALID;
  headerState = HEADER_STATE;
  headerAction = HEADER_ACTION;
  headerDataMatch = HEADER_DATA_MATCH;

  displayedColumns: string[] = [
    PropertyName.UID.toString(),
    PropertyName.Name.toString(),
    PropertyName.Addr1.toString(),
    PropertyName.Addr2.toString(),
    PropertyName.Addr3.toString(),
    PropertyName.Addr4.toString(),
    PropertyName.Addr5.toString(),
    PropertyName.Addr6.toString(),
    HEADER_CORRECT_DATA,
    HEADER_DATA_MATCH,
    HEADER_VALID,
    HEADER_STATE,
    HEADER_ACTION,
  ]; // reference to matColumnDef

  constructor(
    public vatService: VatService,
    public authService: AuthService,
    public dialog: MatDialog,
    private firestoreService: FirestoreService,
    private _snackBar: MatSnackBar,
    public apiService: ApiService,
    private router: Router,
    private fb: FormBuilder,
    private usageService: UsageService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.updateMatTable();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  updateMatTable(): void {
    this.vatService.dataSource = new MatTableDataSource<ICompanyTableRow>(this.vatService.importedData);
    // sorting
    this.vatService.dataSource.sortingDataAccessor = (item: ICompanyTableRow, property: string) => {
      // only == because property is stored as string in displayedColumns
      if (property === PropertyName.UID.toString()) {
        return item.UID;
      }
      if (property === PropertyName.Name.toString()) {
        if (item.acceptedResponse) {
          return item.acceptedResponse.name;
        } else {
          return item.Name;
        }
      }
      if (property === PropertyName.Addr1.toString()) {
        if (item.acceptedResponse) {
          return item.acceptedResponse.address.Addr1;
        } else {
          return item.address.Addr1;
        }
      }
      if (property === PropertyName.Addr2.toString()) {
        if (item.acceptedResponse) {
          return item.acceptedResponse.address.Addr2;
        } else {
          return item.address.Addr2;
        }
      }
      if (property === PropertyName.Addr3.toString()) {
        if (item.acceptedResponse) {
          return item.acceptedResponse.address.Addr3;
        } else {
          return item.address.Addr3;
        }
      }
      if (property === PropertyName.Addr4.toString()) {
        if (item.acceptedResponse) {
          return item.acceptedResponse.address.Addr4;
        } else {
          return item.address.Addr4;
        }
      }
      if (property === PropertyName.Addr5.toString()) {
        if (item.acceptedResponse) {
          return item.acceptedResponse.address.Addr5;
        } else {
          return item.address.Addr5;
        }
      }
      if (property === PropertyName.Addr6.toString()) {
        if (item.acceptedResponse) {
          return item.acceptedResponse.address.Addr6;
        } else {
          return item.address.Addr6;
        }
      }
      if (property === HEADER_DATA_MATCH) {
        const addr = item.importAddressMatchingPercent ? item.importAddressMatchingPercent : 0;
        const name = item.importNameMatchingPercent ? item.importNameMatchingPercent : 0;
        return ((addr + name) / 2) * 100;
      }
      if (property === HEADER_CORRECT_DATA) {
        return item.correctName && item.correctAddress;
      }
      if (property === HEADER_VALID) {
        return item.apiResponse?.valid;
      }
      if (property === HEADER_STATE) {
        return item.state;
      }
    };

    this.vatService.dataSource.sort = this.sort;
    this.vatService.dataSource.paginator = this.paginator;
    this.vatService.dataSource.filterPredicate = (data: ICompanyTableRow, filters: any[]): boolean => {
      return (
        (data.apiResponse?.valid === filters[Filter.VALID_UID] || filters[Filter.VALID_UID] == null) &&
        (data.correctAddress === filters[Filter.CORRECT_ADDRESS] || filters[Filter.CORRECT_ADDRESS] == null) &&
        (data.state === filters[Filter.STATE] || filters[Filter.STATE] == null) &&
        (data.dataModified === filters[Filter.DATA_MODIFIED] || filters[Filter.DATA_MODIFIED] == null) &&
        (data.correctName === filters[Filter.CORRECT_NAME] || filters[Filter.CORRECT_NAME] == null) &&
        (data.addressMatchOverrideActive === filters[Filter.THRESHOLD_ACTIVE] ||
          data.nameMatchOverrideActive === filters[Filter.THRESHOLD_ACTIVE] ||
          filters[Filter.THRESHOLD_ACTIVE] == null) &&
        (filters[Filter.STRING_MATCHING] == null ||
          filters[Filter.STRING_MATCHING]?.length === 0 ||
          data.UID.toUpperCase().includes(filters[Filter.STRING_MATCHING]))
      );
    };
    this.vatService.dataSource.filter = this.vatService.currentFilters;
  }

  clearTable() {
    const data: PromptInput = {
      title: 'Confirm deletion',
      text: 'Are you sure you want to delete all local table data? All progress will be lost. (If this session is stored to the cloud, it will still be available)',
    };
    const dialogRef = this.dialog.open(PromptDialogComponent, {
      maxWidth: '400px',
      data,
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.vatService.resetImportedData();
        this.tableFilterComponent.resetFilters();
        this.updateMatTable();
      }
    });
  }

  openRowDialog(rowElement: ICompanyTableRow): void {
    const dialogRef = this.dialog.open(RowDialogComponent, {
      width: '900px',
      maxHeight: '95vh',
      data: rowElement,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.updateMatTable();
    });
  }

  openFileExportDialog(): void {
    const dialogRef = this.dialog.open(FileExportDialogComponent, {
      width: '900px',
      maxHeight: '95vh',
      data: {
        importedData: this.vatService.importedData,
        importedHeaders: this.vatService.importedHeaders,
        importedHeadersColumnIndices: this.vatService.importedHeadersColumnIndices,
      },
    });

    dialogRef.afterClosed().subscribe((data?: IFileExportOutput) => {
      if (data?.deleteCurrentSession) {
        this.authService.user$.pipe(take(1)).subscribe(async (user) => {
          if (user) {
            try {
              const session = await this.vatService.currentSession$.pipe(first()).toPromise();
              await this.firestoreService.deleteSession(user.uid, session.docId, user.preferences.encryptionEnabled);
            } catch (error) {
              console.error(error);
              this._snackBar.open('Failed to delete current session from cloud storage', 'OK', {
                duration: 5000,
                panelClass: ['snackbar-warn'],
              });
            }

            if (data.clearTable) {
              this.clearTable();
            }
          }
        });
      } else if (data?.clearTable) {
        this.clearTable();
      }
    });
  }

  openSessionsDialog(): void {
    const dialogRef = this.dialog.open(SessionsDialogComponent, {
      width: '900px',
      maxHeight: '95vh',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((restoredSession: boolean) => {
      if (restoredSession) {
        this.updateMatTable();
        this._snackBar.open('Session restored', 'OK', {
          duration: 2000,
        });

        this.router.navigate(['']);
      }
    });
  }

  openDataManagerDialog(): void {
    this.dialog.open(AlternativeDataDialogComponent, {
      width: '80%',
      maxHeight: '95vh',
    });
  }

  reportFinished(event: IFinishedDialogInput): void {
    if (event.stateCounterUnchecked > 0 || event.stateCounterError > 0 || event.stateCounterUnavailable > 0) {
      this.checkUncheckedBtnDisabled = false;
    } else {
      this.checkUncheckedBtnDisabled = true;
    }
  }

  checkAll(): void {
    const data: PromptInput = {
      title: 'Confirm action',
      text: 'Are you sure you want to check all rows again? All current progress will be lost.',
    };
    const dialogRef = this.dialog.open(PromptDialogComponent, {
      maxWidth: '400px',
      data,
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.tableFilterComponent.resetFilters();
        this.updateMatTable();
        this.vatService.startCheck(0, true);
      }
    });
  }

  onSingleCheckInputChange() {
    this.singleVatCheckForm.setValue({
      taxId: this.singleVatCheckForm.value.taxId.toUpperCase(),
    });
  }

  async singleCheck() {
    this.spinnerEnabled = true;

    this.authService.user$.pipe(take(1)).subscribe(async (user) => {
      const countryCode = this.singleVatCheckForm.value.taxId.substring(0, 2);
      const uid = this.singleVatCheckForm.value.taxId.substring(2);

      const usage: IMonthlyUsage = await this.usageService.usageDoc$.pipe(first()).toPromise();
      const role: StripeRole = await this.authService.role;
      if (role === StripeRole.FREE && usage.usageCounter + 1 > FREE_CHECKS) {
        this._snackBar.open(
          'This check is exceeding your available check-contingent. Please upgrade your plan to continue!',
          'OK',
          {
            duration: 5000,
            panelClass: ['snackbar-warn'],
          }
        );
        this.spinnerEnabled = false;
        return;
      }

      this.apiService
        .checkVatRequest(countryCode, uid, user.taxIdValidated ? user.taxId : null)
        .pipe(take(1))
        .subscribe(
          (res) => {
            this.singleCheckResponse = res;
            this.spinnerEnabled = false;
            this.usageService.incrementUsage(1);
          },
          (error: FirebaseError) => {
            this.spinnerEnabled = false;
            this.singleCheckResponse = null;
            const errorMessage = ApiErrorHandler.getErrorMessage(error.details.code);
            this._snackBar.open(errorMessage, 'OK', {
              duration: 5000,
              panelClass: ['snackbar-warn'],
            });
          }
        );
    });
  }

  resetSingleCheckResponse() {
    this.singleCheckResponse = null;
    this.singleVatCheckForm.reset();
  }
}
