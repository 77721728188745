import { first } from 'rxjs/operators';
import { PropertyName } from '../_enums/property-name.enum';
import { State } from '../_enums/state.enum';
import { IAddress } from '../_interfaces/address';
import { IAlternativeData } from '../_interfaces/alternative-data';
import { IApiResponse } from '../_interfaces/api-response';
import { ICompanyTableRow } from './../_interfaces/company-table-row';
import { Address } from './address';
import { ApiResponse } from './api-response';
import { Levenshtein } from './levenshtein';
export class CompanyTableRow implements ICompanyTableRow {
  UID: string;
  Name: string;
  address: IAddress;
  apiResponse?: IApiResponse;
  correctAddress?: boolean;
  dataModified: boolean;
  correctName?: boolean;
  alternativeData?: IAlternativeData[];
  acceptedResponse?: IApiResponse;
  state?: State;
  additionalData?: any;
  sessionDocId?: string;
  sessionRowDocId?: string;
  importAddressMatchingPercent?: number;
  importNameMatchingPercent?: number;
  nameMatchOverrideActive?: boolean;
  addressMatchOverrideActive?: boolean;

  public static parseUidData(columnIndices: number[], headerRow: string[], dataRows: any[]): ICompanyTableRow[] {
    const companyTableRows: ICompanyTableRow[] = [];

    dataRows.forEach((dataRow: string) => {
      const companyTableRow: ICompanyTableRow = {
        UID: null,
        Name: null,
        address: {
          Addr1: null,
          Addr2: null,
          Addr3: null,
          Addr4: null,
          Addr5: null,
          Addr6: null,
        },
        state: State.UNCHECKED,
        dataModified: false,
        additionalData: {},
      };
      for (let i = 0; i < headerRow.length; i++) {
        const header = headerRow[i];
        const indexOfHeaderIndex = columnIndices.indexOf(i); // mindfuck...
        if (indexOfHeaderIndex !== -1) {
          // current header is a selected column (propertyName Enum)
          if (indexOfHeaderIndex > 1 && indexOfHeaderIndex < 8) {
            // is address
            companyTableRow.address[PropertyName[indexOfHeaderIndex]] = dataRow[i];
          } else {
            companyTableRow[PropertyName[indexOfHeaderIndex]] = dataRow[i];
          }
        } else {
          // else: column was not selected and therefore will not be displayed (but still be part of the dataarray)
          if (!companyTableRow.additionalData[header]) {
            companyTableRow.additionalData[header] = [];
          }
          companyTableRow.additionalData[header].push(dataRow[i]);
        }
      }

      companyTableRows.push(companyTableRow);
    });
    return companyTableRows;
  }

  public static nameIsCorresponding(
    importDataRow: ICompanyTableRow,
    alternativeDataCloudStorageEnabled: boolean
  ): boolean {
    if (importDataRow.apiResponse.name) {
      // compare import name to apiresponse
      if (importDataRow.Name === importDataRow.apiResponse.name) {
        return true;
      }
      // compare acceptedresponse with apiresponse
      else if (importDataRow.acceptedResponse?.name === importDataRow.apiResponse.name) {
        return true;
      }
      // compare alternative names (if available) to apiresponse AND compare responseAddress to check if alternative data is still uptodate
      else if (importDataRow.alternativeData?.length > 0) {
        for (let i = 0; i < importDataRow.alternativeData.length; i++) {
          const element = importDataRow.alternativeData[i];
          if (element.alternativeName === importDataRow.Name) {
            // only compare responseaddress
            if (alternativeDataCloudStorageEnabled) {
              if (importDataRow.apiResponse != null && element.apiResponse != null) {
                return ApiResponse.isEqual(importDataRow.apiResponse, element.apiResponse);
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  public static addressIsCorresponding(
    importDataRow: ICompanyTableRow,
    alternativeDataCloudStorageEnabled: boolean
  ): boolean {
    if (importDataRow.apiResponse.address) {
      // compare import address to row.apiresponse
      if (Address.isEqual(importDataRow.address, importDataRow.apiResponse.address)) {
        return true;
      }
      // compare apiresponse with acceptedResponse.address
      else if (
        importDataRow.acceptedResponse &&
        Address.isEqual(importDataRow.acceptedResponse.address, importDataRow.apiResponse.address)
      ) {
        return true;
      } else if (importDataRow.alternativeData?.length > 0) {
        // compare import address to stored alternatives
        // AND compare response address to stored response address of alternative address (to check if the alternative is still up to date)
        for (let i = 0; i < importDataRow.alternativeData.length; i++) {
          const element = importDataRow.alternativeData[i];
          if (Address.isEqual(importDataRow.address, element.alternativeAddress)) {
            if (alternativeDataCloudStorageEnabled) {
              // if cloud storage is enabled, also compare apiResponse for actuality
              // apiResponse can be null if the api returned error/unavailable on import (therefore alt data
              // was not stored to the cloud but alternativeDataCloudStorage might still be enabled)
              if (importDataRow.apiResponse != null && element.apiResponse != null) {
                return ApiResponse.isEqual(importDataRow.apiResponse, element.apiResponse);
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  public static getNameMatchingPercent(importName: string, apiResponse: ApiResponse): number {
    let totalDistance = 0;
    const importStr: string = importName ? importName : '';
    const apiResponseStr: string = apiResponse.name ? apiResponse.name : '';

    totalDistance += Levenshtein.getLevenshteinDistance(importStr, apiResponseStr);

    // avoid div / 0
    if (importStr.length === 0 && totalDistance === 0) {
      return 1;
    } else if (importStr.length === 0) {
      return 0;
    }

    return 1 - totalDistance / importStr.length < 0 ? 0 : 1 - totalDistance / importStr.length;
  }

  public static getAddressMatchingPercent(importAddr: Address, apiResponse: ApiResponse): number {
    let totalDistance = 0;
    const importStr: string =
      (importAddr.Addr1 ? importAddr.Addr1 : '') +
      (importAddr.Addr2 ? importAddr.Addr2 : '') +
      (importAddr.Addr3 ? importAddr.Addr3 : '') +
      (importAddr.Addr4 ? importAddr.Addr4 : '') +
      (importAddr.Addr5 ? importAddr.Addr5 : '') +
      (importAddr.Addr6 ? importAddr.Addr6 : '');
    const apiResponseStr: string =
      (apiResponse.address?.Addr1 ? apiResponse.address.Addr1 : '') +
      (apiResponse.address?.Addr2 ? apiResponse.address.Addr2 : '') +
      (apiResponse.address?.Addr3 ? apiResponse.address.Addr3 : '') +
      (apiResponse.address?.Addr4 ? apiResponse.address.Addr4 : '') +
      (apiResponse.address?.Addr5 ? apiResponse.address.Addr5 : '') +
      (apiResponse.address?.Addr6 ? apiResponse.address.Addr6 : '');

    totalDistance += Levenshtein.getLevenshteinDistance(importStr, apiResponseStr);

    // avoid div / 0
    if (importStr.length === 0 && totalDistance === 0) {
      return 1;
    } else if (importStr.length === 0) {
      return 0;
    }

    return 1 - totalDistance / importStr.length < 0 ? 0 : 1 - totalDistance / importStr.length;
  }

  public static async updateCompanyTableRow(
    companyTableRow: ICompanyTableRow,
    alternativeDataCloudStorageEnabled: boolean,
    matchingPredictionEnabled: boolean,
    matchingThreshold: number
  ) {
    companyTableRow.dataModified =
      companyTableRow.alternativeData?.length > 0 || companyTableRow.acceptedResponse ? true : false;

    if (companyTableRow.apiResponse.address && companyTableRow.apiResponse.name) {
      companyTableRow.correctAddress = CompanyTableRow.addressIsCorresponding(
        companyTableRow,
        alternativeDataCloudStorageEnabled
      );
      companyTableRow.correctName = CompanyTableRow.nameIsCorresponding(
        companyTableRow,
        alternativeDataCloudStorageEnabled
      );

      // matching %
      companyTableRow.importAddressMatchingPercent = CompanyTableRow.getAddressMatchingPercent(
        companyTableRow.address,
        companyTableRow.apiResponse
      );
      companyTableRow.importNameMatchingPercent = CompanyTableRow.getNameMatchingPercent(
        companyTableRow.Name,
        companyTableRow.apiResponse
      );
      companyTableRow.addressMatchOverrideActive = false;
      companyTableRow.nameMatchOverrideActive = false;

      if (matchingPredictionEnabled) {
        if (matchingThreshold <= companyTableRow.importAddressMatchingPercent) {
          if (!companyTableRow.correctAddress) {
            companyTableRow.addressMatchOverrideActive = true;
          }
          companyTableRow.correctAddress = true;
        }
        if (matchingThreshold <= companyTableRow.importNameMatchingPercent) {
          if (!companyTableRow.correctName) {
            companyTableRow.nameMatchOverrideActive = true;
          }
          companyTableRow.correctName = true;
        }
      }
    }
  }
}
