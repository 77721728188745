import { ISessionRow } from './../_interfaces/session-row';
import { IEncryptedSessionRow } from './../_interfaces/iencrypted-session-row';
import { IEncryptedAltData } from './../_interfaces/iencrypted-alt-data';
import { EncryptionService } from './../_services/encryption.service';
import { IUser } from './../_interfaces/user';
import { IApiResponse } from './../_interfaces/api-response';
import { ComponentCanDeactivate } from './../_guards/pending-changes.guard';
import { ApiService } from './../_services/api.service';
import { VatService } from './../_services/vat.service';
import { ISession } from './../_interfaces/session';
import { IAlternativeDataDocument } from './../_interfaces/alternative-data-document';
import { ICompanyTableRow } from './../_interfaces/company-table-row';
import { IImportData } from '../_interfaces/import-data';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { AuthService } from '../_services/auth.service';
import { FirestoreService } from '../_services/firestore.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { ErrorState } from '../_enums/error.enum';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { rejects } from 'assert';
import { FirebaseError } from '../_interfaces/firebase-error';
import { ApiErrorHandler } from '../_classes/api-error-handler';
import { MatStepper } from '@angular/material/stepper';

export interface IStoreSessionToCloudData {
  sessionRowsToUpdate: ISessionRow[] | IEncryptedSessionRow[];
  companyTableRows: ICompanyTableRow[];
  session: ISession;
}

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})
export class ImportComponent implements OnInit, ComponentCanDeactivate {
  constructor(
    private firestoreService: FirestoreService,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private vatService: VatService,
    private router: Router,
    private apiService: ApiService,
    private encryptionService: EncryptionService,
    private db: AngularFirestore
  ) {}
  private routeChangeAllowed = false;

  @ViewChild(MatStepper) stepper: MatStepper;
  private subscriptions: Subscription[] = [];

  uidImportData: IImportData;
  alternativeDataImportData: IImportData;
  spinnerEnabled = false;
  uidImportReady = false;
  alternativeDataImportReady = false;
  private companyTableRows: ICompanyTableRow[];
  private alternativeDataDocuments: IAlternativeDataDocument[];

  private checkVatErrorOccured = false;
  private storeAlternativeDataToCloudErrorOccured = false;
  private storeSessionRowsToCloudErrorOccured = false;

  sessionCloudStorageProgress = 0;
  cloudStorageMessage = 'Importing VAT number data...';
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (!this.routeChangeAllowed && (this.uidImportData || this.alternativeDataImportData)) {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  proceedStepper(): void {
    this.stepper.selected.completed = true;
    this.stepper.next();
  }

  uidDataImported(event: IImportData): void {
    this.stepper.reset();
    this.uidImportReady = false;
    this.companyTableRows = [];
    if (event) {
      this.uidImportData = JSON.parse(JSON.stringify(event));
      this.stepper.selected.completed = true;
      this.stepper.next();
    } else {
      this.uidImportData = null;
    }
  }

  altDataImported(event: IImportData): void {
    this.alternativeDataImportReady = false;
    this.alternativeDataDocuments = [];

    if (event) {
      this.alternativeDataImportData = JSON.parse(JSON.stringify(event));
      this.stepper.selected.completed = true;
      this.stepper.next();
    } else {
      this.alternativeDataImportData = null;
      this.alternativeDataImportReady = true;
    }
  }

  setUidImportValidated(companyTableRows?: ICompanyTableRow[]): void {
    // event returns null if uid data is not ready yet (e.g. selection of duplicates)
    if (companyTableRows) {
      this.companyTableRows = companyTableRows;
      this.uidImportReady = true;
    } else {
      this.uidImportReady = false;
    }
  }

  setAlternativeDataImportValidated(alternativeDataDocuments?: IAlternativeDataDocument[]): void {
    // alternative data is optional
    if (alternativeDataDocuments) {
      this.alternativeDataDocuments = alternativeDataDocuments;
    }
    this.alternativeDataImportReady = true;
  }

  async import(): Promise<void> {
    this.authService.user$.pipe(take(1)).subscribe(async (user) => {
      if (user) {
        this.spinnerEnabled = true;
        for (let i = 0; i < this.stepper.steps.toArray().length - 1; i++) {
          const step = this.stepper.steps.toArray()[i];
          step.editable = false;
        }

        // set respective alternative data in companytablerows
        if (this.alternativeDataDocuments?.length > 0) {
          this.setAlternativeData(this.companyTableRows, this.alternativeDataDocuments);
        }

        let data: IStoreSessionToCloudData;
        let session$: Observable<ISession>;
        if (user.preferences.sessionsCloudStorage) {
          try {
            data = await this.buildSessionRows(
              user,
              this.companyTableRows,
              this.uidImportData.importedHeaders,
              this.uidImportData.columnIndices,
              this.uidImportData.fileName
            );
            // upload uiddata to cloud
            this.cloudStorageMessage = 'Uploading VAT number data...';
            session$ = await this.storeSessionRowsToCloud(user, data.sessionRowsToUpdate, data.session);
          } catch (error) {
            this.storeSessionRowsToCloudErrorOccured = true;
            console.error(error);
            this._snackBar.open('Storing session to cloud failed', 'OK', {
              duration: 5000,
              panelClass: ['snackbar-warn'],
            });
            await this.firestoreService.deleteSession(user.uid, data.session.docId, user.preferences.encryptionEnabled);
          }
        }
        if (user.preferences.alternativeDataCloudStorage && this.alternativeDataDocuments) {
          this.cloudStorageMessage = 'Checking alternative data...';
          const docs = await this.buildAlternativeDocuments(this.alternativeDataDocuments, user);

          this.sessionCloudStorageProgress = 0;
          this.cloudStorageMessage = 'Importing alternative data...';
          try {
            await this.storeAlternativeDocumentsToCloud(docs, user);
          } catch (error) {
            console.error(error);
            this.storeAlternativeDataToCloudErrorOccured = true;
            this._snackBar.open('Alternative data cloud storage failed', 'OK', {
              duration: 5000,
              panelClass: ['snackbar-warn'],
            });
          }
        }
        // set data in vatservice
        if (user.preferences.sessionsCloudStorage) {
          this.vatService.setImportData(
            data.companyTableRows,
            this.uidImportData.importedHeaders,
            this.uidImportData.columnIndices,
            session$
          );
        } else {
          this.vatService.setImportData(
            this.companyTableRows,
            this.uidImportData.importedHeaders,
            this.uidImportData.columnIndices
          );
        }

        if (
          (this.checkVatErrorOccured && user.preferences.alternativeDataCloudStorage) ||
          this.storeAlternativeDataToCloudErrorOccured
        ) {
          if (this.storeSessionRowsToCloudErrorOccured) {
            this._snackBar.open(
              'Session and alternative data cloud storage failed - backup session unavailable!',
              'OK',
              {
                duration: 5000,
                panelClass: ['snackbar-warn'],
              }
            );
          } else {
            this._snackBar.open('Some alternative data rows have not been stored to the cloud.', 'OK', {
              duration: 5000,
              panelClass: ['snackbar-warn'],
            });
          }
        } else if (this.storeSessionRowsToCloudErrorOccured) {
          this._snackBar.open('Session cloud storage failed - backup session unavailable!', 'OK', {
            duration: 5000,
            panelClass: ['snackbar-warn'],
          });
        } else {
          this._snackBar.open('Data imported', 'OK', {
            duration: 2000,
          });
        }
        this.routeChangeAllowed = true;
        this.router.navigate(['']);
      }
    });
  }

  private setAlternativeData(
    companyTableRows: ICompanyTableRow[],
    alternativeDataDocuments: IAlternativeDataDocument[]
  ): void {
    companyTableRows.forEach((companyTableRow) => {
      for (let i = 0; i < alternativeDataDocuments.length; i++) {
        const altDoc = alternativeDataDocuments[i];
        if (altDoc.companyUid === companyTableRow.UID) {
          companyTableRow.dataModified = true;
          companyTableRow.alternativeData = altDoc.alternativeData;
          break;
        }
      }
    });
  }

  private storeAlternativeDocumentsToCloud(
    docsToUpdate: IAlternativeDataDocument[] | IEncryptedAltData[],
    user: IUser
  ): Promise<any> {
    return this.apiService.updateAlternativeDataDocuments(docsToUpdate, user.preferences.encryptionEnabled);
  }

  private buildAlternativeDocuments(
    documents: IAlternativeDataDocument[],
    user: IUser
  ): Promise<IAlternativeDataDocument[] | IEncryptedAltData[]> {
    return new Promise<IAlternativeDataDocument[] | IEncryptedAltData[]>((resolve) => {
      let synchronizedCounter = 0;

      const docsToUpdate: IAlternativeDataDocument[] = [];
      const encryptedDocsToUpdate: IEncryptedAltData[] = [];

      const increment = async () => {
        synchronizedCounter++;
        this.sessionCloudStorageProgress += (1 / documents.length) * 100;
        if (synchronizedCounter === documents.length) {
          // checked vat number of all entries --> store to cloud and resolve
          user.preferences.encryptionEnabled ? resolve(encryptedDocsToUpdate) : resolve(docsToUpdate);
        }
      };

      documents.forEach((alternativeDataDocument) => {
        const countryCode = alternativeDataDocument.companyUid.substr(0, 2);
        const uid = alternativeDataDocument.companyUid.substr(2);

        // make vat check for each alternative data
        this.apiService
          .checkVatRequest(countryCode, uid)
          .pipe(
            switchMap((res: IApiResponse) => {
              // set api responsedata
              alternativeDataDocument.alternativeData.forEach((altData) => {
                altData.apiResponse = res;
              });

              // query for existing alternativeDataDocuments
              return this.firestoreService.queryAlternativeData(user.uid, user.preferences.encryptionEnabled);
            }),
            catchError((error: FirebaseError) => {
              // alternative data will still be available locally with the companyTableRows array
              this.checkVatErrorOccured = true;
              this.displayVatCheckErrorMessage(error);
              increment();
              return EMPTY;
            }),
            take(1)
          )
          .subscribe(
            (data: IAlternativeDataDocument[]) => {
              const filteredDocs = data.filter((item) => item.companyUid === alternativeDataDocument.companyUid);
              if (filteredDocs?.length > 0) {
                // doc already exists --> push all data to array this doc via .concat()
                const doc: IAlternativeDataDocument = filteredDocs[0];
                doc.alternativeData = doc.alternativeData.concat(alternativeDataDocument.alternativeData);

                user.preferences.encryptionEnabled
                  ? encryptedDocsToUpdate.push(this.encryptionService.encryptAlternativeDataDocument(doc))
                  : docsToUpdate.push(doc);
                increment();

                if (filteredDocs.length > 1) {
                  console.error(
                    'More than 1 alternativeDataDocument found for user ' +
                      user.uid +
                      ' ; company ' +
                      filteredDocs[0].companyUid
                  );
                }
              } else {
                // no alternativeDataDoc found --> create new one (create new id)
                alternativeDataDocument.docId = this.db.createId();
                user.preferences.encryptionEnabled
                  ? encryptedDocsToUpdate.push(
                      this.encryptionService.encryptAlternativeDataDocument(alternativeDataDocument)
                    )
                  : docsToUpdate.push(alternativeDataDocument);
                increment();
              }
            },
            (error) => {
              console.error(error);
              this._snackBar.open('Failed to receive alternative data documents from cloud storage', 'OK', {
                duration: 5000,
                panelClass: ['snackbar-warn'],
              });
              user.preferences.encryptionEnabled ? resolve(encryptedDocsToUpdate) : resolve(docsToUpdate);
            }
          );
      });
    });
  }

  private storeSessionRowsToCloud(
    user: IUser,
    sessionRowsToUpdate: ISessionRow[] | IEncryptedSessionRow[],
    session: ISession
  ): Promise<Observable<ISession>> {
    return new Promise<Observable<ISession>>(async (resolve, reject) => {
      try {
        await this.apiService.updateSessionRows(sessionRowsToUpdate, user.preferences.encryptionEnabled);
        resolve(this.firestoreService.getSession(user.uid, session.docId, user.preferences.encryptionEnabled));
      } catch (error) {
        reject(error);
      }
    });
  }

  private buildSessionRows(
    user: IUser,
    companyTableRows: ICompanyTableRow[],
    headerRow: string[],
    columnIndices: number[],
    name: string
  ): Promise<IStoreSessionToCloudData> {
    return new Promise<IStoreSessionToCloudData>(async (resolve, reject) => {
      const session: ISession = {
        userUid: user.uid,
        docId: null,
        timeStamp: new Date(),
        headerRow,
        columnIndices,
        name,
      };
      // create session
      let sessionDocId: string;
      try {
        sessionDocId = await this.firestoreService.createSession(session, user.preferences.encryptionEnabled);
        session.docId = sessionDocId;
      } catch (error) {
        reject(error);
      }

      const sessionRowsToUpdate: ISessionRow[] = [];
      const encryptedSessionRowsToUpdate: IEncryptedSessionRow[] = [];

      for (let i = 0; i < companyTableRows.length; i++) {
        const companyTableRow: ICompanyTableRow = companyTableRows[i];

        const docId = this.db.createId();
        companyTableRow.sessionRowDocId = docId;
        companyTableRow.sessionDocId = session.docId;

        const sessionRow: ISessionRow = {
          docId,
          companyTableRow,
          sessionDocId,
        };

        user.preferences.encryptionEnabled
          ? encryptedSessionRowsToUpdate.push(this.encryptionService.encryptSessionRow(sessionRow))
          : sessionRowsToUpdate.push(sessionRow);

        if (i === companyTableRows.length - 1) {
          // finished
          const res: IStoreSessionToCloudData = {
            sessionRowsToUpdate: user.preferences.encryptionEnabled
              ? encryptedSessionRowsToUpdate
              : sessionRowsToUpdate,
            companyTableRows,
            session,
          };
          resolve(res);
        }
      }
    });
  }

  private displayVatCheckErrorMessage(error: FirebaseError): void {
    const errorMessage = ApiErrorHandler.getErrorMessage(error.details.code);
    console.error(ApiErrorHandler.getErrorLogMessage(error));

    this._snackBar.open(errorMessage, 'OK', {
      duration: 5000,
      panelClass: ['snackbar-warn'],
    });
  }
}
