import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export class Uid {
  public static validUidFormat(fullTaxId: string): boolean {
    // todo improve detection

    const matches = fullTaxId.replace(/ /g, '').substr(0, 2).match('^[A-Z]{2}$'); // check if first 2 letters are uppercase and alphabetical
    if (matches === null) {
      return false;
    } else {
      return true;
    }
  }

  public static uidValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        return this.validUidFormat(control.value) ? null : { invalid: true };
      } else {
        return { invalid: true };
      }
    };
  }
}
