import { IApiResponse } from './../_interfaces/api-response';
import { IEncryptedSessionRow } from './../_interfaces/iencrypted-session-row';
import { ISessionRow } from './../_interfaces/session-row';
import { IEncryptedAltData } from './../_interfaces/iencrypted-alt-data';
import { IAlternativeDataDocument } from './../_interfaces/alternative-data-document';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { IAddress } from '../_interfaces/address';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private aff: AngularFireFunctions) {}

  checkVatRequest(countryCode: string, vatNumber: string, teilnehmerUid?: string): Observable<IApiResponse> {
    if (environment.useFirebaseVatCheck) {
      return this.aff
        .httpsCallable('vat')({
          countryCode: countryCode.replace(/\s/g, ''),
          vatNumber: vatNumber.replace(/\s/g, ''),
          teilnehmerUid: teilnehmerUid.replace(/\s/g, ''),
        })
        .pipe(
          map((res) => {
            const parser = new DOMParser();
            const parsedAddress: IAddress = {
              Addr1: parser.parseFromString(this.removeWhitespaces(res.address.Addr1), 'text/html').documentElement
                .textContent,
              Addr2: parser.parseFromString(this.removeWhitespaces(res.address.Addr2), 'text/html').documentElement
                .textContent,
              Addr3: parser.parseFromString(this.removeWhitespaces(res.address.Addr3), 'text/html').documentElement
                .textContent,
              Addr4: parser.parseFromString(this.removeWhitespaces(res.address.Addr4), 'text/html').documentElement
                .textContent,
              Addr5: parser.parseFromString(this.removeWhitespaces(res.address.Addr5), 'text/html').documentElement
                .textContent,
              Addr6: parser.parseFromString(this.removeWhitespaces(res.address.Addr6), 'text/html').documentElement
                .textContent,
            };
            const parsedResponse: IApiResponse = {
              address: parsedAddress,
              countryCode: parser.parseFromString(this.removeWhitespaces(res.countryCode), 'text/html').documentElement
                .textContent,
              name: parser.parseFromString(this.removeWhitespaces(res.name), 'text/html').documentElement.textContent,
              requestDate: parser.parseFromString(res.requestDate, 'text/html').documentElement.textContent,
              valid: res.valid,
              vatNumber: parser.parseFromString(res.vatNumber, 'text/html').documentElement.textContent,
            };
            return parsedResponse;
          })
        );
    }
  }

  /**
   * This function removes leading and trailing whitespaces
   * and replaces multiple whitespaces in between words with one single whitespace.
   */
  private removeWhitespaces(input: string) {
    let clearedString = input.replace(/^\s*/, '');
    clearedString = clearedString.replace(/\s*$/, '');
    clearedString = clearedString.replace(/\s{2,}/g, ' ');
    return clearedString;
  }

  addCompanyData(stripeID: string, countryCode: string, vatNumber: string, companyName: string): Promise<any> {
    return this.aff
      .httpsCallable('addCompData')({
        stripeID,
        countryCode,
        vatNumber,
        companyName,
      })
      .toPromise();
  }

  getInvoiceInfo(stripeId: string): Observable<any> {
    return this.aff.httpsCallable('invoice')({
      customerId: stripeId,
    });
  }

  deleteSessionSubcollection(sessionDocId: string, deleteEncryptedData: boolean): Promise<any> {
    return this.aff.httpsCallable('deleteSession')({ sessionId: sessionDocId, deleteEncryptedData }).toPromise();
  }

  deleteAllSessions(deleteEncryptedData: boolean): Promise<any> {
    return this.aff.httpsCallable('deleteAllSessions')({ deleteEncryptedData }).toPromise();
  }

  deleteAllAlternatives(deleteEncryptedData: boolean): Promise<any> {
    return this.aff.httpsCallable('deleteAlternatives')({ deleteEncryptedData }).toPromise();
  }

  hashPassword(password: string): Promise<any> {
    return this.aff.httpsCallable('hashPassword')({ password }).toPromise();
  }

  verifyPassword(hash: string, password: string): Promise<any> {
    return this.aff.httpsCallable('verifyPassword')({ hash, password }).toPromise();
  }

  updateAlternativeDataDocuments(
    alternativeDataDocuments: IAlternativeDataDocument[] | IEncryptedAltData[],
    encryptionEnabled: boolean
  ): Promise<any> {
    return this.aff
      .httpsCallable('updateAlternativeDataDocuments')({
        alternativeDataDocuments,
        encryptionEnabled,
      })
      .toPromise();
  }

  updateSessionRows(sessionRows: ISessionRow[] | IEncryptedSessionRow[], encryptionEnabled: boolean): Promise<any> {
    return this.aff
      .httpsCallable('updateSessionRows')({
        sessionRows,
        encryptionEnabled,
      })
      .toPromise();
  }

  cancelSubscriptions(subscriptionIds: string[]): Promise<any> {
    return this.aff
      .httpsCallable('cancelSubscriptions')({
        subscriptionIds,
      })
      .toPromise();
  }

  upgradeToProPlan(subscriptionId: string, proPriceId: string, volumeProPriceId: string): Promise<any> {
    return this.aff
      .httpsCallable('upgradeToProPlan')({
        subscriptionId,
        proPriceId,
        volumeProPriceId,
      })
      .toPromise();
  }
}
