<mat-form-field>
  <mat-label>Filter VAT number</mat-label>
  <input
    matInput
    [ngModel]="currentFilters[filter.STRING_MATCHING]"
    (ngModelChange)="currentFilters[filter.STRING_MATCHING] = $event.toUpperCase()"
    (keyup)="applyFilter($event)"
    placeholder="e.g. ATU14189108"
    #input
  />
</mat-form-field>
<div class="filter-row">
  <button
    mat-raised-button
    class="button-chip"
    [color]="validFilterSelected == true ? 'primary' : 'basic'"
    (click)="filterTable('valid')"
  >
    valid VAT No.
  </button>
  <button
    mat-raised-button
    class="button-chip"
    [color]="invalidFilterSelected == true ? 'primary' : 'basic'"
    (click)="filterTable('invalid')"
  >
    invalid VAT No.
  </button>
  <button
    mat-raised-button
    class="button-chip"
    [color]="correctAddressFilterSelected == true ? 'primary' : 'basic'"
    (click)="filterTable('correctAddress')"
  >
    valid addr
  </button>
  <button
    mat-raised-button
    class="button-chip"
    [color]="incorrectAddressFilterSelected == true ? 'primary' : 'basic'"
    (click)="filterTable('incorrectAddress')"
  >
    invalid addr
  </button>
  <button
    mat-raised-button
    class="button-chip"
    [color]="correctNameFilterSelected == true ? 'primary' : 'basic'"
    (click)="filterTable('correctName')"
  >
    valid name
  </button>
  <button
    mat-raised-button
    class="button-chip"
    [color]="incorrectNameFilterSelected == true ? 'primary' : 'basic'"
    (click)="filterTable('incorrectName')"
  >
    invalid name
  </button>
  <button
    mat-raised-button
    class="button-chip"
    [color]="dataModifiedFilterSelected == true ? 'primary' : 'basic'"
    (click)="filterTable('modified')"
  >
    data modified
  </button>
  <button
    mat-raised-button
    class="button-chip"
    [color]="thresholdActiveFilterSelected == true ? 'primary' : 'basic'"
    (click)="filterTable('threshold')"
  >
    threshold active
  </button>
  <button
    mat-raised-button
    class="button-chip"
    [color]="checkedFilterSelected == true ? 'primary' : 'basic'"
    (click)="filterTable('checked')"
  >
    checked
  </button>
  <button
    mat-raised-button
    class="button-chip"
    [color]="uncheckedFilterSelected == true ? 'primary' : 'basic'"
    (click)="filterTable('unchecked')"
  >
    unchecked
  </button>

  <button
    mat-raised-button
    class="button-chip"
    [color]="unavailableFilterSelected == true ? 'primary' : 'basic'"
    (click)="filterTable('unavailable')"
  >
    unavailable
  </button>
  <button
    mat-raised-button
    class="button-chip"
    [color]="errorFilterSelected == true ? 'primary' : 'basic'"
    (click)="filterTable('error')"
  >
    error
  </button>
</div>
