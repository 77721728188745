<div class="validation__duplicates-container">
  <h2>Review Duplicate VAT Numbers</h2>
  <ng-container *ngIf="duplicateUids.length > 0; else noDuplicatesFound">
    <p>Duplicate VAT numbers found, select correct rows to be imported.</p>
    <div class="validation__table-template-container">
      <table class="validation__table-template">
        <tr class="validation__table-template-tr">
          <th class="validation__table-template-th">Import</th>
          <ng-container *ngFor="let column of uidData.importedHeaders; let i = index">
            <th class="validation__table-template-th">
              {{ column }}
            </th>
          </ng-container>
        </tr>
        <tr *ngFor="let dataRow of uidData.importedData | callback: filterUID" class="validation__table-template-tr">
          <td>
            <mat-checkbox
              [disabled]="importRunning"
              checked="true"
              color="primary"
              class="validation__checkbox"
              (change)="duplicateCheckboxChanged($event, dataRow)"
            ></mat-checkbox>
          </td>
          <td *ngFor="let dataColumn of dataRow">
            {{ dataColumn }}
          </td>
        </tr>
      </table>
    </div>
  </ng-container>
  <ng-template #noDuplicatesFound>No duplicate VAT numbers found, ready to import.</ng-template>
</div>
