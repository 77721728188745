import { ApiService } from './../_services/api.service';
import {
  FREE_CHECKS,
  INCLUDED_PRO_CHECKS,
  ADDITIONAL_BASIC_CHECK_COST,
  BASIC_PRICE,
  INCLUDED_BASIC_CHECKS,
  ADDITIONAL_PRO_CHECK_COST,
  PRO_PRICE,
} from './../../global';
import { FirestoreService } from './../_services/firestore.service';
import { AuthService } from 'src/app/_services/auth.service';
import { Component, OnInit } from '@angular/core';
import { StripeRole } from '../_enums/stripe-role.enum';
import { ICheckoutSession } from '../_interfaces/checkout-session';
import { ILineItem } from '../_interfaces/line-item';
import { IUser } from '../_interfaces/user';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { PromptDialogComponent } from '../_components/prompt-dialog/prompt-dialog.component';
import { PromptInput } from '../_interfaces/prompt-input';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionDialogComponent } from './subscription-dialog/subscription-dialog.component';
import { ISubscriptionDialogInput } from '../_interfaces/isubscription-dialog-input';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  stripeRole: typeof StripeRole = StripeRole;
  proSubscriptionLoading = false;
  basicSubscriptionLoading = false;
  manageSubscriptionLoading = false;
  upgradeToProLoading = false;

  invoice$: Observable<any>;

  freeChecks: number = FREE_CHECKS;
  includedBasicChecks: number = INCLUDED_BASIC_CHECKS;
  includedProChecks: number = INCLUDED_PRO_CHECKS;
  additionalBasicCheckCost: number = ADDITIONAL_BASIC_CHECK_COST;
  additionalProCheckCost: number = ADDITIONAL_PRO_CHECK_COST;
  basicPrice: number = BASIC_PRICE;
  proPrice: number = PRO_PRICE;

  basicPriceId: string = environment.stripe.basicPriceId;
  volumeBasicPriceId: string = environment.stripe.volumeBasicPriceId;
  proPriceId: string = environment.stripe.proPriceId;
  volumeProPriceId: string = environment.stripe.volumeProPriceId;

  constructor(
    public authService: AuthService,
    private firestoreService: FirestoreService,
    private _snackBar: MatSnackBar,
    private apiService: ApiService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.authService.user$.pipe(take(1)).subscribe((user) => {
      if (user.stripeId) {
        this.invoice$ = this.apiService.getInvoiceInfo(user.stripeId);
      }
    });
  }

  openCustomerPortal(): void {
    // if customer portal is not available, stripe cloud function would throw error
    this.manageSubscriptionLoading = true;
    this.authService.getStripePortalLink().subscribe(
      (res) => {
        window.open(res.url, '_blank');
        this.manageSubscriptionLoading = false;
      },
      (error) => {
        {
          console.error(error);
          this.manageSubscriptionLoading = false;
          this._snackBar.open('Failed to open Stripe customer portal', 'OK', {
            duration: 5000,
            panelClass: ['snackbar-warn'],
          });
        }
      }
    );
  }

  subscribeToPlan(basePriceId: string, volumePriceId: string, isBasicSubscription: boolean): void {
    if (this.authService.userLocatedInEU != null) {
      this.authService.user$.pipe(take(1)).subscribe((user) => {
        if (user) {
          if (user.taxId && user.taxIdValidated && user.companyName) {
            isBasicSubscription ? (this.basicSubscriptionLoading = true) : (this.proSubscriptionLoading = true);
            this.redirectPayment(user, volumePriceId, basePriceId);
          } else {
            const data: ISubscriptionDialogInput = {
              companyName: user.companyName,
              taxId: user.taxId,
              taxIdValidated: user.taxIdValidated,
            };
            const dialogRef = this.dialog.open(SubscriptionDialogComponent, {
              maxWidth: '400px',
              data,
            });
            dialogRef.afterClosed().subscribe((confirmedSubscription: boolean) => {
              if (confirmedSubscription) {
                isBasicSubscription ? (this.basicSubscriptionLoading = true) : (this.proSubscriptionLoading = true);
                this.redirectPayment(user, volumePriceId, basePriceId);
              }
            });
          }
        } else {
          isBasicSubscription ? (this.basicSubscriptionLoading = false) : (this.proSubscriptionLoading = false);
        }
      });
    } else {
      this.geolocationUnavailableSnackBar();
    }
  }

  upgradeToProPlan(): void {
    if (this.authService.userLocatedInEU != null) {
      const data: PromptInput = {
        title: 'Confirm upgrade',
        text:
          `After upgrading, you will get a new quota of included checks.` +
          `However, current limit-exceeding checks will be billed with € ` +
          (Math.round(ADDITIONAL_PRO_CHECK_COST * 100) / 100).toFixed(2) +
          `/check. Effectively, you will have new included checks that will be used up in future requests, ` +
          `and some checks that will be billed as though they were exceeding the limit. ` +
          `Your new limit will be ` +
          INCLUDED_PRO_CHECKS +
          ` checks/month.`,
      };
      const dialogRef = this.dialog.open(PromptDialogComponent, {
        maxWidth: '400px',
        data,
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          // upgrade from basic plan to pro plan
          this.upgradeToProLoading = true;
          this.authService.user$.pipe(take(1)).subscribe((user) => {
            this.firestoreService
              .getCurrentSubscriptions(user.uid)
              .pipe(take(1))
              .subscribe(
                async (subscriptions) => {
                  const activeSubscriptions = subscriptions.filter((subscription) => subscription.ended_at === null);
                  if (activeSubscriptions.length > 1) {
                    console.error('upgradeToProPlan(): more than one active subscription for user uid: ' + user.uid);
                  }

                  const subscriptionId = activeSubscriptions[0].items[0].subscription;
                  try {
                    await this.apiService.upgradeToProPlan(
                      subscriptionId,
                      environment.stripe.proPriceId,
                      environment.stripe.volumeProPriceId
                    );
                    this.refreshUserTokenUntilPro();
                  } catch (error) {
                    console.error(error);
                    this._snackBar.open('Upgrade to the Pro plan failed', 'OK', {
                      duration: 5000,
                      panelClass: ['snackbar-warn'],
                    });
                  }
                },
                (error) => {
                  this.upgradeToProLoading = false;
                  console.error(error);
                  this._snackBar.open('Upgrade to the Pro plan failed', 'OK', {
                    duration: 5000,
                    panelClass: ['snackbar-warn'],
                  });
                }
              );
          });
        }
      });
    } else {
      this.geolocationUnavailableSnackBar();
    }
  }

  private refreshUserTokenUntilPro(): void {
    // refreshUserToken until pro role is set via firebase stripe extension
    this.authService.getStripeRole().then(
      (role: StripeRole) => {
        if (role === StripeRole.PRO_PLAN) {
          window.location.reload();
        } else {
          this.refreshUserTokenUntilPro();
        }
      },
      (error) => {
        console.error(error);
        this._snackBar.open('Failed to retrieve Stripe role', 'OK', {
          duration: 5000,
          panelClass: ['snackbar-warn'],
        });
      }
    );
  }

  private async redirectPayment(user: IUser, volumeBasedPrice: string, basePrice: string): Promise<void> {
    // IMPORTANT: standard item needs to be on index 0, volume based item on index 1 (BE accesses items via index)
    const lineItems: ILineItem[] = [
      {
        price: basePrice,
        quantity: 1,
        dynamic_tax_rates: [environment.stripe.austrianTaxRateId], // only tax austrian customers
      },
      { price: volumeBasedPrice, dynamic_tax_rates: [environment.stripe.austrianTaxRateId] }, // only tax austrian customers
    ];

    const checkoutSession: ICheckoutSession = {
      line_items: lineItems,
      success_url: window.location.origin + '/',
      cancel_url: window.location.origin + '/subscription',
      customer_update: {
        name: 'auto',
        address: 'auto',
      },
    };
    const docRef = await this.firestoreService.createCheckoutSession(checkoutSession, user.uid);
    docRef.onSnapshot((snap) => {
      const { error, sessionId } = snap.data();

      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
        this.basicSubscriptionLoading = false;
        this.proSubscriptionLoading = false;
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe
        const stripe = Stripe(environment.stripe.stripePrivateKey);
        stripe.redirectToCheckout({ sessionId });
      }
    });
  }

  private geolocationUnavailableSnackBar(): void {
    this._snackBar.open('Your geolocation is currently unavailable, please disable your Adblock extension.', 'OK', {
      duration: 5000,
      panelClass: ['snackbar-warn'],
    });
  }
}
