import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewKeyDialogComponent } from 'src/app/settings/new-key-dialog/new-key-dialog.component';
import { IUser } from 'src/app/_interfaces/user';
import { AuthService } from 'src/app/_services/auth.service';
import { VatService } from 'src/app/_services/vat.service';

@Component({
  selector: 'app-personal-settings',
  templateUrl: './personal-settings.component.html',
  styleUrls: ['./personal-settings.component.scss'],
})
export class PersonalSettingsComponent implements OnInit {
  @Input() user: IUser | undefined;
  @Input() showSetEncryptionButton: boolean | undefined;

  constructor(
    private authService: AuthService,
    public vatService: VatService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  async toggleAlternativeDataCloudStorage(user: IUser): Promise<void> {
    await this.authService.updateUser(user);
    this.successfulUpdateMessage();
  }

  async toggleSessionCloudStorage(user: IUser): Promise<void> {
    await this.authService.updateUser(user);
    this.successfulUpdateMessage();
  }

  async toggleImportSchemeCloudStorage(user: IUser): Promise<void> {
    await this.authService.updateUser(user);
    this.successfulUpdateMessage();
  }

  async toggleClientSideEncryption(user: IUser): Promise<void> {
    await this.authService.updateUser(user);
    this.successfulUpdateMessage();
  }

  async toggleUseDataMatching(user: IUser): Promise<void> {
    await this.authService.updateUser(user);
    this.successfulUpdateMessage();
  }

  async changeSlider($event, user: IUser): Promise<void> {
    user.preferences.matchingThreshold = $event.value.toFixed(2);
    await this.authService.updateUser(user);
    this.successfulUpdateMessage();
  }

  formatSliderLabel(value: number): string {
    return (value * 100).toFixed(0) + '%';
  }

  private successfulUpdateMessage() {
    this._snackBar.open('Your preferences have been saved', 'OK', {
      duration: 2000,
    });
  }

  setNewEncryptionKey() {
    this.dialog.open(NewKeyDialogComponent, {
      disableClose: true,
      maxWidth: '400px',
    });
  }
}
