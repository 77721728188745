import { AuthService } from 'src/app/_services/auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { EU_COUNTRIES, GEOLOCATION_SERVICE } from 'src/global';

@Injectable({
  providedIn: 'root',
})
export class LocationGuard implements CanActivate {
  constructor(private router: Router, private http: HttpClient, private authService: AuthService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.authService.userLocatedInEU) {
      return this.authService.userLocatedInEU;
    }

    try {
      const obs = this.http.get(GEOLOCATION_SERVICE).pipe(
        map((res) => EU_COUNTRIES.includes(res['country_code'])),
        tap((countryIncluded) => {
          if (!countryIncluded) {
            this.authService.userLocatedInEU = false;
            this.router.navigate(['service-unavailable']);
          } else {
            this.authService.userLocatedInEU = true;
          }
        }),
        catchError((err) => {
          // geolocation-db is offline or user has adblock enabled
          return of(true);
        })
      );
      return obs;
    } catch (error) {
      console.log(error);

      return true;
    }
  }
}
