<div class="upcoming_invoice__container">
  <mat-card class="subscription__card mat-elevation-z4">
    <table class="upcoming-invoice__table">
      <tbody>
        <tr>
          <td class="upcoming-invoice__total upcoming-invoice__name">Item</td>
          <td class="upcoming-invoice__total upcoming-invoice__ges">Total</td>
        </tr>
        <ng-container *ngFor="let item of items">
          <tr>
            <td class="upcoming-invoice__name">{{ item.name }}</td>
            <td class="upcoming-invoice__ges">{{ item.ges | number: '1.2-2' }} {{ item.cur }}</td>
          </tr>
        </ng-container>
        <tr>
          <td id="upcoming-invoice__separator" class="upcoming-invoice__total upcoming-invoice__name">
            Σ excluding tax
          </td>
          <td id="upcoming-invoice__separator" class="upcoming-invoice__total upcoming-invoice__ges">
            {{ sums.woTax | number: '1.2-2' }} {{ currency }}
          </td>
        </tr>
        <tr>
          <td class="upcoming-invoice__name">Tax</td>
          <td class="upcoming-invoice__ges">{{ sums.tax | number: '1.2-2' }} {{ currency }}</td>
        </tr>
        <tr>
          <td class="upcoming-invoice__total upcoming-invoice__name">Σ Total</td>
          <td class="upcoming-invoice__total upcoming-invoice__ges">{{ sums.ges | number: '1.2-2' }} {{ currency }}</td>
        </tr>
      </tbody>
    </table>
  </mat-card>
</div>
