import { VatService } from './../_services/vat.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VatCheckGuard implements CanActivate {
  constructor(private vatService: VatService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // if import is running, disable route change from import
    if (this.vatService.checkStarted && !this.vatService.checkFinished) { return false; }
    else { return true; }
  }
}
