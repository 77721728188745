import { AuthService } from './../_services/auth.service';
import { EncryptionDialogComponent } from './../_components/encryption-dialog/encryption-dialog.component';
import { EncryptionService } from './../_services/encryption.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EncryptionGuard implements CanActivate {
  constructor(
    private encryptionService: EncryptionService,
    private dialog: MatDialog,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.user$.pipe(
      map((user) => user),
      tap((user) => {
        if (user.preferences.encryptionEnabled && !this.encryptionService.key) {
          const headingText = user.preferences.passwordHash
            ? 'Enter your encryption password'
            : 'Set a new encryption password';

          this.dialog.open(EncryptionDialogComponent, {
            disableClose: true,
            data: headingText,
            width: '350px',
            autoFocus: false,
          });
        }
      }),
      map((user) => !!user)
    );
  }
}
