<h3 mat-dialog-title>Confirm subscription</h3>
<div mat-dialog-content>
  <p class="subscription-dialog__disclaimer">
    <b
      >Please note that our service is only available to users who pay on behalf of their company located within the
      EU.</b
    >
  </p>
  <form [formGroup]="subscriptionForm">
    <mat-form-field class="subscription-dialog__input">
      <mat-label>Your company VAT number (EU)</mat-label>
      <input
        (input)="onTaxIdInputChange()"
        placeholder="e.g. ATU12345678"
        cdkFocusInitial
        formControlName="taxId"
        matInput
        type="text"
      />
    </mat-form-field>

    <mat-form-field class="subscription-dialog__input">
      <mat-label>Company name</mat-label>
      <input placeholder="e.g. danubelabs" cdkFocusInitial formControlName="businessName" matInput type="text" />
    </mat-form-field>

    <div class="subscription-dialog__actions">
      <button mat-button [mat-dialog-close]="false">Cancel</button>

      <button
        (click)="confirmSubscription()"
        [disabled]="!subscriptionForm.valid || spinnerEnabled"
        type="submit"
        mat-raised-button
        color="primary"
      >
        <div class="subscription-dialog__confirm-btn-container">
          <div>Confirm</div>
          <div *ngIf="spinnerEnabled" class="subscription-dialog__spinner">
            <mat-progress-spinner diameter="17" mode="indeterminate"></mat-progress-spinner>
          </div>
        </div>
      </button>
    </div>
  </form>
</div>
