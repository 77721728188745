import { IImportData } from 'src/app/_interfaces/import-data';
import { IFileImportOutput } from './../../_interfaces/file-import-output';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { IImportTemplate } from 'src/app/_interfaces/import-template';
import { IRawImportData } from 'src/app/_interfaces/raw-import-data';
import { ITemplateDialogInputData } from 'src/app/_interfaces/template-dialog-input-data';
import { AuthService } from 'src/app/_services/auth.service';
import { FirestoreService } from 'src/app/_services/firestore.service';
import { SelectionDialogComponent } from '../selection-dialog/selection-dialog.component';
import { TemplateDialogComponent } from '../template-dialog/template-dialog.component';

@Component({
  selector: 'app-uid-import',
  templateUrl: './uid-import.component.html',
  styleUrls: ['./uid-import.component.scss'],
})
export class UidImportComponent implements OnInit {
  @Output() fileImportFinishedEvent = new EventEmitter<IImportData>();

  private importData: IImportData;

  uidSelectionEnabled = false;
  uidImportTemplateForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(50)]],
  });

  constructor(
    private firestoreService: FirestoreService,
    public authService: AuthService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.uidImportTemplateForm.get('name').disable();
  }

  rawDataImported(event: IFileImportOutput): void {
    if (event) {
      this.uidSelectionEnabled = true;
      this.uidImportTemplateForm.get('name').enable();
      this.uidImportTemplateForm.controls.name.setValue(event.fileName);
      this.importData = {
        importedHeaders: event.data.importedHeaders,
        importedData: event.data.importedData,
        columnIndices: null,
        fileName: event.fileName,
      };
    } else {
      this.uidSelectionEnabled = false;
      this.uidImportTemplateForm.get('name').disable();
      this.uidImportTemplateForm.controls.name.setValue('');
      this.importData = null;
      this.fileImportFinishedEvent.emit(null);
    }
  }

  openUidTemplatesDialog(): void {
    const inputData: ITemplateDialogInputData = {
      importedData: this.importData.importedData,
      importedHeaders: this.importData.importedHeaders,
      showAlternativeDataTemplates: false,
    };
    const dialogRef = this.dialog.open(TemplateDialogComponent, {
      width: '900px',
      maxHeight: '95vh',
      data: inputData,
    });
    dialogRef.afterClosed().subscribe((columnIndices: number[]) => {
      if (columnIndices) {
        this.importData.columnIndices = columnIndices;
        this.fileImportFinishedEvent.emit(this.importData);
      }
    });
  }

  openUidSelectionDialog(storeAsTemplate: boolean): void {
    const inputData: IRawImportData = {
      importedData: this.importData.importedData,
      importedHeaders: this.importData.importedHeaders,
    };
    const dialogRef = this.dialog.open(SelectionDialogComponent, {
      width: '100%',
      height: 'auto',
      data: inputData,
    });
    dialogRef.afterClosed().subscribe((columnIndices: number[]) => {
      if (columnIndices) {
        this.importData.columnIndices = columnIndices;
        this.fileImportFinishedEvent.emit(this.importData);
        if (storeAsTemplate) { this.storeImportTemplateToCloud(columnIndices); }
      }
    });
  }

  private storeImportTemplateToCloud(columnIndices: number[]): void {
    this.authService.user$.pipe(take(1)).subscribe((user) => {
      if (user) {
        const importTemplate: IImportTemplate = {
          docId: null,
          userUid: user.uid,
          columnIndices,
          timeStamp: new Date(),
          name: this.uidImportTemplateForm.controls.name.value,
        };
        this.firestoreService
          .createImportTemplate(importTemplate)
          .then(() => {
            this._snackBar.open('New template stored to cloud', 'OK', {
              duration: 2000,
            });
          })
          .catch((error) => {
            console.error(error);
            this._snackBar.open('Failed to store import template to cloud storage', 'OK', {
              duration: 5000,
              panelClass: ['snackbar-warn'],
            });
          });
      } else { console.error('User not logged in, template not stored to cloud.'); }
    });
  }
}
