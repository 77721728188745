<h3 mat-dialog-title>{{ headerText }}</h3>
<div mat-dialog-content>
  <form [formGroup]="encryptionForm">
    <mat-form-field class="encryption-dialog__input">
      <mat-label>Password</mat-label>
      <input autocomplete="current-password" cdkFocusInitial formControlName="password" matInput type="password" />
    </mat-form-field>

    <div class="encryption-dialog__actions">
      <button
        type="submit"
        mat-raised-button
        color="primary"
        [disabled]="!encryptionForm.valid || spinnerEnabled"
        (click)="confirmPassword()"
      >
        <div class="encryption-dialog__confirm-btn-container">
          <div>Confirm</div>
          <div *ngIf="spinnerEnabled" class="encryption-dialog__spinner">
            <mat-progress-spinner diameter="17" mode="indeterminate"></mat-progress-spinner>
          </div>
        </div>
      </button>

      <button mat-button [disabled]="spinnerEnabled" (click)="disableEncryption()">Disable encryption</button>
    </div>
  </form>
</div>
