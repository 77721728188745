import { Pipe, PipeTransform } from '@angular/core';
import { IDataboxEntryCheck } from '../_interfaces/idatabox-entry-check';

@Pipe({
  name: 'countChecks',
})
export class CountChecksPipe implements PipeTransform {
  transform(checks: IDataboxEntryCheck[], validCountValue: boolean): number {
    return checks.filter((check) => check.valid === validCountValue).length;
  }
}
