import { IAlternativeDataDocument } from './../../_interfaces/alternative-data-document';
import { AlternativeDataDocument } from './../../_classes/alternative-data-document';
import { CompanyTableRow } from './../../_classes/company-table-row';
import { ICompanyTableRow } from './../../_interfaces/company-table-row';
import { AuthService } from './../../_services/auth.service';
import { IImportData } from '../../_interfaces/import-data';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertyName } from 'src/app/_enums/property-name.enum';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss'],
})
export class ValidationComponent implements OnInit {
  @Output() private uidDataValidatedEvent = new EventEmitter<ICompanyTableRow[]>();
  @Output() private altDataValidatedEvent = new EventEmitter<IAlternativeDataDocument[]>();
  @Input() importRunning: boolean;

  private _uidData: IImportData;
  @Input() public set uidData(data: IImportData) {
    this._uidData = data;
    this.checkForDuplicateUIDs();
  }
  public get uidData(): IImportData {
    return this._uidData;
  }
  @Input() public set altData(data: IImportData) {
    if (data) {
      this.checkAlternativeData(data);
    } else { this.altDataValidatedEvent.emit(null); }
  }

  duplicateUids: string[] = [];
  private duplicateDeletedRows: any[] = [];

  constructor(private authService: AuthService) {}

  ngOnInit(): void {}

  checkForDataToBeReady(): void {
    if (this.findDuplicateUIDs(this._uidData.importedData).size === 0) {
      // uids ok, no duplicates --> parse
      const rows: ICompanyTableRow[] = CompanyTableRow.parseUidData(
        this._uidData.columnIndices,
        this._uidData.importedHeaders,
        this._uidData.importedData
      );

      this.uidDataValidatedEvent.emit(rows);
    } else {
      // data not ready yet (e.g. uid duplicates still selected)
      this.uidDataValidatedEvent.emit(null);
    }
  }

  private checkAlternativeData(data: IImportData): void {
    this.authService.user$.pipe(take(1)).subscribe(async (user) => {
      const docs: IAlternativeDataDocument[] = AlternativeDataDocument.parseAlternativeData(
        data.columnIndices,
        data.importedHeaders,
        data.importedData,
        user.uid
      );

      this.altDataValidatedEvent.emit(docs);
    });
  }

  private checkForDuplicateUIDs(): void {
    const uniqueDuplicateUids = this.findDuplicateUIDs(this._uidData.importedData);

    this.duplicateUids = [];
    this._uidData.importedData.forEach((element) => {
      if (uniqueDuplicateUids.has(element[this._uidData.columnIndices[PropertyName.UID]])) {
        this.duplicateUids.push(element[this._uidData.columnIndices[PropertyName.UID]]);
      }
    });

    this.checkForDataToBeReady();
  }

  private findDuplicateUIDs(data: any): Set<string> {
    // returns a Set<string> of all duplicate UIDs
    const uids: string[] = data.map((element) => element[this._uidData.columnIndices[PropertyName.UID]]);
    return new Set<string>(uids.filter((item, index) => uids.indexOf(item) != index));
  }

  filterUID = (row: any): boolean => {
    // if duplicateUids includes the current iteration row, display it on the duplicate review step
    return this.duplicateUids.includes(row[this._uidData.columnIndices[PropertyName.UID]]);
  }

  duplicateCheckboxChanged(event: any, dataRow: any): void {
    const checked = event.checked;
    if (!checked) {
      this.duplicateDeletedRows.push(dataRow);
      this._uidData.importedData.splice(this._uidData.importedData.indexOf(dataRow), 1);
    } else {
      this.duplicateDeletedRows.splice(this.duplicateDeletedRows.indexOf(dataRow));
      this._uidData.importedData.push(dataRow);
    }

    this.checkForDataToBeReady();
  }
}
