<ng-container *ngIf="user">
  <div class="settings__toggle-row">
    <div>
      <div class="settings__light-header">Enable alternative data cloud-storage (recommended)</div>
      Enable cloud storage of alternative names and addresses to keep your data for recurring checks.
    </div>
    <mat-slide-toggle
      (change)="toggleAlternativeDataCloudStorage(user)"
      [(ngModel)]="user.preferences.alternativeDataCloudStorage"
      [disabled]="vatService.checkStarted && !vatService.checkFinished"
      color="primary"
    ></mat-slide-toggle>
  </div>

  <div class="settings__toggle-row">
    <div>
      <div class="settings__light-header">Enable session cloud-storage (recommended)</div>
      Session cloud storage provides you with an up-to-date backup of your current session. Stored sessions can be
      restored at any time.
    </div>
    <mat-slide-toggle
      (change)="toggleSessionCloudStorage(user)"
      [(ngModel)]="user.preferences.sessionsCloudStorage"
      [disabled]="vatService.checkStarted && !vatService.checkFinished"
      color="primary"
    ></mat-slide-toggle>
  </div>

  <div class="settings__toggle-row">
    <div>
      <div class="settings__light-header">Enable import templates cloud-storage (recommended)</div>
      Create pre-defined import templates to automatically select the respected data columns when importing new data.
    </div>
    <mat-slide-toggle
      (change)="toggleImportSchemeCloudStorage(user)"
      [(ngModel)]="user.preferences.importSchemeCloudStorage"
      [disabled]="vatService.checkStarted && !vatService.checkFinished"
      color="primary"
    ></mat-slide-toggle>
  </div>

  <div class="settings__toggle-row">
    <div>
      <div class="settings__light-header">Enable client side encryption</div>
      Client side encryption is, in addition to the server side encryption (which is enabled by default), another layer
      of security. Please note that if you lose your password, all your data is lost as even we cannot read your
      encrypted data.
    </div>
    <mat-slide-toggle
      (change)="toggleImportSchemeCloudStorage(user)"
      [(ngModel)]="user.preferences.encryptionEnabled"
      [disabled]="vatService.checkStarted && !vatService.checkFinished"
      color="primary"
    ></mat-slide-toggle>
  </div>
  <button
    *ngIf="showSetEncryptionButton"
    [disabled]="vatService.checkStarted && !vatService.checkFinished"
    class="settings__set-key-btn"
    mat-raised-button
    color="basic"
    (click)="setNewEncryptionKey()"
  >
    Set a new key
  </button>

  <div class="settings__toggle-row">
    <div>
      <div class="settings__light-header">Enable data matching algorithm (recommended)</div>
      The data matching algorithm makes use of the Levenshtein distance to calculate the percentage match of the
      provided name and address data. If the matching percentage meets or exceeds the defined threshold, the respective
      rows are flagged as valid.
    </div>
    <mat-slide-toggle
      (change)="toggleUseDataMatching(user)"
      [(ngModel)]="user.preferences.useMatchingPrediction"
      [disabled]="vatService.checkStarted && !vatService.checkFinished"
      color="primary"
    ></mat-slide-toggle>
  </div>

  <div class="settings__toggle-row" *ngIf="user.preferences.useMatchingPrediction; else thresholdPlaceholder">
    <div>
      Current Threshold:
      <span class="settings__light-header"> {{ user.preferences.matchingThreshold * 100 | number: '1.0-0' }}% </span>
    </div>
    <div>
      <mat-slider
        thumbLabel
        [disabled]="vatService.checkStarted && !vatService.checkFinished"
        [displayWith]="formatSliderLabel"
        (change)="changeSlider($event, user)"
        [value]="user.preferences.matchingThreshold"
        color="primary"
        min="0"
        max="1"
        step="0.01"
      ></mat-slider>
    </div>
  </div>
  <ng-template #thresholdPlaceholder><div class="settings__threshold-placeholder"></div></ng-template>
</ng-container>
