<h1 mat-dialog-title>Databox entries</h1>
<mat-dialog-content>
  <ng-container *ngIf="databoxEntries$ | async as databoxEntries; else loading">
    <ng-container *ngIf="databoxEntries.length === 0">No entries found.</ng-container>

    <ng-container *ngFor="let entry of databoxEntries | orderBy : 'date'">
      <mat-card class="row-card mat-elevation-z4">
        <div class="row-card-content">
          <div class="row-card-left">
            <div>
              <b>{{ entry.date['seconds'] * 1000 | date : 'dd.MM.yyyy' }}</b>
              <br />
              {{ entry.checks.length }} total checks
            </div>

            <div *ngIf="entry.checks | countChecks : true > 0" class="row-card-results">
              <div class="row-card-result">
                <mat-icon class="success-icon" aria-hidden="false">check_circle</mat-icon> valid checks:
                {{ entry.checks | countChecks : true }}
              </div>
            </div>

            <div *ngIf="entry.checks | countChecks : false > 0" class="row-card-results">
              <div class="row-card-result">
                <mat-icon class="error-icon" aria-hidden="false">error</mat-icon> invalid checks:
                {{ entry.checks | countChecks : false }}
              </div>
            </div>
          </div>
          <div>
            <button (click)="exportPdf(entry)" mat-icon-button>
              <mat-icon class="read-btn--inactive">picture_as_pdf</mat-icon>
            </button>

            <button (click)="markDataboxEntryAsRead(entry)" mat-icon-button>
              <mat-icon [ngClass]="!entry.read ? 'read-btn--active' : 'read-btn--inactive'">done</mat-icon>
            </button>
          </div>
        </div>
      </mat-card>
    </ng-container>
  </ng-container>
  <ng-template #loading>Loading...</ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="dialog-action-row">
    <button mat-button mat-dialog-close>Close</button>
  </div>
</mat-dialog-actions>
